import {
    DecimalPipe, isPlatformBrowser 
} from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Inject, Input, NgZone, OnInit, PLATFORM_ID 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {Transaction} from '@solana/web3.js';
import { HttpService } from '../../services/http.service';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { PayFiService } from '../../services/payfi.service';
import IPayFiDrawdown from '../../interfaces/IPayFiDrawdown';
import PayFiDrawdown from '../../models/PayFiDrawdown';
import ICheckbox from '@crediblefinance/credible-ui/lib/cf-checkbox-large/ICheckbox';
import IPayFiPool from '../../interfaces/IPayFiPool';
import PayFiPool from '../../models/PayFiPool';
import { combineLatest } from 'rxjs';
import { nullChecker } from '../../helpers/nullChecker';
import { getWindow } from 'ssr-window';
import { KycService } from '../../services/kyc.service';

import getSolanaConnection from '../../helpers/getSolanaConnection';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-new-payfi-repayment',
    templateUrl: './new-payfi-repayment.component.html',
    styleUrl: './new-payfi-repayment.component.scss'
})
export class NewPayFiRepaymentComponent implements OnInit {
    selected_fiat_currency: string = '';

    drawdownsDropdownOptions: Array<IDropdown> = [];
    selected_crypto_currency: string = '';

    pools: Array<IPayFiPool> = [];
    selected_pool: IPayFiPool = new PayFiPool();

    fiat_repayment_amount: number = 0;
    crypto_repayment_amount: number = 0;

    interest_amount: number = 0;
    used_for_interest: number = 0;
    used_for_principal: number = 0;

    platformId: object = {};
    isBrowser: boolean = false;

    drawdowns: Array<IPayFiDrawdown> = [];
    @Input() drawdown: any;

    repayment_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    drawdown_info: IPayFiDrawdown = new PayFiDrawdown();
    show_no_drawdowns: boolean = false;

    pool_id: string = '';
    balance: number = 0;
    gas_fees_balance: number = 0;
    drawdown_id: string = '';

    repayment_type_options: Array<ICheckbox> = [
        {
            label: 'Wallet transfer',
            value: 'wallet',
            optionEnabled: true
        }
    ];
    repayment_type: string = this.repayment_type_options[0].value;
    fiat_conversion_rates: {
        [key: string]: number;
    } = {};
    confirm_btn_label: string = 'Repay';
    window: Window = getWindow();
    constructor(
        private kycService:KycService,
        private metamaskService: MetamaskService,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        public walletService: WalletService,
        private payFiService: PayFiService,
        private decimalPipe: DecimalPipe,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private ngZone: NgZone,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.loading = true;

        const data = this.route.snapshot.queryParamMap.get('drawdown');

        if (data) {
            const parsedData = JSON.parse(data);

            this.drawdown = parsedData;
        }

        this.getPrerequisiteDate();
    }

    getPrerequisiteDate() {
        combineLatest([
            this.payFiService.getFiatRates(),
            this.payFiService.getPools()
        ]).subscribe(
            ([ fiatRates, pools ]) => {
                this.fiat_conversion_rates = fiatRates.data;
                this.pools = pools.data;

                this.getDrawdowns();
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    getDrawdowns() {
        this.show_no_drawdowns = false;

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            usage: 'create_repayment'
        };

        this.payFiService.getDrawdowns(body).subscribe(
            (res) => {
                this.drawdowns = res.data;

                if (this.drawdowns.length === 0)
                    this.show_no_drawdowns = true;

                else {
                    this.drawdowns.forEach((element: any) => {
                        const received_amount = this.decimalPipe.transform(element.received_amount, this.httpService.getPrecision(element.received_currency));
                        const receive_currency = element.received_currency.toUpperCase();
                        const date = new Date(element.created).toDateString();

                        this.drawdownsDropdownOptions.push({
                            label: `${received_amount} ${receive_currency} (${date})`,
                            value: element.drawdown_id,
                            logo: this.httpService.getCurrencyUrl(
                                element.received_currency
                            ),
                            optionEnabled: true
                        });
                    });

                    if (this.drawdown) {
                        const drawdownIndex = this.drawdownsDropdownOptions.findIndex((item: any) => item.value === this.drawdown.drawdown_id);

                        const finalIndex = drawdownIndex === -1 ? 0 : drawdownIndex;

                        this.changeDrawdown(this.drawdownsDropdownOptions[finalIndex]);
                    }
                    else
                        this.changeDrawdown(this.drawdownsDropdownOptions[0]);
                }

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        );
    }

    fiatAmountChanged(amount: number) {
        console.log('fiatAmountChanged', amount);

        if (!amount) amount = 0;

        this.fiat_repayment_amount = parseFloat(amount.toString());

        if (this.fiat_repayment_amount < this.interest_amount)
            this.fiatAmountLessThanInterest();
        else this.fiatAmountMoreThanInterest();

        this.setConfirmBtnLabel();
    }

    cryptoAmountChanged(amount: number) {
        console.log('cryptoAmountChanged', amount);

        if (!amount) amount = 0;

        this.crypto_repayment_amount = parseFloat(amount.toString());

        const crypto_interest_amount = this.interest_amount;

        console.log('crypto_interest_amount', crypto_interest_amount);
        console.log('crypto_repayment_amount', this.crypto_repayment_amount);

        if (this.crypto_repayment_amount < crypto_interest_amount)
            this.cryptoAmountLessThanInterest();
        else this.cryptoAmountMoreThanInterest();

        this.setConfirmBtnLabel();
    }

    fiatAmountLessThanInterest() {
        console.log('fiatAmountLessThanInterest');

        this.used_for_interest = this.fiat_repayment_amount;

        this.used_for_principal = 0;
    }

    cryptoAmountLessThanInterest() {
        console.log('cryptoAmountLessThanInterest');

        this.used_for_interest = this.crypto_repayment_amount;

        this.used_for_principal = 0;
    }

    fiatAmountMoreThanInterest() {
        console.log('fiatAmountMoreThanInterest');

        this.used_for_interest = this.interest_amount;

        const leftover = this.fiat_repayment_amount - this.interest_amount;

        if (leftover > this.drawdown_info.outstanding_fiat)
            this.used_for_principal = this.drawdown_info.outstanding_fiat;
        
        else
            this.used_for_principal = leftover;
    }

    cryptoAmountMoreThanInterest() {
        console.log('cryptoAmountMoreThanInterest');

        const crypto_interest_amount = this.interest_amount;
        const crypto_received_amount = this.convertToCrypto(this.drawdown_info.outstanding_fiat, this.selected_fiat_currency, this.selected_crypto_currency);

        this.used_for_interest = crypto_interest_amount;

        const leftover = this.crypto_repayment_amount - crypto_interest_amount;

        if (leftover > crypto_received_amount)
            this.used_for_principal = crypto_received_amount;
        else this.used_for_principal = leftover;
    }

    changeDrawdown(option: IDropdown) {
        this.drawdown_id = option.value;

        const drawdown_info = this.drawdowns.find((item: any) => {
            return item.drawdown_id === this.drawdown_id;
        });

        if (drawdown_info)
            this.drawdown_info = drawdown_info;

        this.selected_fiat_currency = this.drawdown_info.received_currency;
        this.selected_crypto_currency = this.drawdown_info.collateral_currency;
        this.pool_id = this.drawdown_info.pool_id;

        for (let i = 0; i < this.pools.length; i++) {
            if (
                this.pools[i].drawdown_currency === this.selected_crypto_currency
            ) {
                this.selected_pool = this.pools[i];

                break;
            }
        }

        this.getGasFeeBalance(
            this.drawdown_info.blockchain,
            this.drawdown_info.chain
        );
        this.getCurrencyBalance(
            this.drawdown_info.blockchain,
            this.drawdown_info.chain,
            this.selected_pool.drawdown_currency_mint_address
        );

        this.calculatePendingInterest();
    }

    calculatePendingInterest() {
        const current_time = new Date().getTime();

        const difference_in_minutes = (current_time - this.drawdown_info.last_interest_payment_time) / 60000;

        const interest_per_min = (this.drawdown_info.outstanding_fiat * this.drawdown_info.yield_rate) / 100 / 365 / 24 / 60;

        const pending_interest = interest_per_min * difference_in_minutes;

        this.selected_fiat_currency = this.drawdown_info.received_currency;

        console.log('pending_interest', pending_interest);

        // if (pending_interest < Math.pow(10, -2))
        //     pending_interest = 0;

        if (this.repayment_type === 'fiat') {
            this.interest_amount = pending_interest;
            this.fiatAmountChanged(pending_interest);
        }
        else if (this.repayment_type === 'wallet') {
            this.interest_amount = this.convertToCrypto(
                pending_interest,
                this.selected_fiat_currency,
                this.selected_crypto_currency
            );

            this.cryptoAmountChanged(this.interest_amount);
        }
    }

    convertToCrypto(
        amount: number,
        from_currency: string,
        to_currency: string
    ) {
        const usd_rate = this.fiat_conversion_rates[from_currency];
        const usd_amount = amount / usd_rate;
        const crypto_amount = usd_amount / this.httpService.currencyConversionMap[to_currency].usd;

        return crypto_amount;
    }

    convertToFiat(amount: number, from_currency: string, to_currency: string) {
        const usd_rate = this.fiat_conversion_rates[to_currency];
        const usd_amount = amount * usd_rate;
        const fiat_amount = usd_amount / this.httpService.currencyConversionMap[from_currency].usd;

        return fiat_amount;
    }

    createRepayment() {
        if (this.repayment_type === 'fiat') {
            if (
                isNaN(this.fiat_repayment_amount) || nullChecker(this.fiat_repayment_amount) || this.fiat_repayment_amount === 0
            ) {
                this.cfAlertService.showError(
                    new HttpErrorResponse({
                        error: {
                            message: 'Please enter valid amount'
                        }
                    })
                );

                return;
            }
        }
        else if (this.repayment_type === 'wallet') {
            if (
                isNaN(this.crypto_repayment_amount) || nullChecker(this.crypto_repayment_amount) || this.crypto_repayment_amount === 0
            ) {
                this.cfAlertService.showError(
                    new HttpErrorResponse({
                        error: {
                            message: 'Please enter valid amount'
                        }
                    })
                );

                return;
            }
        }

        const body: any = {
            drawdown_id: this.drawdown_id,
            repayment_type: this.repayment_type
        };

        if (this.repayment_type === 'wallet') {
            body.amount = this.crypto_repayment_amount;
            body.currency = this.selected_crypto_currency;
        }
        else if (this.repayment_type === 'fiat') {
            body.amount = this.fiat_repayment_amount;
            body.currency = this.selected_fiat_currency;
        }

        this.btn_loading = true;

        this.payFiService.createRepaymentWallet(body).subscribe(
            (res: any) => {
                this.btn_loading = false;
                this.repayment_id = res.data.repayment_id;

                if (res.data.transaction) {
                    const transaction = Transaction.from(
                        Buffer.from(res.data.transaction, 'base64')
                    );

                    this.sendSolanaTransaction(transaction);
                }
                else if (res.data.abi) {
                    const abi = res.data.abi;
                    const gas = res.data.gas;
                    const contract_address = res.data.contract_address;

                    console.log('lending', abi, contract_address, gas);

                    this.sendEthereumTransaction(abi, contract_address, gas);
                }
            },
            (err: HttpErrorResponse) => {
                console.error('createRepaymentWallet error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(
                transaction
            );

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    updateSignature(signature: string, error_code: number = -1) {
        const body = {
            drawdown_id: this.drawdown_id,
            repayment_id: this.repayment_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.payFiService.updateRepayment(body).subscribe(
            (res: any) => {
                this.btn_loading = false;

                this.cfAlertService.showMessage('Repayment succesful');

                this.router.navigateByUrl(
                    '/transactions?tab=my-payfi-repayments'
                );
            },
            (err: HttpErrorResponse) => {
                console.error('updateRepayment error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    async sendSolanaTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature) return;

            transaction = res;

            const connection = getSolanaConnection();

            console.log('transaction', transaction);

            const txnSignature = await connection.sendRawTransaction(
                transaction.serialize({
                    requireAllSignatures: true,
                    verifySignatures: true
                })
            );

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature);
        }
        catch (err) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            this.cfAlertService.showError(
                new HttpErrorResponse({
                    error: err
                })
            );

            this.btn_loading = false;
        }
    }

    login() {
        this.httpService.redirectUrl(this.constructor.name, '/login', {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    approveAllowance() {
        if (
            isNaN(this.fiat_repayment_amount) || this.fiat_repayment_amount === null || this.fiat_repayment_amount === undefined || this.fiat_repayment_amount === 0
        ) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.fiat_repayment_amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.gas_fees_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient gas fees', true);

            return;
        }

        this.loading = true;

        const body: any = {
            amount: this.fiat_repayment_amount,
            method: this.repayment_type,
            wallet_address: this.walletService.wallet_address,
            pool_id: this.pool_id,
            vault_id: ''
        };

        if (this.repayment_type === 'wallet')
            body.currency = this.selected_crypto_currency;
        else if (this.repayment_type === 'fiat')
            body.currency = this.selected_fiat_currency;

        this.httpService.approveAllowance(body).subscribe(
            (res: any) => {
                this.drawdown_id = res.data.drawdown_id;

                const abi = res.data.abi;
                const contract_address = res.data.contract_address;
                const gas = res.data.gas;

                console.log('allowance_abi', abi);
                console.log('allowance_contract_address', contract_address);
                console.log('allowance_gas', gas);

                this.sendEthereumAllowanceTransaction(
                    abi,
                    contract_address,
                    gas
                );
            },
            (err: HttpErrorResponse) => {
                console.error('approveAllowance error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.loading = false;
            }
        );
    }

    async getEthereumSignature(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(
                abi,
                contract_address,
                gas
            );

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async sendEthereumAllowanceTransaction(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        let error_code = -1;
        let allowance_signature = '';

        try {
            allowance_signature = await this.getEthereumSignature(
                abi,
                contract_address,
                gas
            );

            console.log('allowance_signature', allowance_signature);

            this.cfAlertService.showMessage('Allowance succesful');

            this.createRepayment();
        }
        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
    }

    async sendEthereumTransaction(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(
                abi,
                contract_address,
                gas
            );

            console.log(
                'sendEthereumTransaction : txn signature => ',
                txnSignature
            );
        }
        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;

        const wallet_address = this.walletService.wallet_address;

        this.httpService
            .getCurrencyBalance(blockchain, chain, wallet_address)
            .subscribe(
                (res: any) => {
                    this.gas_fees_balance = res.data;
                },
                (err: HttpErrorResponse) => {
                    console.error(err);

                    this.cfAlertService.showError(err);
                }
            );
    }

    getCurrencyBalance(
        blockchain: string,
        chain: string,
        mint_address: string
    ) {
        if (!this.isBrowser) {
            this.balance = 0;

            return;
        }

        if (nullChecker(this.walletService.wallet_address) || !this.walletService.isWalletConnected())
            return;

        this.httpService
            .getCurrencyBalance(blockchain, chain, this.walletService.wallet_address, mint_address)
            .subscribe(
                (res: any) => {
                    this.balance = res.data;
                },
                (err: HttpErrorResponse) => {
                    console.error(err);

                    this.cfAlertService.showError(err);
                }
            );
    }

    changeRepaymentTyepOption(option: ICheckbox) {
        this.repayment_type = option.value;

        this.calculatePendingInterest();
    }

    setConfirmBtnLabel() {
        if (this.repayment_type === 'fiat') {
            this.confirm_btn_label = 'Repay ' + this.decimalPipe.transform(
                this.fiat_repayment_amount,
                this.httpService.getPrecision(this.selected_fiat_currency)
            ) + ' ' + this.selected_fiat_currency.toUpperCase();
        }
        else if (this.repayment_type === 'wallet') {
            this.confirm_btn_label = 'Repay ' + this.decimalPipe.transform(
                this.crypto_repayment_amount,
                this.httpService.getPrecision(this.selected_crypto_currency)
            ) + ' ' + this.selected_crypto_currency.toUpperCase();
        }
    }
    generateKycUrl() {
        this.loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe(
            (res: any) => {
                this.loading = false;

                this.window.location.href = res.data;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    connectWallet() {
        this.ngZone.run(() => {
            this.dialog.open(ConnectWalletComponent, {
                width: '550px',
                height: 'auto',
                data: {}
            });
        });
    }
}
