import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router
} from '@angular/router';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
    currentTab = '';

    tabs: Array<TabOption> = [{
        //     label: 'Email',
        //     value: 'login-email'
        // }, {
        label: 'Wallet',
        value: 'login-wallet'
    }];

    connectedWalletAddres: string = '';

    returnUrl: string = '';

    constructor(
        public httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        if (this.httpService.user.user_id > 0) {
            this.router.navigate([ '/airdrop' ]);

            return;
        }

        this.currentTab = this.tabs[0].value;

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.value === tab))
                    this.currentTab = tab;
            }
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
