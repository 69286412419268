import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, HostListener, Input, OnInit,
    ViewChild
} from '@angular/core';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../../services/http.service';

import PrivateCreditPool from '../../../models/PrivateCreditPool';

import { getWindow } from 'ssr-window';
import { LineChartComponent } from '../../charts/line-chart/line-chart.component';
import IPrivateCreditPool from '../../../interfaces/IPrivateCreditPool';
import ILineConfig from '../../charts/line-chart/ILineConfig';

@Component({
    selector: 'app-lend-pool-details-default',
    templateUrl: './lend-pool-details-default.component.html',
    styleUrl: './lend-pool-details-default.component.scss'
})

export class LendPoolDetailsDefaultComponent implements OnInit {
    @Input() category_id: string| null = null;
    @Input() pool_id: string | null = null;
    loading: boolean = false;

    pool: IPrivateCreditPool = new PrivateCreditPool();
    time_to_maturity: number = 0;
    window: Window;
    
    width: any;

    labelArrayPool: Array<any> = [ 'Pool Activity', 'My Supply', 'My Withdrawals' ];
    tabIndexPool: number = 0;

    selected_lending_yield_rate: any = {};
    pool_utilisation: number = 0;
    total_cred_points: number = 0;

    total_apr_tooltip_text: string = '';
    ROUTE_DEFAULT: string = '';

    chart_loading: boolean = true;
    stats: any;
    graphArray: any = [];
    chartTitle = '';
    
    chartLineConfig: Array<ILineConfig> = [];

    isTooltipVisible = false;
    additionalYieldLabel: string = 'CRED';

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(private route: ActivatedRoute, public httpService: HttpService, 
        private cfAlertService: CfAlertService, 
        private router: Router) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.ROUTE_DEFAULT = `/liquidity/${this.category_id}/${this.pool_id}/`;

        this.getPoolStats();
        this.getPoolDetails();

        if (this.httpService.user.wallet_address !== '')
            this.getPoolRewards();

        this.width = this.window.innerWidth;
    }
    showTooltip() {
        this.isTooltipVisible = true;
    }

    hideTooltip() {
        this.isTooltipVisible = false;
    }

    getPoolDetails() {
        this.loading = true;

        this.httpService.getPoolDetails(this.pool_id).subscribe(res => {
            this.pool = res.data;

            this.selected_lending_yield_rate = this.pool.lending_yield_rates[this.pool.lending_yield_rates.length - 1];

            this.pool_utilisation = parseFloat(((this.pool.stats.outstanding_drawdown / this.pool.stats.tvl) * 100).toFixed(2));

            if (isNaN(this.pool_utilisation))
                this.pool_utilisation = 0;

            const currentTime = (new Date()).getTime();
            const term_in_miliseconds = this.pool.tenor * 24 * 60 * 60 * 1000;

            const diffence = this.pool.start_date + term_in_miliseconds - currentTime;

            this.time_to_maturity = Math.round(diffence / 24 / 60 / 60 / 1000);

            this.httpService.setMetaData(this.pool.name, this.pool.description);
            this.httpService.setCanonicalUrl(window.location.href);

            if (this.pool_id === 'badx61jpjr')
                this.additionalYieldLabel = 'CETES';

            this.total_apr_tooltip_text = `USDC APR : ${this.selected_lending_yield_rate.currency_percentage}% , ${this.additionalYieldLabel} TOKEN APR : ${this.selected_lending_yield_rate.cred_percentage}%`;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);

            this.router.navigateByUrl('/liquidity');
        });
    }

    getPoolRewards() {
        const data = {
            pool_id: this.pool_id
        };

        this.httpService.getPoolRewards(data).subscribe(res => {
            this.total_cred_points = res.data.total_cred_points;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    connectWallet() {
        this.router.navigate([ '/login' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    onTabChangePool(index: any) {
        console.log('onTabChangePool >>', index);

        this.tabIndexPool = index;
    }

    getPoolStats() {
        this.chart_loading = true;

        this.httpService.getPoolStats(this.pool_id).subscribe(res => {
            this.stats = res.data;
            const chartDataInvestment = res.data.tvlvsdate_30;
            const chartDataDrawdown = res.data.outstandingdrawdownamountvsdate_30;

            this.chartLineConfig = [{
                name: 'Investment',
                data: chartDataInvestment,
                lineColor: '#FFD700'
            }, {
                name: 'Drawdown',
                data: chartDataDrawdown,
                lineColor: '#0000ff'
            }];

            this.chart_loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    getAmount(amount: number) {
        if (amount !== 0)
            return amount.toFixed(2);
        else
            return 0;
    }

    showCharts(event: string) {
        let chartDataDrawdown, chartDataInvestment;

        if (event === '30D') {
            chartDataInvestment = this.stats.tvlvsdate_30;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_30;
        }

        else if (event === '60D') {
            chartDataInvestment = this.stats.tvlvsdate_60;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_60;
        }

        else if (event === '90D') {
            chartDataInvestment = this.stats.tvlvsdate_90;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_90;
        }

        this.chartLineConfig = [{
            name: 'Investment',
            data: chartDataInvestment,
            
            lineColor: '#FFD700'
        }, {
            name: 'Drawdown',
            data: chartDataDrawdown,
            
            lineColor: '#0000ff'
        }];

        this.child.loadchart();
    }
}
