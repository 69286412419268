<div class="app-ambassador" [ngClass]="httpService.theme">
    <div class="row transactions">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="mb-3">
                <div class="font_lg bold d-flex justify-content-between w-100">
                    <div>
                        Invite Link
                    </div>
                    <div class="referral-count d-flex align-items-center gap-2">
                        <span>{{referred_user_count}}</span>
                        <i class="fa-solid fa-user fa-lg"></i>
                    </div>
                </div>
                <div>
                    <cf-input [label]="'Invite Link'" [theme]="httpService.theme" [value]="invitation_link"
                        [copy]="true" type="text" [readonly]="true"></cf-input>
                </div>
            </div>
        </cf-card>
        <app-ambassador-moons-credit></app-ambassador-moons-credit>
        <app-ambassador-onboarding></app-ambassador-onboarding>
        <!-- <app-members></app-members> -->
        <!-- <app-ambassador-cred></app-ambassador-cred> -->
        <div class="row" *ngIf="!loading">

            <div class="d-flex justify-content-end">
                <div class="level-dropdown mb-3" matTooltip="Referral Level">
                    <cf-dropdown [theme]="httpService.theme" [options]=" statusOptions" (change)="changeStatus($event)"
                        label="Filter" [showLogo]="false" [selected]="selected_status" [showDescription]="true">
                    </cf-dropdown>
                </div>
            </div>

            <div class="table-container" class="mt-4">
                <cf-card type="empty" [theme]="httpService.theme">
                    <table>
                        <thead>
                            <tr>
                                <th>User name</th>
                                <th>Address</th>

                            </tr>
                        </thead>
                        <tbody *ngIf="members.length > 0">
                            <tr *ngFor="let member of members">
                                <td> {{ member.display_name }} </td>
                                <td>{{ member.child_wallet_address }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="totalChildrenDataCount === 0 && httpService.user.wallet_address !== ''">
                            <tr>
                                <td colspan="12" class="pd">
                                    No records found
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <cf-paginator *ngIf="totalChildrenDataCount > 0" [page]="page + 1" [count]="totalChildrenDataCount"
                        [limit]="limit" (changed)="pageChanged($event)"></cf-paginator>
                </cf-card>
            </div>
        </div>
    </div>
</div>