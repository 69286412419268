'use strict';

var assert = require('minimalistic-assert');
var inherits = require('inherits');
var Cipher = require('./cipher');
var DES = require('./des');
function EDEState(type, key) {
  assert.equal(key.length, 24, 'Invalid key length');
  var k1 = key.slice(0, 8);
  var k2 = key.slice(8, 16);
  var k3 = key.slice(16, 24);
  if (type === 'encrypt') {
    this.ciphers = [DES.create({
      type: 'encrypt',
      key: k1
    }), DES.create({
      type: 'decrypt',
      key: k2
    }), DES.create({
      type: 'encrypt',
      key: k3
    })];
  } else {
    this.ciphers = [DES.create({
      type: 'decrypt',
      key: k3
    }), DES.create({
      type: 'encrypt',
      key: k2
    }), DES.create({
      type: 'decrypt',
      key: k1
    })];
  }
}
function EDE(options) {
  Cipher.call(this, options);
  var state = new EDEState(this.type, this.options.key);
  this._edeState = state;
}
inherits(EDE, Cipher);
module.exports = EDE;
EDE.create = function create(options) {
  return new EDE(options);
};
EDE.prototype._update = function _update(inp, inOff, out, outOff) {
  var state = this._edeState;
  state.ciphers[0]._update(inp, inOff, out, outOff);
  state.ciphers[1]._update(out, outOff, out, outOff);
  state.ciphers[2]._update(out, outOff, out, outOff);
};
EDE.prototype._pad = DES.prototype._pad;
EDE.prototype._unpad = DES.prototype._unpad;