import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit
} from '@angular/core';
import {
    Router, ActivatedRoute, NavigationEnd
} from '@angular/router';
import { HttpService } from '../services/http.service';
import { filter } from 'rxjs/operators';
import { getWindow } from 'ssr-window';
import { LocalStorageService } from '../services/localstorage.service';
import { KycService } from '../services/kyc.service';
import { CfAlertService } from '@crediblefinance/credible-ui';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {
    loading: boolean = true;
    kyc_loading: boolean = false;
    showSidebar: boolean = this.getDefaults('showSidebar', true);
    showHeader: boolean = this.getDefaults('showHeader', true);
    showConnectWallet: boolean = this.getDefaults('showConnectWallet', false);
    currentCollapseStatus: boolean = this.getDefaults('sidebarCollapseStatus', false);

    width: any;
    window: Window;
    uncollapsedSidebarWidth: number = 250;
    collapsedSidebarWidth: number = 50;
    currentSidebarWidth: number = this.currentCollapseStatus ? this.collapsedSidebarWidth : this.uncollapsedSidebarWidth;
    nonSidebarWidth: number = 1920;

    isPopState = false;

    constructor(public httpService: HttpService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private kycService: KycService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService) {
        console.log('AppComponent constructor');
        this.window = getWindow();

        this.nonSidebarWidth = this.window.outerWidth;
    }
    
    ngAfterViewInit(): void {
        console.log('AppComponent ngAfterViewInit');

        this.route.queryParams.subscribe(params => {
            console.log('params', params);

            if (params['theme']) {
                const theme = params['theme'];

                if (theme === 'dark' || theme === 'light')
                    this.httpService.theme = theme;
            }
        });
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;

        this.setSidebarWidth();
    }

    ngOnInit(): void {
        console.log('AppComponent ngOnInit');

        this.setInitialTheme();

        this.width = this.window.outerWidth;

        this.subscribeRouteChangeEvents();

        this.init();
    }

    async init() {
        const getCurrencyConversionMap = this.httpService.getCurrencyConversionMap();
        const visualPrecision = this.httpService.setCurrenciesVisualPrecision();
        const getCurrentUser = this.httpService.getCurrentUser();

        await Promise.all([ getCurrencyConversionMap, visualPrecision, getCurrentUser ]);

        this.loading = false;
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    subscribeRouteChangeEvents() {
        // console.log('subscribeRouteChangeEvents');

        this.router.events.pipe(filter(event => {
            // console.log('router event', event);

            return event instanceof NavigationEnd;
        })).subscribe(() => {
            const rt = this.getChild(this.route);

            rt.data.subscribe((data: any) => {
                if (data.title && data.description)
                    this.httpService.setMetaData(data.title, data.description);

                this.showSidebar = 'showSidebar' in data ? data.showSidebar : true;
                this.showHeader = 'showHeader' in data ? data.showHeader : true;
                this.showHeader = 'showConnectWallet' in data ? data.showConnectWallet : true;

                this.setSidebarWidth();
            });
        });
    }

    getChild(route: ActivatedRoute): ActivatedRoute {
        if (route.firstChild)
            return this.getChild(route.firstChild);

        else
            return route;
    }

    sidebarCollapsed(collapsed: boolean) {
        console.log('sidebarCollapsed', collapsed);

        this.currentCollapseStatus = collapsed;
        this.localStorageService.setItem('sidebarCollapseStatus', collapsed.toString());

        this.setSidebarWidth();
    }

    setSidebarWidth() {
        if (this.width < 768)
            this.showSidebar = false;

        if (this.currentCollapseStatus) {
            this.currentSidebarWidth = this.showSidebar ? this.collapsedSidebarWidth : 0;
            this.nonSidebarWidth = this.width - this.currentSidebarWidth;
        }
        else {
            this.currentSidebarWidth = this.showSidebar ? this.uncollapsedSidebarWidth : 0;
            this.nonSidebarWidth = this.width - this.currentSidebarWidth;
        }
    }

    ngOnDestroy() {
        // console.log('ngOnDestroy AppComponent');

        this.storePreferences();
    }

    storePreferences() {
        this.localStorageService.setItem('showSidebar', this.showSidebar.toString());
        this.localStorageService.setItem('showHeader', this.showHeader.toString());
        this.localStorageService.setItem('showConnectWallet', this.showConnectWallet.toString());
    }

    getDefaults(field_name: string, fallback: boolean) {
        const value = this.localStorageService.getItem(field_name);

        if (value === null) {
            this.localStorageService.setItem(field_name, fallback.toString());

            return fallback;
        }

        return value === 'true';
    }

    setInitialTheme() {
        const theme = this.localStorageService.getItem('theme');

        if (theme && (theme === 'dark' || theme === 'light'))
            this.httpService.theme = theme;

        else {
            this.httpService.theme = 'dark';
            this.localStorageService.setItem('theme', 'dark');
        }
    }
}
