var ciphers = require('./encrypter');
var deciphers = require('./decrypter');
var modes = require('./modes/list.json');
function getCiphers() {
  return Object.keys(modes);
}
exports.createCipher = exports.Cipher = ciphers.createCipher;
exports.createCipheriv = exports.Cipheriv = ciphers.createCipheriv;
exports.createDecipher = exports.Decipher = deciphers.createDecipher;
exports.createDecipheriv = exports.Decipheriv = deciphers.createDecipheriv;
exports.listCiphers = exports.getCiphers = getCiphers;