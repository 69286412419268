<div class="app-pool-activity" [ngClass]="httpService.theme">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div class="row" *ngIf="!loading">
        <cf-card type="empty" [theme]="httpService.theme">
            <div class="col-md-12 box">
                <table>
                    <thead>
                        <tr>
                            <th>Wallet Address</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Tx Link</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="items.length > 0">
                        <tr *ngFor="let item of items">
                            <td>
                                <div class="redirection">
                                    <span class="ellipses">{{ item.wallet_address }}
                                    </span>
                                    <i class="fa-solid fa-up-right-from-square ml"
                                        (click)="httpService.redirectionAddress(item.blockchain, item.chain, item.network, item.wallet_address)"></i>
                                </div>
                            </td>
                            <td>
                                {{
                                item.amount
                                | number
                                : httpService.getPrecision(
                                item.currency
                                )
                                }}
                                {{ item.currency | uppercase }}
                            </td>
                            <td>{{ item.created | date : "mediumDate" }}</td>
                            <td>
                                <div class="redirection">
                                    <span class="ellipses">{{ item.blockchain_txid }}
                                    </span>
                                    <i class="fa-solid fa-up-right-from-square ml"
                                        (click)="httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="total_count === 0">
                        <tr>
                            <td colspan="7" class="pd">
                                No records found
                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
                    (changed)="pageChanged($event)"></cf-paginator>
            </div>
        </cf-card>
    </div>
</div>