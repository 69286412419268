import {
    Component, HostListener, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef,
    PLATFORM_ID
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { isPlatformBrowser } from '@angular/common';
import {
    getDocument, getWindow
} from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ActivatedRoute, Router
} from '@angular/router';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrl: './homepage.component.scss'
})
export class HomepageComponent implements OnInit, OnDestroy, AfterViewInit {
    platformId: object = {};
    loading: boolean = true;

    width: any;
    window: Window;
    stats: any = {
        tvl_usd: 0
    };

    category_id: string = '';
    pools: Array<any> = [];
    fixed_income_pools: Array<any> = [];
    payfi_borrow_pools: Array<any> = [];
    payfi_lend_pools: Array<any> = [];
    device: string = 'web';
    document = getDocument();

    transactionAnimationInterval: any;
    transactions: Array<any> = [];

    marquee_items: Array<{
        image_url: string
    }> = [];
    marquee_per_cycle_duration = 2;
    marquee_duplication = 30;

    numberFlippingAnimation: any = {
        steps: 15,
        interval: 100,
        clearIntervals: {}
    };

    tabs: Array<TabOption> = [{
        label: 'Borrow Cash',
        value: 'borrow'
    }, {
        label: 'Lend Stablecoins',
        value: 'lend'
    }];
    currentTab = this.tabs[0].value;
    isBrowser: boolean = false;

    constructor(public httpService: HttpService,
        private cfAlertService: CfAlertService,
        @Inject(PLATFORM_ID) platformId: object,
        private router: Router,
        private route: ActivatedRoute) {
        this.platformId = platformId;
        this.window = getWindow();

        this.isBrowser = isPlatformBrowser(platformId);
    }
    @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;

        if (this.width < 768) {
            this.device = 'mobile';
            this.marquee_per_cycle_duration = 0.5;
        }
    }

    ngOnInit(): void {
        this.width = this.window.outerWidth;

        this.getPools();
        this.getPayfiPools();
        this.getInvestments();
        this.marquee_items = this.getMarqueeCarouselItems();

        if (this.width < 768) {
            this.device = 'mobile';
            this.marquee_per_cycle_duration = 0.5;
        }
    }

    ngAfterViewInit(): void {
        if (this.isBrowser)
            this.startVideo();
    }

    startVideo() {
        const video = this.videoPlayer.nativeElement;

        video.muted = true; // Ensure it's muted
        video.autoplay = true; // Set autoplay
        video.loop = true; // Enable loop
        video.playsInline = true; // Prevent fullscreen on iOS

        video.play().catch((error) => {
            console.error('Video autoplay failed:', error);
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    selectPool(pool: any) {
        if (this.httpService.user.user_id > 0)
            this.router.navigate([ `/liquidity/${pool.category_id}/${pool.pool_id}` ]);

        else {
            this.router.navigate([ '/login' ], {
                queryParams: {
                    returnUrl: `/liquidity/${pool.category_id}/${pool.pool_id}`
                }
            });
        }
    }

    redirectToUrl(url: string, event: Event | undefined = undefined) {
        this.window.open(url, '_blank');

        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    redirectToInternalUrl(url: string) {
        this.window.open(url, '_blank');
    }

    redirectToLend() {
        this.router.navigate([ '/cash-loan/lend' ]);
    }

    redirectToBorrow() {
        this.router.navigate([ '/cash-loan/borrow' ]);
    }

    redirectToPools() {

    }

    getPayfiPools() {
        this.payfi_borrow_pools = [{
            coming_soon: false,
            collateral_image_url: this.httpService.getCurrencyUrl('usdc'),
            receiving_image_url: this.httpService.getCurrencyUrl('inr'),
            name: 'USDC',
            receiving_currency: 'inr',
            payment_methods: [ 'NEFT', 'Moneygram' ],
            yield_rate: 10,
            ltv: 75,
            blockchain: 'solana'
        }];

        this.payfi_lend_pools = [{
            coming_soon: false,
            image_url: this.httpService.getCurrencyUrl('usdc'),
            name: 'USDC',
            yield_rate: 16,
            rating: 'AA',
            tenor: 'Flexible',
            blockchain: 'solana'
        }];
    }

    getPools() {
        const data = {
            category_id: this.category_id
        };

        this.httpService.getPools(data).subscribe(res => {
            const data = res.data;

            this.pools = data;
            this.fixed_income_pools = [{
                coming_soon: false,
                collateral_image_url: this.httpService.getCurrencyUrl('usdc'),
                name: 'usdc',
                receiving_currency: 'inr',
                yield_rate: 8,
                ltv: 60,
                blockchain: 'solana'
            }];
            let tvl_usd = 0;

            this.pools.forEach(x => {
                if (x.category_id === 'private-credit') {
                    x.type = 'Private Credit';
                    this.fixed_income_pools.push(x);
                }

                if (x.category_id === 'partner-protocol') {
                    x.type = 'Partner Protocol';
                    this.fixed_income_pools.push(x);
                }

                if (x.category_id === 'vault') {
                    x.type = 'Vault';
                    this.fixed_income_pools.push(x);
                }

                tvl_usd += x.stats.tvl_usd;
            });

            if (isPlatformBrowser(this.platformId))
                this.startNumberFlippingAnimation('tvl_usd', 0, tvl_usd);

            else
                this.stats.tvl_usd = tvl_usd;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getMarqueeCarouselItems() {
        const arr = [{
            image_url: 'https://assets.credible.finance/brands/Circle.png'
        },
        {
            image_url: 'https://assets.credible.finance/brands/Damia.png'
        },
        {
            image_url: 'https://assets.credible.finance/brands/Etherfuse.png'
        },
        {
            image_url: 'https://assets.credible.finance/brands/GelCredit.png'
        },
        {
            image_url: 'https://assets.credible.finance/brands/Muderx.png'
        },
        {
            image_url: 'https://assets.credible.finance/brands/OpenAI.png'
        },
        {
            image_url: 'https://assets.credible.finance/brands/Spacex1.png'
        }];

        return arr;
    }

    startTransactionAnimation() {
        if (!isPlatformBrowser(this.platformId))
            return;

        let i = 0;
        const _transactions = this.document.getElementsByClassName('transactions') as HTMLCollectionOf<HTMLElement>;

        console.log('_transactions', _transactions);

        const transactionsContainer = _transactions[0];

        console.log('transactionsContainer', transactionsContainer);

        this.transactionAnimationInterval = setInterval(function () {
            i += 1; // speed

            transactionsContainer.scrollTop = i;

            const scrollHeight = transactionsContainer.scrollHeight;

            const clientHeight = transactionsContainer.clientHeight;

            const difference = scrollHeight - clientHeight;

            if (i >= difference)
                i = 0;
        }, 100);
    }

    getInvestments() {
        this.loading = true;

        const body = {
            page: 0,
            limit: 25,
            token_required: false,
            status: 'completed'
        };

        this.httpService.getInvestments(body).subscribe((res: any) => {
            this.transactions = res.data;

            this.loading = false;

            this.startTransactionAnimation();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    startNumberFlippingAnimation(key: string, start: number, end: number) {
        const step = (end - start) / this.numberFlippingAnimation.steps;
        let current = start;
        let i = 0;

        this.numberFlippingAnimation.clearIntervals[key] = setInterval(() => {
            current += step;

            if (i === this.numberFlippingAnimation.steps - 1)
                current = end;

            this.stats[key] = current;

            i++;

            if (i === this.numberFlippingAnimation.steps)
                clearInterval(this.numberFlippingAnimation.clearIntervals[key]);
        }, this.numberFlippingAnimation.interval);
    }

    scrollUp() {
        console.log('Scroll');

        this.window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.transactionAnimationInterval);

        for (const key in this.numberFlippingAnimation.clearIntervals) {
            if (this.numberFlippingAnimation.clearIntervals.hasOwnProperty(key))
                clearInterval(this.numberFlippingAnimation.clearIntervals[key]);
        }
    }
}
