var modeModules = {
  ECB: require('./ecb'),
  CBC: require('./cbc'),
  CFB: require('./cfb'),
  CFB8: require('./cfb8'),
  CFB1: require('./cfb1'),
  OFB: require('./ofb'),
  CTR: require('./ctr'),
  GCM: require('./ctr')
};
var modes = require('./list.json');
for (var key in modes) {
  modes[key].module = modeModules[modes[key].mode];
}
module.exports = modes;