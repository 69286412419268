import { LocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, NgZone, OnInit
} from '@angular/core';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { OnboardingService } from '../onboarding.service';
import { nullChecker } from '../../helpers/nullChecker';

import { RECAPTCHA_SITE_KEY } from '../../app/app.config';
import { WalletService } from '../../services/wallet.service';

declare let grecaptcha: any;

@Component({
    selector: 'app-login-with-wallet',
    templateUrl: './login-with-wallet.component.html',
    styleUrl: './login-with-wallet.component.scss'
})

export class LoginWithWalletComponent implements OnInit {
    steps = [{
        step: 'connect-wallet',
        title: 'Wallet',
        completed: false
    },
    {
        step: 'email',
        title: 'Email',
        completed: false
    },
    {
        step: 'otp',
        title: 'OTP',
        completed: false
    }];

    connectedWalletAddres: string = '';
    provider: string = '';

    currentStep = -1;
    currentCollapseStatus: boolean = false;
    step_skipping_allowed: boolean = false;
    returnUrl: string = '';

    email: string = '';
    otp: string = '';
    loading: boolean = false;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private onboardingService: OnboardingService,
        private router: Router,
        private location: LocationStrategy,
        private walletService: WalletService,
        private localStorageService: LocalStorageService,
        private _ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        if (this.httpService.user.user_id > 0) {
            this.connectedWalletAddres = this.walletService.wallet_address;
            this.provider = this.walletService.current_provider;

            this.steps[0].completed = true;
        }

        if (this.httpService.user.email !== '') {
            this.connectedWalletAddres = this.walletService.wallet_address;
            this.provider = 'circle';

            this.steps[0].completed = true;
        }

        this.subscribeQueryParams();
        this.checkNextStep();
    }

    subscribeQueryParams() {
        this.route.queryParams.subscribe(params => {
            if (params['tab'])
                this.returnUrl = params['returnUrl'];

            if (params['invite_code'])
                this.validateInviteCode(params['invite_code']);

            else
                this.httpService.setMetaData('Connect wallet', 'Connect your wallet');
        });
    }

    validateInviteCode(invite_code: string) {
        this.onboardingService.validateInviteCode(invite_code).subscribe((res: any) => {
            const title = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;
            const description = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;

            this.httpService.setMetaData(title, description);
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    checkNextStep() {
        this._ngZone.run(() => {
            let currentStep = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (!this.steps[i].completed) {
                    currentStep = i;
                    break;
                }
            }

            console.log('checkNextStep => ', currentStep);

            if (currentStep === -1) {
                console.log('All steps completed');

                const redirect_url = !this.returnUrl || this.returnUrl === '' ? '/airdrop' : this.returnUrl;
                const base_url = window.location.origin;
                const full_url = `${base_url}${redirect_url}`;

                history.pushState(null, '', full_url);

                this.router.navigateByUrl(redirect_url, {
                    replaceUrl: true
                });

                this.location.onPopState(() => {
                    history.pushState(null, '', full_url);
                });
            }

            else
                this.currentStep = currentStep;
        });
    }

    changeStep(index: number) {
        if (this.step_skipping_allowed)
            this.currentStep = index;
    }

    previousStep() {
        if (this.currentStep !== 0)
            this.currentStep -= 1;
    }

    notifyWalletConnected($event: any) {
        console.log('login-with-wallet : notifyWalletConnected => ', $event);

        const { provider, wallet_address, email } = $event;

        this.connectedWalletAddres = wallet_address;
        this.provider = provider;
        this.email = email;

        this.steps[0].completed = true;

        if (!nullChecker(email)) {
            this.cfAlertService.showSuccess(`Verification code sent to ${email}`);
            this.steps[1].completed = true;
        }

        this.checkNextStep();
    }

    changeEmail(email: string) {
        this.email = email;
    }

    changeOtp(otp: string) {
        this.otp = otp;
    }

    verifyLoginEmailOtp() {
        this.loading = true;

        const body = {
            email: this.email,
            otp: +this.otp
        };

        this.onboardingService.verifyLoginEmailOtp(body).subscribe((res: any) => {
            this.cfAlertService.showSuccess('Login successful');

            this.localStorageService.setItem('token', res.headers.get('token') || '');

            this.httpService.getCurrentUser(() => {
                this.loading = false;

                this.router.navigate([ this.returnUrl || '/airdrop' ]);
            });
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }

    linkEmailWithWalletAddress() {
        grecaptcha.enterprise.ready(async () => {
            const recaptcha = await grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, {
                action: 'checkWalletAddress'
            });

            this.loading = true;

            const body = {
                email: this.email,
                wallet_address: this.connectedWalletAddres,
                recaptcha: recaptcha
            };

            this.onboardingService.linkEmailWithWalletAddress(body).subscribe((res: any) => {
                this.loading = false;

                this.cfAlertService.showSuccess(res.message);

                this.steps[1].completed = true;

                this.checkNextStep();
            }, (err: HttpErrorResponse) => {
                this.cfAlertService.showError(err);
                this.loading = false;
            });
        });
    }

    resendVerificationCode() {
        this.loading = true;
        const body = {
            email: this.email,
            type: 'login'
        };

        this.onboardingService.resendEmailOtp(body).subscribe((res: any) => {
            this.loading = false;

            console.log('resend email otp', res.data);
            this.cfAlertService.showSuccess(res.message);
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }
}
