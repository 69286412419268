var Buffer = require('safe-buffer').Buffer;
var xor = require('buffer-xor');
function encryptStart(self, data, decrypt) {
  var len = data.length;
  var out = xor(data, self._cache);
  self._cache = self._cache.slice(len);
  self._prev = Buffer.concat([self._prev, decrypt ? data : out]);
  return out;
}
exports.encrypt = function (self, data, decrypt) {
  var out = Buffer.allocUnsafe(0);
  var len;
  while (data.length) {
    if (self._cache.length === 0) {
      self._cache = self._cipher.encryptBlock(self._prev);
      self._prev = Buffer.allocUnsafe(0);
    }
    if (self._cache.length <= data.length) {
      len = self._cache.length;
      out = Buffer.concat([out, encryptStart(self, data.slice(0, len), decrypt)]);
      data = data.slice(len);
    } else {
      out = Buffer.concat([out, encryptStart(self, data, decrypt)]);
      break;
    }
  }
  return out;
};