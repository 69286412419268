<div class="app-add-payout-beneficiary" [ngClass]="httpService.theme">
    <div class="page-heading" mat-dialog-title>Add beneficiary</div>

    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>

    <mat-dialog-content>
        <div *ngIf="!loading">
            <div class="field">
                <cf-dropdown [theme]="httpService.theme" [options]="payout_currency_options" label="Select currency"
                    (change)="payoutCurrencyChanged($event)" [selected]="selected_payout_currency" [showLogo]="true">
                </cf-dropdown>
            </div>
            <div class="field">
                <cf-dropdown [theme]="httpService.theme" [options]="payout_method_options" label="Select payout method"
                    (change)="payoutMethodChanged($event)" [selected]="selected_payout_method" [showLogo]="false">
                </cf-dropdown>
            </div>
            <div class="field" *ngFor="let input_field of payout_method_input_fields">
                <cf-input *ngIf="input_field.type === 'input_text'" [theme]="httpService.theme"
                    [label]="input_field.label" [readonly]="input_field.readonly"
                    (valueChanged)="textInputChanged($event, input_field.key)" [placeholder]="input_field.placeholder"
                    [value]="input_field.value"></cf-input>
                <cf-checkbox-large [theme]="httpService.theme" *ngIf="input_field.type === 'radio_button'"
                    [options]="input_field.options" [label]="input_field.label"
                    (changed)="checkboxChanged($event, input_field.key)"
                    [selected]="input_field.value"></cf-checkbox-large>
            </div>
            <!-- <div class="payout-beneficiary-summary">
                <div class="summary-item">
                    <span class="summary-heading">Bank name</span>
                    <span class="summary-value"></span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Bank branch</span>
                    <span class="summary-value"></span>
                </div>
            </div> -->
        </div>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!loading">
        <div class="controls">
            <cf-button [theme]="httpService.theme" type="outline" label="Cancel" (clicked)="closeDialog()"></cf-button>
            <cf-button [theme]="httpService.theme" type="solid" label="Submit"
                [loading]="add_payout_beneficiary_loading" (clicked)="addPayoutBeneficiary()"></cf-button>
        </div>
    </mat-dialog-actions>
</div>