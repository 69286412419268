var aes = require('./aes');
var Buffer = require('safe-buffer').Buffer;
var Transform = require('cipher-base');
var inherits = require('inherits');
function StreamCipher(mode, key, iv, decrypt) {
  Transform.call(this);
  this._cipher = new aes.AES(key);
  this._prev = Buffer.from(iv);
  this._cache = Buffer.allocUnsafe(0);
  this._secCache = Buffer.allocUnsafe(0);
  this._decrypt = decrypt;
  this._mode = mode;
}
inherits(StreamCipher, Transform);
StreamCipher.prototype._update = function (chunk) {
  return this._mode.encrypt(this, chunk, this._decrypt);
};
StreamCipher.prototype._final = function () {
  this._cipher.scrub();
};
module.exports = StreamCipher;