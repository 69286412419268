// based on the aes implimentation in triple sec
// https://github.com/keybase/triplesec
// which is in turn based on the one from crypto-js
// https://code.google.com/p/crypto-js/

var Buffer = require('safe-buffer').Buffer;
function asUInt32Array(buf) {
  if (!Buffer.isBuffer(buf)) buf = Buffer.from(buf);
  var len = buf.length / 4 | 0;
  var out = new Array(len);
  for (var i = 0; i < len; i++) {
    out[i] = buf.readUInt32BE(i * 4);
  }
  return out;
}
function scrubVec(v) {
  for (var i = 0; i < v.length; v++) {
    v[i] = 0;
  }
}
function cryptBlock(M, keySchedule, SUB_MIX, SBOX, nRounds) {
  var SUB_MIX0 = SUB_MIX[0];
  var SUB_MIX1 = SUB_MIX[1];
  var SUB_MIX2 = SUB_MIX[2];
  var SUB_MIX3 = SUB_MIX[3];
  var s0 = M[0] ^ keySchedule[0];
  var s1 = M[1] ^ keySchedule[1];
  var s2 = M[2] ^ keySchedule[2];
  var s3 = M[3] ^ keySchedule[3];
  var t0, t1, t2, t3;
  var ksRow = 4;
  for (var round = 1; round < nRounds; round++) {
    t0 = SUB_MIX0[s0 >>> 24] ^ SUB_MIX1[s1 >>> 16 & 0xff] ^ SUB_MIX2[s2 >>> 8 & 0xff] ^ SUB_MIX3[s3 & 0xff] ^ keySchedule[ksRow++];
    t1 = SUB_MIX0[s1 >>> 24] ^ SUB_MIX1[s2 >>> 16 & 0xff] ^ SUB_MIX2[s3 >>> 8 & 0xff] ^ SUB_MIX3[s0 & 0xff] ^ keySchedule[ksRow++];
    t2 = SUB_MIX0[s2 >>> 24] ^ SUB_MIX1[s3 >>> 16 & 0xff] ^ SUB_MIX2[s0 >>> 8 & 0xff] ^ SUB_MIX3[s1 & 0xff] ^ keySchedule[ksRow++];
    t3 = SUB_MIX0[s3 >>> 24] ^ SUB_MIX1[s0 >>> 16 & 0xff] ^ SUB_MIX2[s1 >>> 8 & 0xff] ^ SUB_MIX3[s2 & 0xff] ^ keySchedule[ksRow++];
    s0 = t0;
    s1 = t1;
    s2 = t2;
    s3 = t3;
  }
  t0 = (SBOX[s0 >>> 24] << 24 | SBOX[s1 >>> 16 & 0xff] << 16 | SBOX[s2 >>> 8 & 0xff] << 8 | SBOX[s3 & 0xff]) ^ keySchedule[ksRow++];
  t1 = (SBOX[s1 >>> 24] << 24 | SBOX[s2 >>> 16 & 0xff] << 16 | SBOX[s3 >>> 8 & 0xff] << 8 | SBOX[s0 & 0xff]) ^ keySchedule[ksRow++];
  t2 = (SBOX[s2 >>> 24] << 24 | SBOX[s3 >>> 16 & 0xff] << 16 | SBOX[s0 >>> 8 & 0xff] << 8 | SBOX[s1 & 0xff]) ^ keySchedule[ksRow++];
  t3 = (SBOX[s3 >>> 24] << 24 | SBOX[s0 >>> 16 & 0xff] << 16 | SBOX[s1 >>> 8 & 0xff] << 8 | SBOX[s2 & 0xff]) ^ keySchedule[ksRow++];
  t0 = t0 >>> 0;
  t1 = t1 >>> 0;
  t2 = t2 >>> 0;
  t3 = t3 >>> 0;
  return [t0, t1, t2, t3];
}

// AES constants
var RCON = [0x00, 0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x40, 0x80, 0x1b, 0x36];
var G = function () {
  // Compute double table
  var d = new Array(256);
  for (var j = 0; j < 256; j++) {
    if (j < 128) {
      d[j] = j << 1;
    } else {
      d[j] = j << 1 ^ 0x11b;
    }
  }
  var SBOX = [];
  var INV_SBOX = [];
  var SUB_MIX = [[], [], [], []];
  var INV_SUB_MIX = [[], [], [], []];

  // Walk GF(2^8)
  var x = 0;
  var xi = 0;
  for (var i = 0; i < 256; ++i) {
    // Compute sbox
    var sx = xi ^ xi << 1 ^ xi << 2 ^ xi << 3 ^ xi << 4;
    sx = sx >>> 8 ^ sx & 0xff ^ 0x63;
    SBOX[x] = sx;
    INV_SBOX[sx] = x;

    // Compute multiplication
    var x2 = d[x];
    var x4 = d[x2];
    var x8 = d[x4];

    // Compute sub bytes, mix columns tables
    var t = d[sx] * 0x101 ^ sx * 0x1010100;
    SUB_MIX[0][x] = t << 24 | t >>> 8;
    SUB_MIX[1][x] = t << 16 | t >>> 16;
    SUB_MIX[2][x] = t << 8 | t >>> 24;
    SUB_MIX[3][x] = t;

    // Compute inv sub bytes, inv mix columns tables
    t = x8 * 0x1010101 ^ x4 * 0x10001 ^ x2 * 0x101 ^ x * 0x1010100;
    INV_SUB_MIX[0][sx] = t << 24 | t >>> 8;
    INV_SUB_MIX[1][sx] = t << 16 | t >>> 16;
    INV_SUB_MIX[2][sx] = t << 8 | t >>> 24;
    INV_SUB_MIX[3][sx] = t;
    if (x === 0) {
      x = xi = 1;
    } else {
      x = x2 ^ d[d[d[x8 ^ x2]]];
      xi ^= d[d[xi]];
    }
  }
  return {
    SBOX: SBOX,
    INV_SBOX: INV_SBOX,
    SUB_MIX: SUB_MIX,
    INV_SUB_MIX: INV_SUB_MIX
  };
}();
function AES(key) {
  this._key = asUInt32Array(key);
  this._reset();
}
AES.blockSize = 4 * 4;
AES.keySize = 256 / 8;
AES.prototype.blockSize = AES.blockSize;
AES.prototype.keySize = AES.keySize;
AES.prototype._reset = function () {
  var keyWords = this._key;
  var keySize = keyWords.length;
  var nRounds = keySize + 6;
  var ksRows = (nRounds + 1) * 4;
  var keySchedule = [];
  for (var k = 0; k < keySize; k++) {
    keySchedule[k] = keyWords[k];
  }
  for (k = keySize; k < ksRows; k++) {
    var t = keySchedule[k - 1];
    if (k % keySize === 0) {
      t = t << 8 | t >>> 24;
      t = G.SBOX[t >>> 24] << 24 | G.SBOX[t >>> 16 & 0xff] << 16 | G.SBOX[t >>> 8 & 0xff] << 8 | G.SBOX[t & 0xff];
      t ^= RCON[k / keySize | 0] << 24;
    } else if (keySize > 6 && k % keySize === 4) {
      t = G.SBOX[t >>> 24] << 24 | G.SBOX[t >>> 16 & 0xff] << 16 | G.SBOX[t >>> 8 & 0xff] << 8 | G.SBOX[t & 0xff];
    }
    keySchedule[k] = keySchedule[k - keySize] ^ t;
  }
  var invKeySchedule = [];
  for (var ik = 0; ik < ksRows; ik++) {
    var ksR = ksRows - ik;
    var tt = keySchedule[ksR - (ik % 4 ? 0 : 4)];
    if (ik < 4 || ksR <= 4) {
      invKeySchedule[ik] = tt;
    } else {
      invKeySchedule[ik] = G.INV_SUB_MIX[0][G.SBOX[tt >>> 24]] ^ G.INV_SUB_MIX[1][G.SBOX[tt >>> 16 & 0xff]] ^ G.INV_SUB_MIX[2][G.SBOX[tt >>> 8 & 0xff]] ^ G.INV_SUB_MIX[3][G.SBOX[tt & 0xff]];
    }
  }
  this._nRounds = nRounds;
  this._keySchedule = keySchedule;
  this._invKeySchedule = invKeySchedule;
};
AES.prototype.encryptBlockRaw = function (M) {
  M = asUInt32Array(M);
  return cryptBlock(M, this._keySchedule, G.SUB_MIX, G.SBOX, this._nRounds);
};
AES.prototype.encryptBlock = function (M) {
  var out = this.encryptBlockRaw(M);
  var buf = Buffer.allocUnsafe(16);
  buf.writeUInt32BE(out[0], 0);
  buf.writeUInt32BE(out[1], 4);
  buf.writeUInt32BE(out[2], 8);
  buf.writeUInt32BE(out[3], 12);
  return buf;
};
AES.prototype.decryptBlock = function (M) {
  M = asUInt32Array(M);

  // swap
  var m1 = M[1];
  M[1] = M[3];
  M[3] = m1;
  var out = cryptBlock(M, this._invKeySchedule, G.INV_SUB_MIX, G.INV_SBOX, this._nRounds);
  var buf = Buffer.allocUnsafe(16);
  buf.writeUInt32BE(out[0], 0);
  buf.writeUInt32BE(out[3], 4);
  buf.writeUInt32BE(out[2], 8);
  buf.writeUInt32BE(out[1], 12);
  return buf;
};
AES.prototype.scrub = function () {
  scrubVec(this._keySchedule);
  scrubVec(this._invKeySchedule);
  scrubVec(this._key);
};
module.exports.AES = AES;