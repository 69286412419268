<div class="app-bank-accounts" [ngClass]="httpService.theme">
    <div class="controls">
        <cf-button [theme]="httpService.theme" (clicked)="openDialog()" label="Add beneficiary"></cf-button>
    </div>

    <div class="payout-beneficiaries">
        <cf-card [theme]="httpService.theme" *ngFor="let payout_beneficiary of payout_beneficiaries" type="empty">
            <div class="fields">
                <div class="field" *ngFor="let field of payout_beneficiary.fields">
                    <div class="label">{{field.label | titlecase}}</div>
                    <div class="value">{{ field.value }}</div>
                </div>
            </div>
        </cf-card>
    </div>
</div>