import {
    HttpClient, HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';
import { serverUrl } from '../app/app.config';

@Injectable({
    providedIn: 'root'
})
export class PayFiService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) { }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getUserTotalCollateral() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getUserTotalCollateral`, httpOptions);
    }

    addCollateral(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/addCollateral`, body, httpOptions);
    }

    updateAddCollateral(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/updateAddCollateral`, body, httpOptions);
    }

    removeCollateral(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/removeCollateral`, body, httpOptions);
    }

    addPayoutBeneficiary(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/addPayoutBeneficiary`, body, httpOptions);
    }

    updateRemoveCollateral(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/updateRemoveCollateral`, body, httpOptions);
    }

    createDrawdown(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/createDrawdown`, body, httpOptions);
    }

    newCollateral(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/newCollateral`, body, httpOptions);
    }

    updateDrawdown(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/updateDrawdown`, body, httpOptions);
    }

    updateCollateral(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/updateCollateral`, body, httpOptions);
    }

    createRepaymentWallet(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/createRepaymentWallet`, body, httpOptions);
    }

    updateRepayment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}payfi/updateRepayment`, body, httpOptions);
    }

    getPools() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPools`, httpOptions);
    }

    getPayFiPoolCommonData() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPayFiPoolCommonData`, httpOptions);
    }

    getPayFiPoolStats(lending_pool_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPayFiPoolStats?lending_pool_id=${lending_pool_id}`, httpOptions);
    }

    getPayFiUserPoolStats(lending_pool_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPayFiUserPoolStats?lending_pool_id=${lending_pool_id}`, httpOptions);
    }

    getUserActivityPayfi(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getUserActivity?page=${body.page}&limit=${body.limit}`, httpOptions);
    }

    getCollaterals(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}payfi/getCollaterals?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}payfi/getCollaterals?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getDrawdowns(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}payfi/getDrawdowns?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}payfi/getDrawdowns?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getDrawdownStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}payfi/getDrawdownStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}payfi/getDrawdownStats?filter=${filter}`, httpOptions);
        }
    }

    getRepayments(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}payfi/getRepayments?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}payfi/getRepayments?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getRepaymentStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}payfi/getRepaymentStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}payfi/getRepaymentStats?filter=${filter}`, httpOptions);
        }
    }

    getPayFiPoolDetails(pool_id: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPayFiPoolDetails?pool_id=${pool_id}`, httpOptions);
    }

    getPayoutBeneficiaries(currency?: string, payout_method?: string, usage?: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPayoutBeneficiaries?currency=${currency}&payout_method=${payout_method}&usage=${usage}`, httpOptions);
    }

    getUsdcPoolId() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getUsdcPoolId`, httpOptions);
    }

    getCollateralCurrencies() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getCollateralCurrencies`, httpOptions);
    }

    getPayoutCurrencies() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPayoutCurrencies`, httpOptions);
    }

    getPaymentMethods(currency: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPaymentMethods?currency=${currency}`, httpOptions);
    }

    getFiatRates() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getFiatRates`, httpOptions);
    }

    getLendingCurrencies(blockchain: string, chain: string = '') {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getLendingCurrencies?blockchain=${blockchain}&chain=${chain}`, httpOptions);
    }

    getInvestments(data: any) {
        const queryString = new URLSearchParams(data).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getInvestments?${queryString}`, httpOptions);
    }

    getPrincipalWithdrawals(data: any) {
        const queryString = new URLSearchParams(data).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPrincipalWithdrawals?${queryString}`, httpOptions);
    }
}
