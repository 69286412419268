import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PayFiService } from '../../services/payfi.service';

@Component({
    selector: 'app-pool-activity-payfi',
    templateUrl: './pool-activity-payfi.component.html',
    styleUrl: './pool-activity-payfi.component.scss'
})
export class PoolActivityPayFiComponent implements OnInit {
    items: any = [{}, {}, {}, {}, {}, {}, {}];
    currentDate: string | number | Date = new Date().getTime();
    loading: boolean = false;
    userActivity: any;
    total_count: number = 0;
    page: number = 0;
    limit: number = 10;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private payFiService: PayFiService
    ) { }

    ngOnInit(): void {
        this.getUserActivity();
    }

    getUserActivity() {
        const body = {
            page: this.page,
            limit: 10
        };

        this.payFiService.getUserActivityPayfi(body).subscribe(
            (res) => {
                this.loading = false;
                this.userActivity = res.data;
                this.total_count = res.total_count;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getUserActivity();
    }
}
