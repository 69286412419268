<div class="app-payfi container-fluid" [ngClass]="httpService.theme">
    <div class="main-container">
        <div class="section-payFi">
            <div class="header-container">
                <div class="header-content">
                    <span class="header-title">
                        Lend Stablecoins & Borrow Cash
                    </span>
                    <span class="header-subTitle">
                        All loans are overcollateralized with on-chain collateral, including
                        cryptocurrencies,<br />tokenized RWAs, and staked assets.
                    </span>

                    <!-- <div class="chips-container">
                        <div class="card-content">
                            <span class="card-title">Rating Type : </span>
                            <span class="card-value">Indicative</span>
                        </div>
                        <div class="card-content">
                            <span class="card-title">Rating Agency Equivalent : </span>
                            <span class="card-value">{{ commonData?.lender_overview?.rating_agency_equivalent }}</span>
                        </div>
                        <div class="card-content">
                            <span class="card-title">Implied PD : </span>
                            <span class="card-value">{{ commonData?.lender_overview?.implied_pd }}%</span>
                        </div>
                    </div> -->

                    <!-- <div class="rating-container">
                        <span class="rating-text">
                            Ratings and credit assessments provided by <a href="https://credora.io" target="_blank"
                                class="credora-link">{{ commonData?.lender_overview?.rating_agency }}</a>
                        </span>

                    </div> -->

                </div>

                <div class="button-container">
                    <cf-button [theme]="httpService.theme" label="Lend" type="solid" (clicked)="lend()"
                        [ngStyle]="{ 'margin-right': '1rem' }"></cf-button>
                    <cf-button [theme]="httpService.theme" label="Borrow" type="solid" (clicked)="borrow()"></cf-button>
                </div>
            </div>

            <div class="cards">
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">Lent Amount</span>
                        <span class="card-value">${{ poolStatsData.investment | number:httpService.getPrecision('usd')
                            }} </span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">Borrowed Amount</span>
                        <span class="card-value">${{ poolStatsData.drawdown | number:httpService.getPrecision('usd') }}
                        </span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">Lend APY</span>
                        <span class="card-value">{{ poolStatsData.apy_percentage | number : "0.0-2" }}%</span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">Borrow APY</span>
                        <!-- <span class="card-value">{{ poolStatsData.utilization | number : "0.0-2" }}%</span> -->
                        <span class="card-value">10%</span>
                    </div>
                </cf-card>
            </div>

            <div class="graph">
                <cf-card type="empty" [theme]="httpService.theme" *ngIf="!chart_loading">
                    <app-line-chart (showCharts)="showCharts($event)" [lineConfig]="chartLineConfig"
                        [title]="chartTitle" [currency]="'USDC'"></app-line-chart>
                </cf-card>
            </div>
        </div>


        <div class="section-my-borrowing">
            <div class="header-container">
                <span class="header-title">My Borrowings</span>
            </div>
            <div class="cards">
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">My Borrowings</span>
                        <span class="card-value">${{ userPoolStatsData?.borrowings?.my_borrowings | number }} </span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">My Repayments</span>
                        <span class="card-value">${{ userPoolStatsData?.borrowings?.my_repayments | number }} </span>
                    </div>
                </cf-card>
            </div>
            <div class="table-my-borrowing">
                <cf-card type="empty" [theme]="httpService.theme">
                    <app-my-borrowings-payfi></app-my-borrowings-payfi>
                </cf-card>
            </div>
        </div>

        <div class="section-my-investments">
            <div class="header-container">
                <span class="header-title">My Investments</span>
            </div>
            <div class="cards">
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">My Investments</span>
                        <span class="card-value">${{ userPoolStatsData?.investments?.my_investments |
                            number:httpService.getPrecision('usd') }} </span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">My Withdrawals</span>
                        <span class="card-value">${{ userPoolStatsData?.investments?.withdrawals |
                            number:httpService.getPrecision('usd') }} </span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">My Earnings</span>
                        <span class="card-value">${{ userPoolStatsData?.investments?.earnings |
                            number:httpService.getPrecision('usd') }} </span>
                    </div>
                </cf-card>
                <cf-card type="empty" [theme]="httpService.theme">
                    <div class="card-content">
                        <span class="card-title">Unclaimed Earnings</span>
                        <span class="card-value">${{this.unclaimed_interest_usd | number:httpService.getPrecision('usd')
                            }}</span>
                    </div>
                </cf-card>
            </div>
            <div class="table-my-investments">
                <cf-card type="empty" [theme]="httpService.theme">
                    <app-my-investments-payfi></app-my-investments-payfi>
                </cf-card>
            </div>
        </div>

        <div class="section-pool-activity">
            <cf-card type="empty" [theme]="httpService.theme">
                <app-pool-activity-payfi></app-pool-activity-payfi>
            </cf-card>
        </div>

        <!-- <div class="section-faq">
            <app-faq-payfi></app-faq-payfi>
        </div> -->
    </div>

</div>