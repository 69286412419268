import {
    Component, OnInit 
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-node-purchase',
    templateUrl: './node-purchase.component.html',
    styleUrl: './node-purchase.component.scss'
})
export class NodePurchaseComponent implements OnInit {
    paymentOption: Array<IDropdown> = [{
        label: 'Wallet',
        value: 'wallet',
        optionEnabled: true
    }];
    payment_id : string = '';
    termsAndConditions: boolean = false;
    
    constructor(
            public httpService: HttpService
          
    ) {}
    
    ngOnInit(): void {
        
    }

    changePayment(event: any){

    }

    confirmPurchase(){

    }

    agreeTerms() {
        this.termsAndConditions = !this.termsAndConditions;
    }
}
