<div class="app-borrower-dashboard" [ngClass]="httpService.theme">
    <!-- <span class="page-heading">Tokenize</span> -->


    <div class="section-1">
        <span class="section-heading">Hello {{ httpService.user.display_name }}</span>

        <div class="overview">
            <div class="left-side">
                <cf-card [theme]="httpService.theme" type="empty">
                    <div class="balance-container">
                        <span class="balance-container-title">Available to Drawdown</span>
                        <span class="balance-container-value">
                            <span style="font-size: 18px !important"> $</span>
                            {{ total_balance | number : "0.0-2" }}</span>

                        <div class="actions">
                            <cf-button [theme]="httpService.theme" label="Drawdown"
                                (clicked)="redirectToBorrowPoolDetails('drawdown', '')"></cf-button>
                            <cf-button [theme]="httpService.theme" label="Repay"
                                (clicked)="redirectToBorrowPoolDetails('repay', '')"></cf-button>
                            <cf-button [theme]="httpService.theme" label="Create pool" (clicked)="
                            redirectToExternalUrl(
                                'https://kb3uxn7bgba.typeform.com/to/vkgK78ni'
                            )
                        ">
                            </cf-button>
                        </div>
                    </div>
                </cf-card>

                <div class="friends-container">
                    <cf-card [theme]="httpService.theme" type="empty">
                        <div class="friend-container" matTooltip="Amount to be repaid">
                            <div class="friend-container-value-extra">
                                <div class="repayment-interest">
                                    <div style="font-size: 18px !important">
                                        $</div>
                                    <div>{{ repayment_interest_usd | number : "0.0-2" }}</div>
                                    <div class="refresh-icon" *ngIf="
                                repayment_amount_loaded &&
                                    httpService.user.user_id > 0
                                ">
                                        <i class="fas fa-redo fa-sm custom-size" [matTooltip]="
                                    repayment_amount_tooltip_text
                                    " (click)="updateUserRepaymentAmount()" style="color: #ffffff"></i>
                                    </div>
                                </div>
                                <div class="refresh" *ngIf="
                                    !repayment_amount_loaded &&
                                    httpService.user.user_id > 0
                                ">
                                    <cf-loader [diameter]="20"></cf-loader>
                                </div>
                            </div>
                            <div class="friend-container-title">
                                Pending amount
                            </div>

                        </div>
                    </cf-card>
                    <cf-card [theme]="httpService.theme" type="empty">
                        <div class="friend-container">
                            <div class="friend-container-value">
                                <span style="font-size: 18px !important"> $</span>
                                {{ outstanding_drawdown_usd | number : "0.0-2" }}
                            </div>
                            <div class="friend-container-title">
                                Total outstanding
                            </div>
                        </div>
                    </cf-card>
                </div>
            </div>

            <div class="right-side">
                <cf-card [theme]="httpService.theme" type="empty">
                    <div class="portfolio-container">
                        <span class="card-heading">Portfolio</span>

                        <div class="portfolio-chart" *ngIf="chart_loaded">
                            <app-line-chart [lineConfig]="chartLineConfig" title="" [showLegend]="false"
                                [currency]="'USDC'" [height]="260"></app-line-chart>
                        </div>
                    </div>
                </cf-card>
            </div>
        </div>
    </div>



    <div class="section-2">
        <cf-card [theme]="httpService.theme" type="empty">
            <div>
                <div class="tabs">
                    <span class="tab" (click)="changeTab(tab.key)" [ngClass]="{ 'tab-active': currentTab === tab.key }"
                        *ngFor="let tab of tabs">{{ tab.name }}</span>
                </div>
                <div class="tabs-divider"></div>

                <div class="section-5" *ngIf="currentTab === 'pools'">
                    <div class="pools-container">
                        <div class="pools">
                            <table class="web">
                                <thead>
                                    <tr>
                                        <th>Pool</th>
                                        <th>Total Available</th>
                                        <th>Total Outstanding</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of active_pools">
                                        <td>
                                            <div class="pool-name-container" *ngIf="item?.pool_id"
                                                (click)="redirectToPool(item.category_id, item.pool_id)">
                                                <img [src]="item.pool_logo" [alt]="item.pool_logo" class="pool-logo" />
                                                <span class="pool-name">{{
                                                    item.pool_name
                                                    }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="user-balance">
                                                {{ item.total_available | number : "0.0-2" }}
                                                {{ item.pool_currency | uppercase }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="user-balance">
                                                {{ item.total_outstanding | number : "0.0-2" }}
                                                {{ item.pool_currency | uppercase }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="active_pools.length === 0">
                                        <td colspan="3" class="no-record">
                                            No pools available
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot></tfoot>
                            </table>

                            <div class="mobile">
                                <table>
                                    <tbody>
                                        <tr *ngFor="let item of active_pools">
                                            <table>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Pool</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="pool-name-container" *ngIf="item?.pool_id" (click)="
                                                        redirectToPool(item.category_id, item.pool_id)
                                                    ">
                                                            <img [src]="item.pool_logo" [alt]="item.pool_logo"
                                                                class="pool-logo" />
                                                            <span class="pool-name ellipses">{{
                                                                item.pool_name
                                                                }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Total Available</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="user-balance">
                                                            {{ item.total_available | number : "0.0-2" }}
                                                            {{ item.pool_currency | uppercase }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Total Outstanding</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="user-balance">
                                                            {{ item.total_outstanding | number : "0.0-2" }}
                                                            {{ item.pool_currency | uppercase }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                        <tr *ngIf="active_pools.length === 0">
                                            <td colspan="2" class="no-record">
                                                No pools found
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-6" *ngIf="currentTab === 'repayments'">
                    <div class="drawdowns-container">
                        <div class="drawdowns">
                            <table class="web">
                                <thead>
                                    <tr>
                                        <th>Drawdown Amount</th>
                                        <th>Outstanding Amount</th>
                                        <th>Next Repayment Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of drawdowns">
                                        <td>
                                            <div class="drawdown-value">
                                                {{ item.amount | number : "0.0-2" }}
                                                {{ item.currency | uppercase }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="drawdown-value">
                                                {{ item.outstanding_amount | number : "0.0-2" }}
                                                {{ item.currency | uppercase }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="drawdown-value">
                                                {{ item.next_repayment_timestamp | date : "medium" }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="drawdown-value">
                                                <cf-button label="Repay" type="solid"
                                                    (clicked)="redirectToBorrowPoolDetails('repay', item.drawdown_id)"></cf-button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="drawdowns.length === 0">
                                        <td colspan="4" class="no-record">
                                            No records found
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot></tfoot>
                            </table>

                            <div class="mobile">
                                <table>
                                    <tbody>
                                        <tr *ngFor="let item of drawdowns">
                                            <table>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Drawdown Amount</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="drawdown-value">
                                                            {{ item.amount | number : "0.0-2" }}
                                                            {{ item.currency | uppercase }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Outstanding Amount</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="drawdown-value">
                                                            {{ item.outstanding_amount | number : "0.0-2" }}
                                                            {{ item.currency | uppercase }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Next Repayment Date</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="drawdown-value">
                                                            {{ item.next_repayment_timestamp | date : "medium" }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="table-heading">
                                                        <span>Action</span>
                                                    </td>
                                                    <td class="table-value">
                                                        <div class="drawdown-value">
                                                            <cf-button label="Repay" type="solid"
                                                                (clicked)="redirectToBorrowPoolDetails('repay', item.drawdown_id)"></cf-button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                        <tr *ngIf="drawdowns.length === 0">
                                            <td colspan="4" class="no-record">
                                                No records found
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>


    <div class="section-4">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="transactions-container">
                <span class="card-heading">Recent Transactions</span>

                <div class="transactions">
                    <table class="web">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Pool</th>
                                <th>Amount</th>
                                <th>Tx Hash</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of recentTransactions">
                                <td [ngClass]="{
                                'green-text': item.type === 'Repayment',
                                'red-text': item.type !== 'Repayment'
                            }">
                                    {{ item.type }}
                                </td>
                                <td>{{ item.created | date : "mediumDate" }}</td>
                                <td>
                                    <div class="pool-name-container" *ngIf="item?.pool_id" (click)="redirectToPool(item.category_id,
                                    item.pool_id)">
                                        <img [src]="item.pool_image_url" [alt]="item.pool_image_url"
                                            class="pool-logo" />
                                        <span class="pool-name">{{
                                            item.pool_name
                                            }}</span>
                                    </div>
                                </td>
                                <td>
                                    {{
                                    item.amount
                                    | number
                                    : httpService.getPrecision(
                                    item.currency
                                    )
                                    }}
                                    {{ item.currency | uppercase }}
                                </td>
                                <td>
                                    <div class="redirection">
                                        <span class="ellipses">{{ item.blockchain_txid }}
                                        </span>
                                        <i class="fa-solid fa-up-right-from-square ml icon" (click)="
                                            httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)
                                        "></i>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="recentTransactions.length === 0">
                                <td colspan="5" class="no-record">
                                    No recent transactions
                                </td>
                            </tr>
                        </tbody>
                        <tfoot></tfoot>
                    </table>

                    <div class="mobile">
                        <table>
                            <tbody>
                                <tr *ngFor="let item of recentTransactions">
                                    <table>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Type</span>
                                            </td>
                                            <td class="table-value">
                                                <div [ngClass]="{
                                                'green-text': item.type === 'Repayment',
                                                'red-text': item.type !== 'Repayment'
                                            }">
                                                    {{ item.type }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Date</span>
                                            </td>
                                            <td class="table-value">
                                                {{
                                                item.created
                                                | date : "mediumDate"
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Pool</span>
                                            </td>
                                            <td class="table-value">
                                                <div class="pool-name-container" *ngIf="item?.pool_id" (click)="
                                                    redirectToPool(item.category_id, item.pool_id)
                                                ">
                                                    <img [src]="item.pool_image_url" [alt]="item.pool_image_url"
                                                        class="pool-logo" />
                                                    <span class="pool-name">{{
                                                        item.pool_name
                                                        }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Amount</span>
                                            </td>
                                            <td class="table-value">
                                                {{
                                                item.amount
                                                | number
                                                : httpService.getPrecision(
                                                item.currency
                                                )
                                                }}
                                                {{ item.currency | uppercase }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-heading">
                                                <span>Tx Hash</span>
                                            </td>
                                            <td class="table-value">
                                                <div class="redirection">
                                                    <span class="ellipses">{{ item.blockchain_txid }}
                                                    </span>
                                                    <i class="fa-solid fa-up-right-from-square ml icon" (click)="
                                                        httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)
                                                    "></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </tr>
                                <tr *ngIf="recentTransactions.length === 0">
                                    <td colspan="2" class="no-record">
                                        No recent transactions
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>

    <div class="section-3">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="activites-container">
                <span class="card-heading">Recent Activity</span>
                <div class="activities">
                    <div class="activity-container" *ngFor="let activity of activites">
                        <div class="activity-description">
                            {{ activity.description }}
                        </div>
                        <div class="activity-time">
                            {{ activity.created | date : "medium" }}
                        </div>
                    </div>
                    <div class="activity-container" *ngIf="activites.length === 0">
                        <div class="no-record">No recent activites</div>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>
</div>