<div class="app-my-borrowings" [ngClass]="httpService.theme">
    <table [cellPadding]="3">
        <thead>
            <tr class="table-header">
                <th>Collateral Amount</th>
                <th>Loan Amount</th>
                <th>Next Payment Due</th>
                <th>Payment method</th>
                <!-- <th>Loan status</th> -->
                <th>Tenure</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let drawdown of drawdowns">
                <td>
                    <span>{{ drawdown.collateral_amount |
                        number:httpService.getPrecision(drawdown.collateral_currency)
                        }} {{ drawdown.collateral_currency | uppercase }}
                    </span>
                </td>
                <td>
                    <span>
                        {{ drawdown.received_amount | number:httpService.getPrecision(drawdown.received_currency)
                        }} {{ drawdown.received_currency | uppercase }}
                    </span>
                </td>
                <td>{{ currentDate | date : "mediumDate" }}</td>
                <td>
                    <span>NEFT
                    </span>
                </td>
                <!-- <td>
                    <span>{{ drawdown.status | titlecase}}
                    </span>
                </td> -->
                <td>
                    <span>{{ drawdown.tenure }} Days
                    </span>
                </td>
                <td class="button-container">
                    <div class="button">
                        <cf-button *ngIf="!drawdown.closed" [theme]="httpService.theme" label="Repay"
                            (clicked)="redirect(drawdown)">
                        </cf-button>
                        <div *ngIf="drawdown.closed" class="button-view text-center">
                            <span class="label">Repaid
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
        (changed)="pageChanged($event)"></cf-paginator>
</div>