<div class="app-new-payfi-drawdown dark">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>

    <div *ngIf="!loading">
        <div class="sidebar-and-steps">
            <div class="steps-web">
                <div *ngIf="steps[currentStep]?.step === 'payout'" class="step-content">
                    <div class="field">
                        <cf-dropdown [theme]="httpService.theme" [options]="received_currency_dropdown_options"
                            label="Payout currency" (change)="receiveCurrencyChanged($event)"
                            [selected]="received_currency" [showLogo]="true">
                        </cf-dropdown>
                    </div>

                    <div class="field">
                        <cf-input-currency [theme]="httpService.theme" [value]="received_amount" label="You borrow"
                            type="number" [currency]="received_currency"
                            [currencyLogo]="httpService.getCurrencyUrl(received_currency)"
                            (valueChanged)="loanAmountChanged($event)" [precision]="2">
                        </cf-input-currency>
                        <span class="equivalent-amount mt-1">Min: {{ min_payout_amount |
                            number:received_currency_precision}} {{ received_currency | uppercase }} </span>
                    </div>

                    <div *ngIf="
                                    httpService.user.user_id > 0 &&
                                    httpService.user?.kyc_status !== 1">
                        <cf-button [theme]="httpService.theme" [label]="'Start KYC'" [loading]="loading"
                            (clicked)="generateKycUrl()"></cf-button>
                    </div>
                    <div class="actions" *ngIf="
                                    httpService.user.user_id > 0 &&
                                    (!this.selected_pool?.kyc_required || httpService.user?.kyc_status === 1)
                                ">
                        <cf-button
                            *ngIf="!(walletService.current_provider === '' || walletService.current_provider === 'email')"
                            [theme]="httpService.theme" label="Next" type="solid"
                            (clicked)="next1Completed()"></cf-button>
                        <cf-button
                            *ngIf="(walletService.current_provider === '' || walletService.current_provider === 'email')"
                            [theme]="httpService.theme" label="Connect Wallet" type="solid"
                            (clicked)="connectWallet()"></cf-button>
                    </div>
                </div>
                <div *ngIf="steps[currentStep]?.step === 'tenure'" class="step-content">
                    <div class="field">
                        <cf-dropdown [theme]="httpService.theme" [options]="tenure_dropdown_options" label="Tenure"
                            [selected]="tenure" (change)="tenureChanged($event)" [showLogo]="false">
                        </cf-dropdown>
                    </div>

                    <div class="actions">
                        <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                            (clicked)="previous2Completed()"></cf-button>

                        <cf-button [theme]="httpService.theme" label="Next" type="solid"
                            (clicked)="next2Completed()"></cf-button>
                    </div>
                </div>
                <div *ngIf="steps[currentStep]?.step === 'payment-method'" class="step-content">
                    <div class="field">
                        <cf-dropdown [theme]="httpService.theme" [options]="payment_method_options"
                            label="Payment methods" (change)="changePaymentMethod($event)"
                            [selected]="selected_payment_method_id" [showLogo]="false">
                        </cf-dropdown>
                    </div>

                    <div class="field">
                        <cf-dropdown [theme]="httpService.theme" [options]="bank_options" label="Beneficiary account"
                            (change)="changeBank($event)" [selected]="selected_payout_beneficiary_id"
                            [showLogo]="false">
                        </cf-dropdown>
                    </div>


                    <div
                        *ngIf="selected_payout_method.input_fields.length > 0 && selected_payout_beneficiary_id === 'new'">
                        <div class="field" *ngFor="let item of selected_payout_method.input_fields">
                            <div class="field" *ngIf="item.type==='radio_button'">
                                <cf-checkbox-large [options]="item.options" label="Account type"
                                    (changed)="checkboxChanged($event, item.key)"
                                    [selected]="item.value"></cf-checkbox-large>
                            </div>
                            <div class="field" *ngIf="item.type==='input_text'">
                                <cf-input [theme]="httpService.theme" [label]="item.label" [value]="item.value"
                                    (valueChanged)="textInputChanged($event, item.key)"
                                    [placeholder]="item.placeholder"></cf-input>
                            </div>
                        </div>
                    </div>

                    <div class="actions">
                        <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                            (clicked)="previous3Completed()"></cf-button>

                        <cf-button [theme]="httpService.theme" label="Next" type="solid" (clicked)="next3Completed()"
                            [loading]="add_payout_beneficiary_loading"></cf-button>
                    </div>
                </div>
                <div *ngIf="steps[currentStep]?.step === 'collateral'" class="step-content">
                    <div class="field">
                        <cf-dropdown [theme]="httpService.theme" [options]="collateral_options"
                            label="Collateral currency" (change)="changeCollateralCurrency($event)"
                            [selected]="collateral_currency" [showLogo]="true">
                        </cf-dropdown>
                    </div>

                    <div class="actions">
                        <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                            (clicked)="previous4Completed()"></cf-button>

                        <cf-button [theme]="httpService.theme" label="Next" type="solid"
                            (clicked)="next4Completed()"></cf-button>
                    </div>
                </div>
                <div *ngIf="steps[currentStep]?.step === 'summary'" class="step-content">
                    <div class="new-payfi-drawdown-summary">
                        <div class="progress-bar-holder">
                            <div class="progress-bar" [style.width.%]="timer / initial_timer * 100"></div>
                        </div>

                        <div class="summary-content">
                            <div class="summary-item">
                                <span class="summary-heading">LTV</span>
                                <span class="summary-value">{{ selected_pool.approved_ltv_percentage }}%</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Fees ({{ selected_pool.drawdown_fee_percentage
                                    }}%)</span>
                                <span class="summary-value">{{ fee | number:received_currency_precision }} {{
                                    received_currency | uppercase }}</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Conversion</span>
                                <span class="summary-value">1 {{ collateral_currency | uppercase }} = {{
                                    conversion_rate
                                    | number :
                                    received_currency_precision }}
                                    {{ received_currency | uppercase }}</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Repayment Frequency</span>
                                <span class="summary-value">{{ selected_pool.repayment_frequency_days }}
                                    days</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Borrow APY</span>
                                <span class="summary-value">{{ selected_pool.drawdown_yield_rate }}%</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">
                                    7 Days Repayment amount
                                    <i class="fa-solid fa-circle-info"
                                        [matTooltip]="'Repayment amount to be paid for every ' + selected_pool.repayment_frequency_days + ' days'"></i>
                                </span>
                                <span class="summary-value" style="color: #cf0;">{{ repayment_amount | number :
                                    received_currency_precision }} {{
                                    received_currency |
                                    uppercase }}</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">
                                    Total interest
                                    <i class="fa-solid fa-circle-info"
                                        [matTooltip]="'Total interest amount for ' + tenure + ' days'"></i>
                                </span>
                                <span class="summary-value">{{ interest | number : received_currency_precision }}
                                    {{ received_currency |
                                    uppercase }}</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">
                                    Total Tenure
                                </span>
                                <span class="summary-value">
                                    {{tenure }} days</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Existing Collateral</span>
                                <span class="summary-value">$ {{ userCollateralInfo?.collateral_usd | number:'0.0-2'
                                    }}</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Existing Drawdown</span>
                                <span class="summary-value">$ {{ userCollateralInfo?.dropdown_usd | number:'0.0-2'
                                    }}</span>
                            </div>
                            <div class="summary-item">
                                <span class="summary-heading">Total collateral required</span>
                                <span class="summary-value" style="color: #cf0;" *ngIf="additional_collateral > 0">
                                    $ {{ additional_collateral | number:'0.0-2' }}
                                </span>
                                <span class="summary-value" style="color: #cf0;" *ngIf="additional_collateral <= 0">
                                    Not required
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="actions">
                        <cf-button [theme]="httpService.theme" label="Previous" type="outline"
                            (clicked)="previous5Completed()"></cf-button>

                        <div *ngIf="!new_drawdown_loading">
                            <cf-button [label]="confirm_btn_label" type="solid"
                                [disabled]="httpService.user.user_id === 0" (clicked)="createDrawdown()"></cf-button>
                            <span class="equivalent-amount mt-2">
                                Available:
                                {{ balance | number : collateral_currency_precision }}
                                {{ collateral_currency | uppercase }}
                            </span>
                        </div>
                        <div *ngIf="new_drawdown_loading">
                            <cf-loader></cf-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>