<div class="app-login">
    <div class="temp">
        <div class="left-section">
            <div class="logo" [routerLink]="'/'">
                <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                    title="Credible Finance" loading="lazy" routerLink="/" />
            </div>
        </div>
        <div class="center-section">
            <div class="row gx-0">
                <div class="col-md-12">
                    <span class="heading">Login</span>
                </div>
            </div>
            <div class="row gx-0">
                <div class="col-md-12">
                    <span class="sub-heading">Are you a new user ? <a [href]="register_link">Register</a></span>
                </div>
            </div>

            <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
                (changed)="changeTab($event)"></cf-tabs>

            <div class="tabs">
                <app-login-with-email *ngIf="currentTab === 'login-email'"></app-login-with-email>
                <app-login-with-wallet *ngIf="currentTab === 'login-wallet'"></app-login-with-wallet>
            </div>
        </div>
    </div>
</div>