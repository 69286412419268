import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import IInvestment from '../../interfaces/IInvestment';
import { Transaction} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { nullChecker } from '../../helpers/nullChecker';
import { MetamaskService } from '../metamask.service';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import { KycService } from '../../services/kyc.service';
import { getWindow } from 'ssr-window';

import getSolanaConnection from '../../helpers/getSolanaConnection';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';

@Component({
    selector: 'app-new-earning',
    templateUrl: './new-earning.component.html',
    styleUrls: [ './new-earning.component.scss' ]
})
export class NewEarningComponent implements OnInit, OnChanges {
    currencyDropdownOptions: Array<IDropdown> = [];
    amount: number = 0;
    amount_usd: number = 0;
    conversion_rate: number = 0;
    currency: string = 'usdc';

    investmentDropdownOptions: Array<IDropdown> = [];
    investments: Array<IInvestment> = [];

    lending_id: string = '';
    interest_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    lender_fee_percentage: number = 0;
    fee: number = 0;
    received_amount: number = 0;
    investment_info: any = {};

    cred_points: number = 0;

    show_no_investments: boolean = false;

    vault_dropdown_options: Array<IDropdown> = [];
    selected_vault: any;
    selected_vault_id: string = '';

    @Input() pool_id: string = '';

    kyc_required: boolean = false;
    pool: IPrivateCreditPool = new PrivateCreditPool();
    window: Window = getWindow();

    selectedWithdrawItem: any;

    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;
    
    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private metamaskService: MetamaskService,
        public walletService: WalletService,
        private router: Router,
        private kycService: KycService,
        private route: ActivatedRoute,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>,
        public blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public networkDialogRef: MatDialogRef<ChangeChainDialogComponent>,
        public dialog: MatDialog,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        console.log('new-earning.component.ts ngOnInit()');
        const data = this.route.snapshot.queryParamMap.get('withdrawItem');

        if (data) {
            const parsedData = JSON.parse(data);

            this.selectedWithdrawItem = parsedData;
            this.pool_id = parsedData.pool_id;
        }

        this.getInvestments();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pool_id'] && changes['pool_id'].currentValue) {
            this.pool_id = changes['pool_id'].currentValue;

            this.getPoolDetails();
        }
    }

    getPoolDetails() {
        this.loading = true;

        this.httpService.getPoolDetails(this.pool_id).subscribe(
            (res) => {
                this.pool = res.data;

                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                this.loading = false;

                console.error(err);

                this.cfAlertService.showError(err);
            }
        );
    }

    getInvestments() {
        this.showLoader();

        this.investmentDropdownOptions = [];

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            pool_id: this.pool_id,
            usage: 'create_withdrawal',
            status: 'completed'
        };

        this.httpService.getInvestments(body).subscribe(
            (res) => {
                this.investments = res.data;

                if (this.investments.length > 0) {
                    this.investments.forEach((element: any) => {
                        this.investmentDropdownOptions.push({
                            label: `${element.amount.toFixed(
                                2
                            )} ${element.currency.toUpperCase()} (${new Date(
                                element.created
                            ).toDateString()})`,
                            value: element.lending_id,
                            optionEnabled: true
                        });
                    });

                    const itemIndex = this.selectedWithdrawItem ? res.data.findIndex(
                        (item: any) =>
                            item.lending_id === this.selectedWithdrawItem.lending_id
                    ) : 0;

                    // If no item is found, set index to 0
                    const finalIndex = itemIndex === -1 ? 0 : itemIndex;

                    this.lending_id = res.data[finalIndex].lending_id;
                    this.investment_info = res.data[finalIndex];

                    this.calculateAmount();

                    this.currencyDropdownOptions.push({
                        label:
                            res.data[finalIndex].currency === 'usdt' ? 'Tether USD' : 'Circle USD',
                        value: res.data[finalIndex].currency,
                        logo: this.httpService.getCurrencyUrl(
                            res.data[finalIndex].currency
                        )
                    });
                }
                else 
                    this.show_no_investments = true;

                this.hideLoader();
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        );
    }

    showLoader() {
        this.show_no_investments = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    amountChanged(amount: number) {
        if (!amount) amount = 0;

        this.amount = parseFloat(amount.toString());
        this.fee = amount * (this.lender_fee_percentage / 100);
        this.received_amount = this.amount - this.fee;

        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;
        this.amount_usd = this.amount * this.conversion_rate;
    }

    changeInvestment(event: any) {
        this.lending_id = event.value;
        this.investment_info = this.investments.find((item: any) => {
            return item.lending_id === this.lending_id;
        });

        this.calculateAmount();
    }

    calculateAmount() {
        this.currency = this.investment_info.currency.toUpperCase();

        let pending_interest = this.calculatePendingInterest(
            this.investment_info.interest_claimed_time,
            this.investment_info.outstanding_amount,
            this.investment_info.interest_rate
        );

        console.log('pending_interest initial : ', pending_interest);

        if (pending_interest < Math.pow(10, -4)) pending_interest = 0;

        console.log('pending_interest final : ', pending_interest);

        this.cred_points = parseFloat(
            this.calculatePendingInterest(
                this.investment_info.interest_claimed_time,
                this.investment_info.outstanding_amount,
                this.investment_info.cred_percentage
            ).toFixed(6)
        );

        console.log('calculateAmount : cred_points => ', this.cred_points);

        this.amountChanged(pending_interest);
    }

    calculatePendingInterest(
        interest_claimed_time: number,
        outstanding_principal_amount: number,
        interest_rate: number
    ) {
        const current_time = new Date().getTime();
        const difference_in_minutes = (current_time - interest_claimed_time) / 60000;

        console.log(
            'calculatePendingInterest : difference_in_minutes => ',
            difference_in_minutes
        );

        const interest_per_min = (outstanding_principal_amount * interest_rate) / 100 / 365 / 24 / 60;

        console.log(
            'calculatePendingInterest : interest_per_min => ',
            interest_per_min
        );

        const interest = interest_per_min * difference_in_minutes;

        console.log('calculatePendingInterest : interest => ', interest);

        return interest;
    }

    claimInterest() {
        const body = {
            lending_id: this.lending_id,
            wallet_address: this.walletService.wallet_address,
            vault_id: ''
        };

        if (!nullChecker(this.selected_vault_id))
            body.vault_id = this.selected_vault_id;

        this.btn_loading = true;

        this.httpService.claimInterest(body).subscribe(
            (res: any) => {
                this.btn_loading = false;
                this.interest_id = res.data.interest_id;

                if ('transaction' in res.data) {
                    const transaction = Transaction.from(
                        Buffer.from(res.data.transaction, 'base64')
                    );

                    this.sendSolanaTransaction(transaction);
                }
                else if (res.data.abi) {
                    const abi = res.data.abi;
                    const gas = res.data.gas;
                    const contract_address = res.data.contract_address;

                    console.log('interest', abi, contract_address, gas);

                    this.sendEthereumTransaction(abi, contract_address, gas);
                }
            },
            (err: HttpErrorResponse) => {
                console.error('claimInterest error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    updateInterestSignature(signature: string, error_code: number) {
        const body = {
            interest_id: this.interest_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateInterestWithdrawal(body).subscribe(
            (res: any) => {
                this.btn_loading = false;

                this.cfAlertService.showMessage('Earnings claimed');

                this.router.navigateByUrl('/transactions?tab=my-earnings');
            },
            (err: HttpErrorResponse) => {
                console.error('updateInterestWithdrawal error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(
                transaction
            );

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    async sendSolanaTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature) return;

            transaction = res;

            const connection = getSolanaConnection();

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(
                transaction.serialize({
                    requireAllSignatures: true,
                    verifySignatures: true
                })
            );

            console.log(
                'sendSolanaTransaction : txn signature => ',
                txnSignature
            );
        }
        catch (err: any) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split(
                'custom program error:'
            );

            if (split_items.length > 0) {
                error_code = parseInt(
                    split_items[split_items.length - 1].trim(),
                    16
                );
            }

            console.log('sendSolanaTransaction : error_code => ', error_code);
        }
        finally {
            this.updateInterestSignature(txnSignature, error_code);
        }
    }

    async getEthereumSignature(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(
                abi,
                contract_address,
                gas
            );

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async sendEthereumTransaction(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(
                abi,
                contract_address,
                gas
            );

            console.log(
                'sendEthereumTransaction : txn signature => ',
                txnSignature
            );
        }
        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
        finally {
            this.updateInterestSignature(txnSignature, error_code);
        }
    }

    login() {
        this.httpService.redirectUrl(this.constructor.name, '/login', {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeVault(event: any) {
        console.log('changeVault => ', event.value);

        this.selected_vault_id = event.value;
    }

    generateKycUrl() {
        this.loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe(
            (res: any) => {
                this.loading = false;

                this.window.location.href = res.data;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    openChangeBlockchainDialog() {
        console.log('openChangeBlockchainDialog');

        this.differentBlockchain = true;
        
        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: this.investment_info.blockchain,
                current_blockchain: this.walletService.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        console.log('openChangeChainDialog', this.walletService.blockchain);

        if (this.walletService.blockchain === 'solana') {
            if (this.investment_info.chain === 'solana')
                return true;
        }
        
        const chainId = await this.metamaskService.getChainId();

        this.cfAlertService.showMessage(`Chain id: ${chainId}`, false);

        console.log('chainId', chainId);

        const chainIdDecimal = parseInt(chainId, 16);

        console.log('chainIdDecimal', chainIdDecimal);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        console.log('currentChainInfo', this.investment_info.chain, currentChainInfo.chainKey);

        if (this.investment_info.chain === currentChainInfo.chainKey)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.investment_info.chain,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result) 
                console.log('result', result);
        });

        return false;
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });

        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            this.ngZone.run(async () => {
                console.log('connectWallet afterClosed', status);

                if (this.walletService.isWalletConnected()) {
                    console.log(this.investment_info.blockchain, this.walletService.blockchain);

                    if (this.investment_info.blockchain !== this.walletService.blockchain) 
                        this.openChangeBlockchainDialog();
    
                    else
                        await this.openChangeChainDialog();
                }

                else
                    console.log('wallet not connected');
            });
        });
    }
}
