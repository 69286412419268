<div class="app-personal-profile" [ngClass]="httpService.theme">
    <div class="content" *ngIf="httpService.user.kyc_status === 0">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="section">
                <div class="d-flex gap-3 align-items-center">
                    <i class="fa-regular fa-circle-xmark"></i>
                    <div class="d-grid">
                        <span class="section-heading">Please complete your KYC</span>
                        <span class="section-sub-heading">KYC verification unlocks many additional features.</span>
                    </div>
                </div>

                <div class="controls">
                    <cf-button [theme]="httpService.theme" [label]="'Start KYC'" (clicked)="generateKycUrl()"
                        [loading]="loading"></cf-button>
                </div>
            </div>
        </cf-card>
    </div>

    <div class="content" *ngIf="httpService.user.kyc_status === 2">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="section">
                <div class="d-flex gap-3 align-items-center">
                    <i class="fa-solid fa-hourglass-half"></i>
                    <div class="d-grid">
                        <span class="section-heading">KYC Pending</span>
                        <span class="section-sub-heading">Your KYC is under review. You will be notified once it is
                            approved.</span>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>

    <div class="content" *ngIf="httpService.user.kyc_status === 1">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="section">
                <div class="d-flex gap-3 align-items-center">
                    <i class="fa-regular fa-circle-check"></i>
                    <div class="d-grid">
                        <span class="section-heading">KYC Approved</span>
                        <span class="section-sub-heading">Your KYC has been approved.</span>
                    </div>
                </div>
            </div>
        </cf-card>
    </div>
    <!-- <div class="content mt-4">
        <cf-card [theme]="httpService.theme" type="empty">
            <div class="section">
                <span class="section-heading mb-2">Personal Information</span>

                <div class="fields">
                    <div class="field">
                        <cf-input [theme]="httpService.theme" [label]="'First Name'" type="text"
                            [value]="httpService.user.first_name !== '' ? httpService.user.first_name : 'First Name'"
                            [readonly]="true"></cf-input>
                    </div>
                    <div class="field">
                        <cf-input [theme]="httpService.theme" [label]="'Last Name'"
                            [value]="httpService.user.last_name !== '' ? httpService.user.last_name : 'Last Name'"
                            type="text" [readonly]="true"></cf-input>
                    </div>
                    <div class="field">
                        <cf-input [theme]="httpService.theme" [label]="'Email'" [value]="httpService.user.email"
                            type="text" [value]="httpService.user.email !== '' ? httpService.user.email : 'Email'"
                            [readonly]="true"></cf-input>
                    </div>
                    <div class="field">
                        <cf-input [theme]="httpService.theme" [label]="'Invite Link'" [value]="invitation_link"
                            [copy]="true" type="text" [readonly]="true"></cf-input>
                    </div>
                    <div class="field">
                        <cf-dropdown [options]="nationalityOptions" [label]="'Nationality Country'" [showLogo]="true"
                            [theme]="httpService.theme" [disabled]="true" [showDescription]="true">
                        </cf-dropdown>
                    </div>
                    <div class="field">
                        <cf-dropdown [options]="residenceOptions" [label]="'Residence Country'" [showLogo]="true"
                            [theme]="httpService.theme" [disabled]="true" [showDescription]="true">
                        </cf-dropdown>
                    </div>
                </div>
            </div>
        </cf-card>
    </div> -->
</div>