import ETHEREUM_CHAINS from '../constants/EthereumChains';

function getRedirectionAddressUrl(blockchain: string, chain: string, network: string, address: string): string {
    if (blockchain === 'solana') {
        if (network === 'devnet')
            return `https://explorer.solana.com/address/${address}?cluster=devnet`;

        else
            return `https://explorer.solana.com/address/${address}`;
    }

    if (blockchain === 'ethereum') {
        if (chain === 'plume') {
            if (network === 'devnet')
                return `${ETHEREUM_CHAINS['PLUMEDEVNET'].blockExplorerUrl}/address/${address}`;

            else
                return `${ETHEREUM_CHAINS['PLUMEMAINNET'].blockExplorerUrl}/address/${address}`;
        }
        
        else if (chain === 'base') {
            if (network === 'testnet')
                return `${ETHEREUM_CHAINS['BASETESTNET'].blockExplorerUrl}/address/${address}`;

            else
                return `${ETHEREUM_CHAINS['BASETESTNET'].blockExplorerUrl}/address/${address}`;
        }
    }

    return '';
}

export default getRedirectionAddressUrl;