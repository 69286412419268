<div class="app-node-purchase dark" [ngClass]="httpService.theme">
    <div class="progress-bar-container">
        <div class="header-label">
            <div>
                <div class="label">
                    FDV
                </div>
                <div class="title">
                    $82M
                </div>
            </div>
            <div>
                <div class="label">
                    Projected APY
                </div>
                <div class="title">
                    15%
                </div>
            </div>
        </div>
        <div class="progress-container">
            <div class="progress-bar"></div>
        </div>
        <div class="progress-label">
            <span class="current-value">30</span>
            <span class="total-nodes">/1000 Nodes</span>
        </div>
    </div>
    <hr class="horizontal-line">
    <div class="quantity-container">
        <div class="label">Quantity</div>
        <div class="container">
            <button class="button minus">-</button>
            <span class="value">0</span>
            <button class="button plus">+</button>
        </div>
        <div class="network label">Network: Solana</div>
    </div>
    <cf-dropdown [theme]="httpService.theme" [options]="paymentOption" (change)="changePayment($event)"
        label="Select payment method" [showLogo]="false" [selected]="payment_id" [showDescription]="true">
    </cf-dropdown>

    <div class="node-summary">
        <div class="summary-item">
            <span class="summary-heading">Total amount</span>
            <span class="summary-value">0 SOL</span>
        </div>
        <div class="summary-item moon-container">
            <span class="summary-heading">Quantity</span>
            <span class="summary-value">0</span>
        </div>
        <div class="summary-item moon-container">
            <div class="moon-section">
                <div class="summary-heading">
                    Use Moon Balance for Discounts
                </div>
                <div class="summary-heading">Available Moons: 500</div>
            </div>
            <span class="summary-value discount-text">Apply Discount</span>
        </div>
    </div>

    <div class="promocode-container">
        <div class="title">
            Use Promo code
        </div>
    </div>

    <div class="terms">
        <cf-checkbox [checked]="termsAndConditions" (changed)="agreeTerms()" label=""
            [theme]="httpService.theme"></cf-checkbox>
        <span class="terms-title">
            I agree to the <a href="/terms-condition" target="_blank">terms and conditions</a>
        </span>
    </div>

    <cf-button [disabled]="true" label="Confirm Purchase" type="solid" (clicked)="confirmPurchase()"></cf-button>

</div>