<div class="app-login-with-email">
    <cf-card type="empty" [theme]="httpService.theme">
        <div *ngIf="email_step">
            <div class="fields">
                <div class="field">
                    <cf-input [value]="email" label="Email" (valueChanged)="changeEmail($event)"></cf-input>
                </div>
            </div>
            <div class="controls">
                <cf-button (clicked)="loginWithEmail()" label="Login" [loading]="loading"></cf-button>
            </div>
        </div>

        <div *ngIf="otp_step">
            <div class="fields">
                <div class="field">
                    <cf-input [value]="otp" label="Verification code" (valueChanged)="changeOtp($event)"></cf-input>
                    <div class="d-flex justify-content-end">
                        <cf-button (clicked)="resendVerificationCode()" label="Resend code" type="text"
                            [loading]="resend_otp_loading"></cf-button>
                    </div>
                </div>
            </div>
            <div class="controls">
                <cf-button (clicked)="verifyLoginEmailOtp()" label="Verify code" [loading]="loading"></cf-button>
            </div>
        </div>
        
        <div class="sign-with-google" id="google-button"></div>
    </cf-card>
</div>