<div class="app-lend-pool-details-default" [ngClass]="httpService.theme">
    <cf-card type="empty" [theme]="httpService.theme">
        <div class="grid-container">
            <div class="grid-item">
                <div class="d-flex justify-content-between">
                    <div class="start-section">
                        <img class="me-3 img-wd" src="https://assets.credible.finance/Favicon_Black.png" />
                        <div class="multi-line-text-start">
                            <div class="heading">Node</div>
                            <div class="sub-heading">
                                Credible’s CreditAI Nodes
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-item">
                <div class="d-flex justify-content-between">
                    <div class="start-section">
                        <img class="me-3 img-wd" src="https://assets.credible.finance/Favicon_Black.png" />
                        <div class="multi-line-text-start">
                            <div class="heading">Pool Network</div>
                            <div class="sub-heading">
                                Solana
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-item">
                <div class="d-flex justify-content-between">
                    <div class="start-section">
                        <div class="multi-line-text-start">
                            <div class="heading">
                                <div>Total Nodes Available</div>
                                <img [matTooltip]="total_apr_tooltip_text" class="tooltip-img"
                                    src="../../assets/images/tooltip.png" />
                            </div>
                            <div class="sub-heading">
                                {{ 0 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-item">
                <div class="d-flex justify-content-between">
                    <div class="start-section">
                        <div class="multi-line-text-start">
                            <div class="heading">
                                <div>Nodes for Sale</div>
                                <img [matTooltip]="total_apr_tooltip_text" class="tooltip-img"
                                    src="../../assets/images/tooltip.png" />
                            </div>
                            <div class="sub-heading">
                                {{ 0 | number:'0.0-0' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-item">
                <div class="d-flex justify-content-between">
                    <div class="start-section">
                        <div class="multi-line-text-start">
                            <div class="heading">Pricing</div>
                            <div class="sub-heading">
                                <span>Starting with $100 (Tier 1)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </cf-card>

    <div class="pool-description">
        The Transactional Capital Financing SPV focuses on short-term fintech assets. Damia Capital utilizes digital
        tools to monitor transaction flows and collateral efficiently. This SPV is designed for investors seeking
        attractive risk-return investment opportunities.
    </div>

    <div class="cards">
        <cf-card type="empty" [theme]="httpService.theme">
            <div class="card-content">
                <span class="card-title">💰 Earn Rewards</span>
                <span class="card-value">Earn lifetime rewards in $CRED tokens as long as Credible is active. Rewards
                    are distributed based on platform fees.</span>
            </div>
        </cf-card>
        <cf-card type="empty" [theme]="httpService.theme">
            <div class="card-content">
                <span class="card-title">🤝 Referral Bonuses</span>
                <span class="card-value">Earn up to 30% referral bonuses when new users purchase node licenses through
                    your referral.</span>
            </div>
        </cf-card>
        <cf-card type="empty" [theme]="httpService.theme">
            <div class="card-content">
                <span class="card-title">🖼️ NFT Licenses</span>
                <span class="card-value">Each node license is issued as a non-transferable NFT, ensuring transparency
                    and security.</span>
            </div>
        </cf-card>
        <cf-card type="empty" [theme]="httpService.theme">
            <div class="card-content">
                <span class="card-title">🛠️ Commission</span>
                <span class="card-value">Delegate your node operation to trusted partners and earn up to 10% commission
                    on $CRED rewards while they manage the operations.</span>
            </div>
        </cf-card>
    </div>

    <div class="web-view">
        <ul class="mt-3">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Asset : </span>
                        <img src="https://assets.credible.finance/currencies/usdc.png" class="pool-asset-icon" />
                        <span class="sub-heading">{{
                            0
                            }}</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Size : </span>
                        <span class="sub-heading">{{
                            0 | number : "0.0-0"
                            }}
                            USD</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Token : </span>
                        <span class="sub-heading ellipses">{{
                            0
                            }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon"></i>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Address : </span>
                        <span class="sub-heading">{{ 0 }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon"></i>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">USDC APR : </span>
                        <span class="sub-heading">{{
                            0
                            }}%</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Type : </span>
                        <span class="sub-heading">Indicative</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Agency Equivalent :
                        </span>
                        <span class="sub-heading">{{
                            0
                            }}</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Implied PD : </span>
                        <span class="sub-heading">{{ 0 }}%</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Repayment Frequency : </span>
                        <span class="sub-heading">{{
                            0
                            }}
                            days</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">{{0}} APR : </span>
                        <span class="sub-heading">{{
                            0
                            }}%</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>


    <div class="mobile-view">
        <ul class="mt-3">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Asset : </span>
                        <img src="https://assets.credible.finance/currencies/usdc.png" class="pool-asset-icon" />
                        <span class="sub-heading">{{
                            0
                            }}</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Size : </span>
                        <span class="sub-heading">{{
                            0 | number : "0.0-0"
                            }}
                            USD</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Token : </span>
                        <span class="sub-heading ellipses">{{
                            0
                            }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon"></i>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Pool Address : </span>
                        <span class="sub-heading">{{ 0 }}</span>
                        <i class="fa-solid fa-up-right-from-square redirection-icon"></i>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">USDC APR : </span>
                        <span class="sub-heading">{{
                            0
                            }}%</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">{{0}} APR : </span>
                        <span class="sub-heading">{{
                            0
                            }}%</span>
                    </div>
                </div>
            </li>
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Implied PD : </span>
                        <span class="sub-heading">{{ 0 }}%</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Type : </span>
                        <span class="sub-heading">Indicative</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Rating Agency Equivalent :
                        </span>
                        <span class="sub-heading">{{
                            0
                            }}</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="mt-2">
            <li class="web-view-small-card">
                <div class="start-section">
                    <div class="multi-line-text-start">
                        <span class="heading">Repayment Frequency : </span>
                        <span class="sub-heading">{{
                            0
                            }}
                            days</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="mt-3 normal-text">
        Ratings and credit assessments provided by
        <a target="_blank"> Ratings and credit assessments provided by Credora
        </a>
    </div>

    <hr />

    <div class="temp">
        <div class="left">
            <div class="graph-heading">POOL UTILISATION</div>
            <div class="row">
                <app-line-chart (showCharts)="showCharts($event)" [lineConfig]="chartLineConfig" [title]="chartTitle"
                    [currency]="'USDC'"></app-line-chart>
            </div>

            <div class="accepted-card">
                <cf-card type="empty" [theme]="httpService.theme">
                    <div>
                        <div class="content">
                            Accepted currencies:
                        </div>
                        <hr class="horizontal-line">
                        <div class="content">
                            Network
                        </div>
                    </div>
                </cf-card>
            </div>
        </div>
        <div class="right">
            <app-node-widget></app-node-widget>
        </div>
    </div>

    <div class="button-menu">
        <app-new-button-menu showButtonType="lableBorderButton" [pool_id]="pool_id" [defaultTabIndex]="tabIndexPool"
            [labelArray]="labelArrayPool" (buttonClick)="onTabChangePool($event)"></app-new-button-menu>
    </div>
    <app-my-nodes *ngIf="tabIndexPool === 0"></app-my-nodes>

    <div class="container-wrap">
        <div class="heading-container">
            <h1 class="title">Frequently Asked Questions</h1>
            <p class="sub-title">Do you have any questions? Here are some frequently asked questions that we
                have
                answered</p>
        </div>
        <div class="sub-container">
            <div class="faq-cards">
                <div class="faq-card" *ngFor="let faq of faqs" (click)="openFaq(faq.index)">
                    <div class="faq-question">
                        <span>{{ faq.question }}</span>
                        <i class="fa-solid fa-minus" *ngIf="faq.index === openedFaqIndex"></i>
                        <i class="fa-solid fa-plus" *ngIf="faq.index !== openedFaqIndex"></i>
                    </div>
                    <span class="faq-answer" *ngIf="faq.index === openedFaqIndex">{{ faq.answer }}</span>
                </div>
            </div>
        </div>
    </div>

</div>