<div class="app-my-investments">
    <table [cellPadding]="3">
        <thead>
            <tr class="table-header">
                <th>Investment Amount</th>
                <th>Investment Date</th>
                <th>Blockchain TX ID</th>
            </tr>
        </thead>
        <tbody *ngIf="items.length > 0">
            <tr *ngFor="let item of investments">
                <td>
                    <span>{{ item.amount | number:httpService.getPrecision(item.currency)
                        }} {{ item.currency | uppercase }}
                    </span>
                </td>
                <td>{{ item.created | date : "mediumDate" }}</td>
                <td>
                    <div class="redirection">
                        <span class="ellipses">{{ item.blockchain_txid }}
                        </span>
                        <i class="fa-solid fa-up-right-from-square ml icon"
                            (click)="httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)"></i>
                    </div>
                </td>
                <td class="button-container">
                    <cf-button *ngIf="!item.settled" [theme]="httpService.theme" label="Withdraw"
                        (clicked)="redirect(item)">
                    </cf-button>
                    <div *ngIf="item.settled" class="button-view text-center">
                        <span class="label">Withdrawn
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
        (changed)="pageChanged($event)"></cf-paginator>
</div>