<div class="app-ambassador-moons-credit" [ngClass]="httpService.theme">
    <div class="top-section">
        <div class="section-heading">
            Rewards
        </div>
        <div class="level-dropdown mb-3" matTooltip="Referral Level">
            <cf-dropdown [theme]="httpService.theme" [options]="levelOptions" [showLogo]="false" [showLabel]="false"
                (change)="levelChange($event)" [selected]="current_level"></cf-dropdown>
        </div>
    </div>

    <div class="middle-section" [ngClass]="{'loader-container': loading_stats}">
        <cf-loader *ngIf="loading_stats"></cf-loader>
        <div class="member-stats" *ngIf="!loading_stats">
            <div>
                <cf-card [theme]="httpService.theme" [stat]="stats.all" heading="All time" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" [stat]="stats.last_7d" heading="Last 7 days"
                    type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" [stat]="stats.last_30d" heading="Last 30 days"
                    type="compact"></cf-card>
            </div>
            <div>
                <cf-card [theme]="httpService.theme" [stat]="stats.last_60d" heading="Last 60 days"
                    type="compact"></cf-card>
            </div>
        </div>
    </div>

    <div class="section-heading">
        Rewards vs Date
    </div>
    <div class="bottom-section" [ngClass]="{'loader-container': loading_charts}">
        <cf-loader *ngIf="loading_charts" class="loader"></cf-loader>
        <div class="member-controls">
            <div class="member-duration-controls">
                <div class="member-control" matTooltip="12 hrs"
                    [ngClass]="{'active-control': current_chart_duration === '12h'}"
                    (click)="changeMoonCreditChartDuration('12h')">12H</div>
                <div class="member-control" matTooltip="1 day"
                    [ngClass]="{'active-control': current_chart_duration === '1d'}"
                    (click)="changeMoonCreditChartDuration('1d')">1D</div>
                <div class="member-control" matTooltip="3 day"
                    [ngClass]="{'active-control': current_chart_duration === '3d'}"
                    (click)="changeMoonCreditChartDuration('3d')">3D</div>
                <div class="member-control" matTooltip="1 week"
                    [ngClass]="{'active-control': current_chart_duration === '1w'}"
                    (click)="changeMoonCreditChartDuration('1w')">1W</div>
            </div>
            <div class="member-type-controls">
                <div class="member-control" matTooltip="cumulative"
                    [ngClass]="{'active-control': current_chart_type === 'cumulative'}"
                    (click)="changeMoonCreditChartType('cumulative')">Cu</div>
                <div class="member-control" matTooltip="Non cumulative"
                    [ngClass]="{'active-control': current_chart_type === 'non_cumulative'}"
                    (click)="changeMoonCreditChartType('non_cumulative')">NC</div>
            </div>
        </div>


        <div *ngIf="!loading_charts">
            <app-line-chart [lineConfig]="chartLineConfig" title="" [height]="350"></app-line-chart>
        </div>
    </div>
</div>