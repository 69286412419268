import {
    Component, Input, OnInit
} from '@angular/core';
import { HttpService } from '../../../services/http.service';

@Component({
    selector: 'app-node-widget',
    templateUrl: './node-widget.component.html',
    styleUrl: './node-widget.component.scss'
})
export class NodeWidgetComponent implements OnInit {
    currentTab: any = 'nodePurchase';

    @Input() pool_id: string = '';

    constructor(public httpService: HttpService) {
    }

    ngOnInit(): void {
        
    }

    changedTab(tab: any) {
        this.currentTab = tab;
    }
}
