import { Injectable } from '@angular/core';
import {
    Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { HttpService } from '../../services/http.service';

@Injectable({
    providedIn: 'root' 
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        public httpService: HttpService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.httpService.user.user_id === 0) {
            const urlTree = this.router.createUrlTree([ '/login' ], {
                queryParams: {
                    returnUrl: state.url
                }
            });

            return urlTree;
        }

        return true;
    }
}
