<div class="app-login-with-wallet">
    <div class="sidebar-and-steps">
        <div class="steps-web">
            <div class="steps-content">
                <div *ngIf="steps[currentStep]?.step === 'connect-wallet'" class="step-content">
                    <app-login-connect-wallet (connected)="notifyWalletConnected($event)"
                        purpose="login"></app-login-connect-wallet>
                </div>

                <div *ngIf="steps[currentStep]?.step === 'email'" class="step-content">
                    <div class="fields">
                        <div class="field">
                            <cf-input [value]="email" label="Email" (valueChanged)="changeEmail($event)"></cf-input>
                        </div>
                    </div>
                    <div class="controls">
                        <cf-button (clicked)="linkEmailWithWalletAddress()" label="Login"
                            [loading]="loading"></cf-button>
                    </div>
                </div>

                <div *ngIf="steps[currentStep]?.step === 'otp'" class="step-content">
                    <div class="fields">
                        <div class="field">
                            <cf-input [value]="otp" label="Verification code"
                                (valueChanged)="changeOtp($event)"></cf-input>
                        </div>
                    </div>
                    <!-- <div class="controls">
                        <cf-button (clicked)="verifyLoginEmailOtp()" label="Verify code"
                            [loading]="loading"></cf-button>
                    </div> -->
                    <div class="actions">
                        <div class="otp-content">
                            <cf-button (clicked)="verifyLoginEmailOtp()" type="solid" label="Verify code"
                                [loading]="loading"></cf-button>
                            <div class="resend-text">
                                <span (click)="resendVerificationCode()">
                                    Resend code
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>