import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import IInvestment from '../../interfaces/IInvestment';
import { Transaction} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';
import { nullChecker } from '../../helpers/nullChecker';
import { MetamaskService } from '../metamask.service';
import PrivateCreditPool from '../../models/PrivateCreditPool';
import { KycService } from '../../services/kyc.service';
import { getWindow } from 'ssr-window';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';

import getSolanaConnection from '../../helpers/getSolanaConnection';

@Component({
    selector: 'app-new-payfi-withdrawal-investment',
    templateUrl: './new-payfi-withdrawal-investment.component.html',
    styleUrl: './new-payfi-withdrawal-investment.component.scss'
})
export class NewPayfiWithdrawalInvestmentComponent implements OnInit {
    currencyDropdownOptions: Array<IDropdown> = [];
    amount: number = 0;
    amount_usd: number = 0;
    conversion_rate: number = 0;
    currency: string = 'usdc';

    investmentDropdownOptions: Array<IDropdown> = [];
    investments: Array<IInvestment> = [];

    lending_id: string = '';
    withdrawal_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    pool_info: IPrivateCreditPool = new PrivateCreditPool();
    pools: Array<IPrivateCreditPool> = [];
    poolOptions: Array<IDropdown> = [];

    lender_fee_percentage: number = 0;
    fee: number = 0;
    received_amount: number = 0;
    investment_info: any = {};

    cred_points: number = 0;

    show_no_investments: boolean = false;

    vault_dropdown_options: Array<IDropdown> = [];
    selected_vault: any;
    selected_vault_id: string = '';

    selectedWithdrawItem: any;

    kyc_required: boolean = false;
    window = getWindow();

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private metamaskService: MetamaskService,
        private walletService: WalletService,
        private router: Router,
        private kycService: KycService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        console.log('new-withdrawal.component.ts ngOnInit()');
        const data = this.route.snapshot.queryParamMap.get('withdrawItem');

        if (data) {
            const parsedData = JSON.parse(data);

            this.selectedWithdrawItem = parsedData;
        }

        this.getInvestments();
    }

    async getInvestments() {
        this.showLoader();

        this.investmentDropdownOptions = [];

        let chainKey = 'solana';

        if (this.httpService.user.blockchain === 'ethereum') {
            const chainId = await this.metamaskService.getChainId();
            const chainIdDecimal = parseInt(chainId, 16);

            const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

            chainKey = currentChainInfo.chainKey;
        }

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            blockchain: this.httpService.user.blockchain,
            chain: chainKey,
            usage: 'create_withdrawal',
            status: 'completed'
        };

        this.httpService.getInvestments(body).subscribe((res) => {
            this.investments = res.data;

            if (this.investments.length > 0) {
                this.investments.forEach((element: any) => {
                    this.investmentDropdownOptions.push({
                        label: `${element.amount.toFixed(
                            2
                        )} ${element.currency.toUpperCase()} (${new Date(
                            element.created
                        ).toDateString()})`,
                        value: element.lending_id,
                        optionEnabled: true
                    });
                });

                const itemIndex = this.selectedWithdrawItem ? this.investments.findIndex(
                    (item: any) =>
                        item.lending_id === this.selectedWithdrawItem.lending_id
                ) : 0;

                // If no item is found, set index to 0
                const finalIndex = itemIndex === -1 ? 0 : itemIndex;

                this.lending_id = this.investments[finalIndex].lending_id;
                this.investment_info = this.investments[finalIndex];

                this.calculateAmount();

                this.currencyDropdownOptions.push({
                    label: res.data[finalIndex].currency === 'usdt' ? 'Tether USD' : 'Circle USD',
                    value: res.data[finalIndex].currency,
                    logo: this.httpService.getCurrencyUrl(res.data[finalIndex].currency)
                });
            }
            else this.show_no_investments = true;

            this.hideLoader();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    showLoader() {
        this.show_no_investments = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    amountChanged(amount: number) {
        if (!amount)
            amount = 0;

        this.amount = parseFloat(amount.toString());
        this.fee = amount * (this.lender_fee_percentage / 100);
        this.received_amount = this.amount - this.fee;

        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;
        this.amount_usd = this.amount * this.conversion_rate;
    }

    changeInvestment(event: any) {
        this.lending_id = event.value;
        this.investment_info = this.investments.find((item: any) => {
            return item.lending_id === this.lending_id;
        });

        this.calculateAmount();
    }

    calculateAmount() {
        this.currency = this.investment_info.currency;

        const pool_info = this.pools.find((item: any) => {
            return item.pool_id === this.investment_info.pool_id;
        });

        if (pool_info) {
            this.pool_info = pool_info;

            this.lender_fee_percentage = pool_info.lender_fee_percentage;
        }

        this.amountChanged(this.investment_info.outstanding_amount);
    }

    principalWithdrawal() {
        if (nullChecker(this.amount)) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.investment_info.outstanding_amount < this.amount) {
            this.cfAlertService.showMessage(`Maximum amount that you can withdraw is ${this.investment_info.outstanding_amount.toFixed(2)} ${this.currency.toUpperCase()}`, true);

            return;
        }

        const body = {
            lending_id: this.lending_id,
            amount: this.amount,
            currency: this.currency.toLowerCase(),
            vault_id: ''
        };

        if (!nullChecker(this.selected_vault_id))
            body.vault_id = this.selected_vault_id;

        this.btn_loading = true;

        this.httpService.requestPrincipalWithdrawal(body).subscribe((res: any) => {
            this.btn_loading = false;
            this.withdrawal_id = res.data.withdrawal_id;

            if ('transaction' in res.data) {
                const transaction = Transaction.from(
                    Buffer.from(res.data.transaction, 'base64')
                );

                this.sendSolanaTransaction(transaction);
            }
            else if (res.data.abi) {
                const abi = res.data.abi;
                const gas = res.data.gas;
                const contract_address = res.data.contract_address;

                console.log('withdrawal', abi, contract_address, gas);

                this.sendEthereumTransaction(abi, contract_address, gas);
            }
        }, (err: HttpErrorResponse) => {
            console.error('Withdrawal error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.btn_loading = false;
        });
    }

    updatePrincipalSignature(signature: string, error_code: number) {
        const body = {
            withdrawal_id: this.withdrawal_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updatePrincipalWithdrawal(body).subscribe(
            (res: any) => {
                this.btn_loading = false;

                this.cfAlertService.showMessage('Withdrawal succesful');

                this.router.navigateByUrl('/transactions?tab=my-withdrawals');
            },
            (err: HttpErrorResponse) => {
                console.error('updatePrincipalWithdrawal error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.btn_loading = false;
            }
        );
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(
                transaction
            );

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    async sendSolanaTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;

            transaction = res;

            const connection = getSolanaConnection();

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(
                transaction.serialize({
                    requireAllSignatures: true,
                    verifySignatures: true
                })
            );

            console.log('sendTransaction : txn signature => ', txnSignature);
        }
        catch (err: any) {
            console.error('sendTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split(
                'custom program error:'
            );

            if (split_items.length > 0) {
                error_code = parseInt(
                    split_items[split_items.length - 1].trim(),
                    16
                );
            }

            console.log('sendTransaction : error_code => ', error_code);
        }
        finally {
            this.updatePrincipalSignature(txnSignature, error_code);
        }
    }

    async sendEthereumTransaction(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(
                abi,
                contract_address,
                gas
            );

            console.log(
                'sendEthereumTransaction : txn signature => ',
                txnSignature
            );
        }
        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }
        finally {
            this.updatePrincipalSignature(txnSignature, error_code);
        }
    }

    connectWallet() {
        this.router.navigate([ '/login' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeVault(event: any) {
        console.log('changeVault => ', event.value);

        this.selected_vault_id = event.value;
    }

    async getEthereumSignature(
        abi: string,
        contract_address: string,
        gas: number
    ) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(
                abi,
                contract_address,
                this.httpService.user.wallet_address,
                gas
            );

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    generateKycUrl() {
        this.loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe(
            (res: any) => {
                this.loading = false;

                this.window.location.href = res.data;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }
}
