<div class="app-new-payfi-repayment" [ngClass]="httpService.theme">
    <div *ngIf="
            !loading &&
            httpService.user.user_id > 0 &&
            !show_no_drawdowns
        ">
        <div class="field">
            <cf-dropdown [theme]="httpService.theme" [options]="drawdownsDropdownOptions"
                (change)="changeDrawdown($event)" label="Select drawdown" [showLogo]="true" [selected]="drawdown_id"
                [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="field">
            <cf-checkbox-large [options]="repayment_type_options" label="Payment method"
                (changed)="changeRepaymentTyepOption($event)" [selected]="repayment_type"></cf-checkbox-large>
        </div>

        <div class="field" *ngIf="repayment_type === 'fiat'">
            <cf-input-currency [theme]="httpService.theme" [value]="fiat_repayment_amount" label="Amount"
                [currency]="selected_fiat_currency" [currencyLogo]="httpService.getCurrencyUrl(selected_fiat_currency)"
                type="number" (valueChanged)="fiatAmountChanged($event)" [precision]="2" [readonly]="false">
            </cf-input-currency>
        </div>

        <div class="field" *ngIf="repayment_type === 'wallet'">
            <cf-input-currency [theme]="httpService.theme" [value]="crypto_repayment_amount" label="Amount"
                [currency]="selected_crypto_currency" [maxLength]="20"
                [currencyLogo]="httpService.getCurrencyUrl(selected_crypto_currency)" type="number"
                (valueChanged)="cryptoAmountChanged($event)" [precision]="4">
            </cf-input-currency>
            <span class="equivalent-amount mt-1">
                Available:
                {{ balance | number : httpService.getPrecision(selected_crypto_currency) }}
                {{ selected_crypto_currency | uppercase }}
            </span>
        </div>

        <div class="new-payfi-repayment-summary">
            <div class="summary-item">
                <span class="summary-heading">Total drawdown</span>
                <span class="summary-value">{{
                    drawdown_info.received_amount
                    | number : httpService.getPrecision(selected_fiat_currency)
                    }}
                    {{ selected_fiat_currency | uppercase }}
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Already repaid</span>
                <span class="summary-value">
                    {{ drawdown_info.principal_fiat | number : httpService.getPrecision(drawdown_info.received_currency)
                    }}
                    {{ drawdown_info.received_currency | uppercase }}
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Outstanding principal</span>
                <span class="summary-value">
                    {{ drawdown_info.outstanding_fiat | number :
                    httpService.getPrecision(drawdown_info.received_currency) }}
                    {{ drawdown_info.received_currency | uppercase }}
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Last interest paid</span>
                <span class="summary-value">{{
                    drawdown_info.last_interest_payment_time
                    | date:'medium'
                    }}
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Interest rate</span>
                <span class="summary-value">
                    {{ drawdown_info.yield_rate }}%
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Used for interest</span>
                <span class="summary-value" *ngIf="repayment_type === 'fiat'">
                    {{ used_for_interest | number : httpService.getPrecision(selected_fiat_currency) }}
                    {{ selected_fiat_currency | uppercase }}
                    <span style="color: grey;">({{ convertToCrypto(used_for_interest, selected_fiat_currency,
                        selected_crypto_currency) | number : httpService.getPrecision(selected_crypto_currency) }}
                        {{ selected_crypto_currency | uppercase }})</span>
                </span>
                <span class="summary-value" *ngIf="repayment_type === 'wallet'">
                    {{ used_for_interest | number : httpService.getPrecision(selected_crypto_currency) }}
                    {{ selected_crypto_currency | uppercase }}
                    <span style="color: grey;">({{ convertToFiat(used_for_interest, selected_crypto_currency,
                        selected_fiat_currency) | number : httpService.getPrecision(selected_fiat_currency) }}
                        {{ selected_fiat_currency | uppercase }})</span>
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Used for principal</span>
                <span class="summary-value" *ngIf="repayment_type === 'fiat'">
                    {{ used_for_principal | number : httpService.getPrecision(selected_fiat_currency) }}
                    {{ selected_fiat_currency | uppercase }}
                    <span style="color: grey;">({{ convertToCrypto(used_for_principal, selected_fiat_currency,
                        selected_crypto_currency) | number : httpService.getPrecision(selected_crypto_currency) }}
                        {{ selected_crypto_currency | uppercase }})</span>
                </span>
                <span class="summary-value" *ngIf="repayment_type === 'wallet'">
                    {{ used_for_principal | number : httpService.getPrecision(selected_crypto_currency) }}
                    {{ selected_crypto_currency | uppercase }}
                    <span style="color: grey;">({{ convertToFiat(used_for_principal, selected_crypto_currency,
                        selected_fiat_currency) | number : httpService.getPrecision(selected_fiat_currency) }}
                        {{ selected_fiat_currency | uppercase }})</span>
                </span>
            </div>
            <div class="summary-item">
                <span class="summary-heading">Total repayment</span>
                <span class="summary-value" *ngIf="repayment_type === 'fiat'">
                    {{ crypto_repayment_amount | number : httpService.getPrecision(selected_fiat_currency) }}
                    {{ selected_fiat_currency | uppercase }}
                    <span style="color: grey;">({{ convertToCrypto(crypto_repayment_amount, selected_fiat_currency,
                        selected_crypto_currency) | number : httpService.getPrecision(selected_crypto_currency) }}
                        {{ selected_crypto_currency | uppercase }})</span>
                </span>
                <span class="summary-value" *ngIf="repayment_type === 'wallet'">
                    {{ crypto_repayment_amount | number : httpService.getPrecision(selected_crypto_currency) }}
                    {{ selected_crypto_currency | uppercase }}
                    <span style="color: grey;">({{ convertToFiat(crypto_repayment_amount, selected_crypto_currency,
                        selected_fiat_currency) | number : httpService.getPrecision(selected_fiat_currency) }}
                        {{ selected_fiat_currency | uppercase }})</span>
                </span>
            </div>
        </div>
        <div class="actions" *ngIf="
                                    httpService.user.user_id > 0 &&
                                    httpService.user?.kyc_status !== 1">
            <cf-button [theme]="httpService.theme" [label]="'Start KYC'" [loading]="loading"
                (clicked)="generateKycUrl()"></cf-button>
        </div>

        <div class="actions" *ngIf="
                                            httpService.user.user_id > 0 &&
                                            httpService.user?.kyc_status === 1">>
            <div class="action">
                <div *ngIf="!btn_loading">
                    <cf-button [label]="confirm_btn_label" type="solid" [disabled]="httpService.user.user_id === 0"
                        (clicked)="createRepayment()" [loading]="btn_loading"></cf-button>
                </div>
                <div *ngIf="btn_loading">
                    <cf-loader></cf-loader>
                </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div class="actions"
        *ngIf="!loading && !show_no_drawdowns && (walletService.current_provider === '' || walletService.current_provider === 'email')">
        <cf-button [theme]="httpService.theme" label="Connect Wallet" type="solid"
            (clicked)="connectWallet()"></cf-button>
    </div>
    <div *ngIf="show_no_drawdowns" class="no_data">
        <div>No drawdowns</div>
    </div>
</div>