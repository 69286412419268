<div class="app-register-steps">
    <div class="center-section">
        <div class="row gx-0">
            <div class="col-md-12">
                <span class="heading">Register</span>
            </div>
        </div>
        <div class="row gx-0">
            <div class="col-md-12">
                <span class="sub-heading">Already a user ? <a href="/login">Login</a></span>
            </div>
        </div>

        <div class="sidebar-and-steps">
            <div class="steps-web">
                <div class="steps-content">
                    <div *ngIf="steps[currentStep]?.step === 'email-verification'" class="step-content">
                        <app-email-verification (completed)="notifyEmailVerification($event)"
                            [invite_code]="invite_code"></app-email-verification>
                    </div>

                    <div *ngIf="steps[currentStep]?.step === 'invite-code'" class="step-content">
                        <app-invite-code (completed)="notifyInviteCode($event)" [email]="email"></app-invite-code>
                    </div>

                    <div *ngIf="steps[currentStep]?.step === 'connect-wallet'" class="step-content">
                        <app-connect-wallet (connected)="notifyWalletConnected($event)" purpose="register"
                            (next)="connectWalletNext()" [email]="email"></app-connect-wallet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>