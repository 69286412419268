import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router
} from '@angular/router';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
    window = getWindow();
    currentTab = '';
    register_link = '';

    tabs: Array<TabOption> = [{
        label: 'Email',
        value: 'login-email'
    }, {
        label: 'Wallet',
        value: 'login-wallet'
    }];

    connectedWalletAddres: string = '';

    returnUrl: string = '';

    constructor(
        public httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/airdrop';

        console.log(this.constructor.name, 'returnUrl', returnUrl);
        
        this.register_link = `${this.window.location.origin}/register?returnUrl=${returnUrl}`;

        if (this.httpService.user.user_id > 0) {
            console.log('User is already logged in. Redirecting to airdrop page');

            console.log('paramMap', this.route.snapshot.paramMap);
            console.log('queryParamMap', this.route.snapshot.queryParamMap);
            console.log('url', this.route.url);

            this.httpService.redirectUrl(this.constructor.name, returnUrl);

            return;
        }

        this.currentTab = this.tabs[0].value;

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.value === tab))
                    this.currentTab = tab;
            }
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
