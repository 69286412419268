<div class="app-wallet-new-deposit">
    <div class="fields">
        <div class="page-heading">New Deposit</div>

        <div class="form-fields">
            <div class="form-field">
                <cf-dropdown [options]="currencyOptions" (change)="changeCurrency($event)" label="Select Currency"
                    [showLogo]="true" [selected]="selectedCurrency" [showDescription]="true">
                </cf-dropdown>
            </div>

            <div class="form-field">
                <cf-dropdown [options]="networkOptions" (change)="changeNetwork($event)" label="Select Network"
                    [showLogo]="true" [selected]="selectedNetwork" [showDescription]="true">
                </cf-dropdown>
            </div>
            <div class="form-field">
                <cf-input label="Address" placeholder="Address" [type]="'number'" [value]="wallet_address"
                    [readonly]="true" [copy]="true"></cf-input>
            </div>
        </div>
        <div class="qr-code">
            <cf-card type=" empty">
                <qrcode [qrdata]="wallet_address" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </cf-card>
        </div>
    </div>

    <div class="notes">
        <cf-card type="empty">
            <div class="note">
                <div class="note-heading">Minimum deposit</div>
                <div class="note-value">{{this.selectedNetworkInfo.min_withdrawal}} {{this.selectedCurrency}}</div>
            </div>

            <div class="note">
                <div class="note-heading">Deposit arrival</div>
                <div class="note-value">200 confirmations</div>
            </div>

            <div class="note">
                <div class="note-heading">Withdrawal unlock</div>
                <div class="note-value">200 confirmations</div>
            </div>

            <!-- <div class="note">
                <div class="note-heading">Contract information</div>
                <div class="note-value">{{ httpService.sliceAddress('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
                    8) }}</div>
            </div> -->
        </cf-card>
    </div>
</div>