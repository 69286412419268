'use strict';

var assert = require('minimalistic-assert');
var inherits = require('inherits');
var proto = {};
function CBCState(iv) {
  assert.equal(iv.length, 8, 'Invalid IV length');
  this.iv = new Array(8);
  for (var i = 0; i < this.iv.length; i++) this.iv[i] = iv[i];
}
function instantiate(Base) {
  function CBC(options) {
    Base.call(this, options);
    this._cbcInit();
  }
  inherits(CBC, Base);
  var keys = Object.keys(proto);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    CBC.prototype[key] = proto[key];
  }
  CBC.create = function create(options) {
    return new CBC(options);
  };
  return CBC;
}
exports.instantiate = instantiate;
proto._cbcInit = function _cbcInit() {
  var state = new CBCState(this.options.iv);
  this._cbcState = state;
};
proto._update = function _update(inp, inOff, out, outOff) {
  var state = this._cbcState;
  var superProto = this.constructor.super_.prototype;
  var iv = state.iv;
  if (this.type === 'encrypt') {
    for (var i = 0; i < this.blockSize; i++) iv[i] ^= inp[inOff + i];
    superProto._update.call(this, iv, 0, out, outOff);
    for (var i = 0; i < this.blockSize; i++) iv[i] = out[outOff + i];
  } else {
    superProto._update.call(this, inp, inOff, out, outOff);
    for (var i = 0; i < this.blockSize; i++) out[outOff + i] ^= iv[i];
    for (var i = 0; i < this.blockSize; i++) iv[i] = inp[inOff + i];
  }
};