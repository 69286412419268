/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import IInvestment from '../../interfaces/IInvestment';

@Component({
    selector: 'app-my-investments-payfi',
    templateUrl: './my-investments-payfi.component.html',
    styleUrl: './my-investments-payfi.component.scss'
})
export class MyInvestmentsPayFiComponent implements OnInit {
    items: any = [{}, {}, {}, {}, {}, {}, {}];
    currentDate: string | number | Date = new Date().getTime();
    loading: boolean = false;
    investments: Array<IInvestment> = [];
    total_count: number = 0;
    page: number = 0;
    limit: number = 10;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log('new-payfi.component.ts ngOnInit()');
        this.getInvestments();
    }
    getInvestments() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: 'completed'
        };

        this.httpService.getInvestments(body).subscribe(
            (res: any) => {
                this.investments = res.data;
                this.total_count = res.total_count;
                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getInvestments();
    }

    redirect(item: any) {
        // this.router.navigate(['/payfi/details'], {
        //     queryParams: {
        //         tab: 'my-collaterals'
        //     }
        // });

        this.router.navigate(['/cash-loan/lend'], {
            queryParams: {
                currenttab: 'withdrawal',
                withdrawItem: JSON.stringify(item)
            }
        });
    }
}
