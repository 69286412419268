<div class="app-wallet-funds">
    <cf-tabs [tabs]="tabs" [currentTab]="currentTab" (changed)="changeTab($event)"
        [theme]="httpService.theme"></cf-tabs>

    <div class="tabs">
        <app-wallet-balances *ngIf="currentTab === 'balances'" (changeTab)="changeTabFromBalances($event)">
        </app-wallet-balances>
        <app-wallet-deposits *ngIf="currentTab === 'deposit'"> </app-wallet-deposits>
        <app-wallet-withdrawals *ngIf="currentTab === 'withdraw'"> </app-wallet-withdrawals>
    </div>
</div>