<div class="app-wallet-new-withrawal">
    <div class="fields">
        <div class="page-heading">New Withdrawal</div>

        <div class="form-fields">
            <div class="form-field">
                <cf-dropdown [options]="currencyOptions" (change)="changeCurrency($event)" label="Select Currency"
                    [showLogo]="true" [selected]="selectedCurrency" [showDescription]="true">
                </cf-dropdown>
            </div>
            <div class="form-field" *ngIf="networkOptions && networkOptions?.length">
                <cf-dropdown [options]="networkOptions" (change)="changeNetwork($event)" label="Select Network"
                    [showLogo]="true" [selected]="selectedNetwork" [showDescription]="true">
                </cf-dropdown>
            </div>
            <div class="form-field">
                <cf-input label="Wallet Address" placeholder="Wallet Address" [value]="destination_address"
                    (valueChanged)="changeWalletAddress($event)" [readonly]="showVerificationCode"></cf-input>
            </div>
            <div class="form-field">
                <cf-input label="Withdrawal Amount" placeholder="Amount" [type]="'number'" [value]="amount"
                    [copy]="false" (valueChanged)="changeAmount($event)" [readonly]="showVerificationCode"></cf-input>
                <span class="equivalent-amount mt-1">
                    Available:
                    {{ balance | number : httpService.getPrecision(selectedCurrency) }}
                    {{ selectedCurrency | uppercase }}
                </span>
            </div>
            <div class="form-field">
                <cf-input label="Verification code" placeholder="Verification code" [type]="'text'"
                    [value]="withdrawal_otp" *ngIf="showVerificationCode"
                    (valueChanged)="changeVerificationCode($event)"></cf-input>
            </div>
        </div>
        <div class="controls">
            <cf-button (clicked)="getVerificationCode()" label="Get code" [disabled]="amount === ''" [loading]="loading"
                *ngIf="!showVerificationCode"></cf-button>

            <div *ngIf="showVerificationCode" class="resend-text d-flex justify-content-end"
                (click)="resendWithdrawalOtp()">
                <span>
                    Resend code
                </span>
            </div>


            <cf-button (clicked)="submit()" label="Submit" [loading]="loading" [disabled]="withdrawal_otp === ''"
                *ngIf="showVerificationCode"></cf-button>
        </div>
    </div>

    <div class="notes">
        <cf-card type="empty">
            <div class="note">
                <div class="note-heading">Minimum withrawal</div>
                <div class="note-value">{{selectedNetworkInfo?.min_withdrawal}} {{selectedCurrency | uppercase}}</div>
            </div>

            <div class="note">
                <div class="note-heading">Contract address</div>
                <div class="note-value">{{ this.httpService.sliceAddress(selectedNetworkInfo?.mint_address) }}</div>
            </div>

            <div class="note">
                <div class="note-heading">24 hr Max limit</div>
                <div class="note-value">{{ selectedNetworkInfo?.max_withdrawal_24h | number }}
                    {{selectedCurrency | uppercase}}
                </div>
            </div>

            <div class="note">
                <div class="note-heading">Network fees</div>
                <div class="note-value">{{ selectedNetworkInfo.withdrawal_fees | number:'0.0-6' }}
                    {{selectedCurrencyInfo.currency}}
                </div>
            </div>

            <div class="note">
                <div class="note-heading">Receivable amount</div>
                <div class="note-value">{{ this.recievable_amount }} {{selectedCurrency | uppercase}}</div>
            </div>
        </cf-card>
    </div>
</div>