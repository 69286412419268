<div class="app-wallet-balances">
    <div class="balances">
        <cf-card type="empty" class="balance" *ngFor="let item of currencies" [theme]="httpService.theme">
            <div class="balance web">
                <div class="currency">
                    <img [src]="this.httpService.getCurrencyUrl(item.currency)" width="30" [alt]="item.currency" />
                    {{ item.name }}
                </div>
                <div class="balance-amount" *ngIf="item.loading">
                    <cf-loader [diameter]="20"></cf-loader>
                </div>
                <div class="balance-amount" *ngIf="!item.loading">
                    {{ item.balance | number
                                        : httpService.getPrecision(
                                        item.currency
                                        ) }} {{ item.currency_key | uppercase }} {{ item.currency_key | uppercase }}
                </div>
                <div class="actions">
                    <cf-button type="text" label="Deposit" (clicked)="redirectToDeposit(item)"></cf-button>
                    <cf-button type="text" label="Withdraw" (clicked)="redirectToWithdrawal(item)"></cf-button>
                </div>
            </div>
            <div class="balance mobile">
                <div class="currency">
                    <img [src]="this.httpService.getCurrencyUrl(item.currency)" width="30" [alt]="item.currency" />
                    {{ item.name }}
                </div>
                <div class="balance-amount" *ngIf="item.loading">
                    <cf-loader [diameter]="20"></cf-loader>
                </div>
                <div class="balance-amount" *ngIf="!item.loading">
                    {{ item.balance | number
                                    : httpService.getPrecision(
                                    item.currency
                                    ) }} {{ item.currency_key | uppercase }} {{ item.currency_key | uppercase }}
                </div>
            </div>
            <div class="balance mobile">
                <div class="actions">
                    <cf-button type="text" label="Deposit" (clicked)="redirectToDeposit(item)"></cf-button>
                    <cf-button type="text" label="Withdraw" (clicked)="redirectToWithdrawal(item)"></cf-button>
                </div>
            </div>
        </cf-card>
    </div>
</div>