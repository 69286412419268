import {
    DatePipe, DecimalPipe, isPlatformBrowser 
} from '@angular/common';
import {
    Component, EventEmitter, Inject, Input, OnInit, Output, 
    PLATFORM_ID
} from '@angular/core';
import * as echarts from 'echarts';
import {
    getDocument, getWindow 
} from 'ssr-window';
import ILineConfig from './ILineConfig';
import ILineData from './ILineData';
import ILinePoint from './ILinePoint';
import formatDate from './formatDate';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: [ './line-chart.component.scss' ],
    providers: [ DatePipe ]
})

export class LineChartComponent implements OnInit {
    @Input() lineConfig: Array<ILineConfig> = [];
    @Input() title: string = '';
    @Input() currency: string = '';
    @Input() suffix: boolean = true;
    @Input() height: number = 420;
    @Input() yAxisPrecision: number = 4;
    @Input() showLegend: boolean = true;
    document: Document;
    platformId: object = {};
    window: Window = getWindow();

    @Output() showCharts = new EventEmitter<any>();
    SelectedChartDays: string = '30D';
    myChart!: echarts.ECharts;
    chartId: string = (new Date().getTime() + this.getRandomInt(1, 100)).toString();

    constructor(private decimalPipe: DecimalPipe, @Inject(PLATFORM_ID) platformId: object) {
        this.document = getDocument();
        this.platformId = platformId;
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId))
            this.initializeChart();
    }

    initializeChart() {
        setTimeout(() => {
            const chartDom = this.document.getElementById(this.chartId)!;

            this.myChart = echarts.init(chartDom);
            this.window.addEventListener('resize', () => {
                this.myChart.resize();
            });

            this.loadchart();
        }, 300);
    }

    extractChartData(data: Array<ILineConfig>): ILineData {
        const xAxisSet = new Set<number>();
        const series: Array<Array<number>> = [];

        // x-axis will be same for all the lines
        data.forEach((item: ILineConfig) => {
            item.data.forEach((point: ILinePoint) => {
                xAxisSet.add(point.x);
            });
        });

        const xAxisArray: Array<number> = Array.from(xAxisSet).sort((a, b) => a - b);

        // prepare y-axis for all the lines
        // fill the missing x-axis with null
        data.forEach((item: ILineConfig) => {
            const map: any = {};

            item.data.forEach((point: ILinePoint) => {
                map[point.x] = point.y;
            });

            xAxisArray.forEach((key: any) => {
                if (!(key in map))
                    map[key] = null;
            });

            const sortedMap = Object.keys(map).sort().reduce((acc: any, key: any) => {
                acc[key] = map[key];

                return acc;
            }, {});

            const yAxis: Array<number> = Object.values(sortedMap);

            series.push(yAxis);
        });

        const xAxisDate = xAxisArray.map(x => formatDate(x));

        return {
            xAxis: xAxisDate,
            series: series
        };
    }

    toFixedWithoutRoundOff(value: any): string {
        const number = +value;

        const numericalValue = this.decimalPipe.transform(number, `0.0-${this.yAxisPrecision}`);

        if (this.suffix)
            return `${numericalValue} ${this.currency}`;

        else
            return `${this.currency} ${numericalValue}`;
    }

    loadchart() {
        setTimeout(() => {
            type EChartsOption = echarts.EChartsOption;

            const chartData = this.extractChartData(this.lineConfig);

            const series = chartData.series;
            const xAxis = chartData.xAxis;

            const legend_data: Array<string> = [];
            
            if (this.showLegend) {
                series.forEach((item: Array<number>, index: number) => {
                    legend_data.push(this.lineConfig[index].name);
                });
            }

            const option: EChartsOption = {
                title: {
                    text: this.title,
                    textStyle: {
                        fontSize: 15
                    }
                },
                // axisPointer: {
                //     show: true
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    valueFormatter: (value) => this.toFixedWithoutRoundOff(value)
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxis,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 0.1,
                            color: 'grey',
                            type: 'dashed'
                        }
                    },
                    minorTick: {
                        show: true,
                        splitNumber: 1
                    }
                },
                yAxis: {
                    type: 'value',
                    minorTick: {
                        show: true,
                        splitNumber: 1
                    },
                    axisLabel: {
                        formatter: (value: any) => this.toFixedWithoutRoundOff(value)
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 0.1,
                            color: 'grey',
                            type: 'dashed'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'grey',
                            width: 0.5,
                            type: 'dashed'
                        }
                    }
                },
                series: series.map((item: Array<number>, index: number) => {
                    return {
                        name: this.lineConfig[index].name,
                        type: 'line',
                        markPoint: {
                            symbol: 'roundRect',
                            symbolSize: 10,
                            itemStyle: {
                                color: 'blue'
                            }
                        },
                        stack: 'Total',
                        data: item,
                        lineStyle: {
                            // color: this.lineConfig[index].lineColor,
                            width: 1,
                            type: 'solid',
                            opacity: 1,
                            curveness: 0,
                            shadowBlur: 0
                        },
                        smooth: true
                    };
                })
            };

            if (this.showLegend) {
                option.legend = {
                    show: true,
                    icon: 'roundRect',
                    textStyle: {
                        color: '#FFFFFF'
                    },
                    inactiveColor: '#72717F',
                    data: legend_data
                };
            }

            option && this.myChart.setOption(option, true);
        }, 400);
    }

    showChart(event: any) {
        this.SelectedChartDays = event;
        this.showCharts.emit(event);
    }

    getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    mergeData(data: any) {
        const map: {
            [key: string]: Array<number>;
        } = {};

        data.forEach((item: any) => {
            if (!map[item.x]) 
                map[item.x] = [];

            map[item.x].push(item.y);
        });
    }
}
