import {
    Component, OnInit 
} from '@angular/core';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
    tabs: Array<TabOption> = [{
        label: 'Personal Profile',
        value: 'personal-profile'
    }, {
        label: 'Beneficiary',
        value: 'beneficiary'
    }];
    currentTab: string = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public httpService: HttpService
    ) { }

    ngOnInit(): void {
        this.currentTab = this.tabs[0].value;
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}