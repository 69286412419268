import {
    Component, Inject, 
    OnInit
} from '@angular/core';
import {
    MatDialogRef, MAT_DIALOG_DATA 
} from '@angular/material/dialog';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import User from '../../models/User';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { Router } from '@angular/router';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-change-blockchain-dialog',
    templateUrl: './change-blockchain-dialog.component.html',
    styleUrl: './change-blockchain-dialog.component.scss'
})
export class ChangeBlockchainDialogComponent implements OnInit {
    loading: boolean = false;

    expected_blockchain_icon: string = '';

    current_blockchain_icon: string = '';

    expected_network: string = '';
    actual_network: string = '';
    window: Window = getWindow();

    constructor(
        public dialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        public walletService: WalletService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private metamaskService: MetamaskService,
        private localStorageService: LocalStorageService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: { expected_blockchain: string, current_blockchain: string, expected_network: string, actual_network: string }
    ) { }

    ngOnInit(): void {
        console.log('expected_blockchain', this.data.expected_blockchain);
        console.log('current_blockchain', this.data.current_blockchain);

        this.expected_blockchain_icon = this.getBlockchainIcon(this.data.expected_blockchain);
        this.current_blockchain_icon = this.getBlockchainIcon(this.data.current_blockchain);

        console.log('expected_blockchain_icon', this.expected_blockchain_icon);
        console.log('current_blockchain_icon', this.current_blockchain_icon);
    }

    getBlockchainIcon(blockchain: string): string {
        console.log('getBlockchainIcon', blockchain);

        if (blockchain === 'solana')
            return this.httpService.getCurrencyUrl('sol');

        else if (blockchain === 'ethereum')
            return this.httpService.getCurrencyUrl('eth');

        else
            return '';
    }

    async logout() {
        console.log('logout', this.constructor.name);
        
        if (this.walletService.current_provider === 'phantom') 
            this.phantomService.disconnectWallet();

        else if (this.walletService.current_provider === 'solflare') 
            this.solflareService.disconnectWallet();

        else if (this.walletService.current_provider === 'metamask') 
            this.metamaskService.disconnectWallet();
        
        this.walletService.wallet_address = '';
        this.walletService.current_provider = '';
        this.localStorageService.clear();

        this.httpService.user = new User();

        await this.router.navigate([ '/' ], {
            replaceUrl: true
        });

        console.log('RELOAD ISSUE URL : Change blockchain dialog => ', this.window.location.href);

        this.window.location.reload();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
