/* eslint-disable array-bracket-spacing */
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    Component, OnInit,
    ViewChild
} from '@angular/core';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { HttpService } from '../../services/http.service';
import ILineConfig from '../charts/line-chart/ILineConfig';
import { HttpErrorResponse } from '@angular/common/http';
import { PayFiService } from '../../services/payfi.service';
import IPayFiPool from '../../interfaces/IPayFiPool';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';

@Component({
    selector: 'app-new-payfi',
    templateUrl: './new-payfi.component.html',
    styleUrl: './new-payfi.component.scss'
})
export class NewPayFiComponent implements OnInit {
    credChartData: Array<any> = [];
    chartLineConfig: Array<ILineConfig> = [];
    commonData: any = {};
    poolStatsData: any = {};
    userPoolStatsData: any;
    loading: boolean = false;
    pools: Array<IPayFiPool> = [];

    unclaimed_interest_usd: number = 0;
    unclaimed_interest_updated_at: number = new Date().getTime();
    unclaimed_interest_loaded: boolean = false;
    unclaimed_interest_tooltip_text: string = '';

    chart_loading: boolean = true;
    stats: any;
    chartTitle = '';

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(
        private route: ActivatedRoute,
        public httpService: HttpService,
        private payFiService: PayFiService,
        private cfAlertService: CfAlertService,
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log('new-payfi-drawdown.component.ts ngOnInit()');

        this.getPayFiPoolCommonData();
        this.updateUserUnclaimedInterest();
    }

    borrow() {
        this.router.navigate(['/cash-loan/borrow']);
    }

    lend() {
        this.router.navigate(['/cash-loan/lend']);
    }

    updateUserUnclaimedInterest() {
        this.unclaimed_interest_loaded = false;

        this.httpService.updateLendUserUnclaimedInterest({}).subscribe(
            (res: any) => {
                this.unclaimed_interest_usd = res.data.unclaimed_interest_usd;
                this.unclaimed_interest_updated_at = res.data.updated;

                this.unclaimed_interest_tooltip_text = `Last updated at ${new Date(
                    this.unclaimed_interest_updated_at
                ).toLocaleString()}`;

                this.unclaimed_interest_loaded = true;
            },
            (err: HttpErrorResponse) => {
                console.error('updateUserUnclaimedInterest error');
                console.error(err);
            }
        );
    }

    getPayFiPoolCommonData() {
        this.loading = true;

        this.payFiService.getPayFiPoolCommonData().subscribe((res) => {
            this.commonData = res.data;

            this.getPayFiPoolStats(this.commonData.lending_pool_id);
            this.getPayFiUserPoolStats(this.commonData.lending_pool_id);
            this.getPoolStats(this.commonData.lending_pool_id);
        },
        (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        }
        );
    }

    getPayFiPoolStats(lending_pool_id: string) {
        this.payFiService.getPayFiPoolStats(lending_pool_id).subscribe((res) => {
            this.loading = false;

            this.poolStatsData = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        }
        );
    }

    getPayFiUserPoolStats(lending_pool_id: string) {
        this.payFiService.getPayFiUserPoolStats(lending_pool_id).subscribe(
            (res) => {
                this.loading = false;

                this.userPoolStatsData = res.data;
            }, (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    showCharts(event: string) {
        let chartDataDrawdown, chartDataInvestment;

        if (event === '30D') {
            chartDataInvestment = this.stats.tvlvsdate_30;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_30;
        }

        else if (event === '60D') {
            chartDataInvestment = this.stats.tvlvsdate_60;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_60;
        }

        else if (event === '90D') {
            chartDataInvestment = this.stats.tvlvsdate_90;
            chartDataDrawdown = this.stats.outstandingdrawdownamountvsdate_90;
        }

        this.chartLineConfig = [{
            name: 'Borrow',
            data: chartDataDrawdown,
            lineColor: '#ff0000'
        }, {
            name: 'Lend',
            data: chartDataInvestment,
            lineColor: '#cf0'
        }];

        this.child.loadchart();
    }

    getPoolStats(lending_pool_id: string) {
        this.chart_loading = true;

        this.httpService.getPoolStats(lending_pool_id).subscribe(res => {
            this.stats = res.data;
            const chartDataInvestment = res.data.tvlvsdate_30;
            const chartDataDrawdown = res.data.outstandingdrawdownamountvsdate_30;

            this.chartLineConfig = [{
                name: 'Borrow',
                data: chartDataDrawdown,
                lineColor: '#ff0000'
            }, {
                name: 'Lend',
                data: chartDataInvestment,
                lineColor: '#cf0'
            }];

            console.log('chartLineConfig', this.chartLineConfig);

            // this.child.loadchart();

            this.chart_loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }
}
