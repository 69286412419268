var MODES = require('./modes');
var AuthCipher = require('./authCipher');
var Buffer = require('safe-buffer').Buffer;
var StreamCipher = require('./streamCipher');
var Transform = require('cipher-base');
var aes = require('./aes');
var ebtk = require('evp_bytestokey');
var inherits = require('inherits');
function Cipher(mode, key, iv) {
  Transform.call(this);
  this._cache = new Splitter();
  this._cipher = new aes.AES(key);
  this._prev = Buffer.from(iv);
  this._mode = mode;
  this._autopadding = true;
}
inherits(Cipher, Transform);
Cipher.prototype._update = function (data) {
  this._cache.add(data);
  var chunk;
  var thing;
  var out = [];
  while (chunk = this._cache.get()) {
    thing = this._mode.encrypt(this, chunk);
    out.push(thing);
  }
  return Buffer.concat(out);
};
var PADDING = Buffer.alloc(16, 0x10);
Cipher.prototype._final = function () {
  var chunk = this._cache.flush();
  if (this._autopadding) {
    chunk = this._mode.encrypt(this, chunk);
    this._cipher.scrub();
    return chunk;
  }
  if (!chunk.equals(PADDING)) {
    this._cipher.scrub();
    throw new Error('data not multiple of block length');
  }
};
Cipher.prototype.setAutoPadding = function (setTo) {
  this._autopadding = !!setTo;
  return this;
};
function Splitter() {
  this.cache = Buffer.allocUnsafe(0);
}
Splitter.prototype.add = function (data) {
  this.cache = Buffer.concat([this.cache, data]);
};
Splitter.prototype.get = function () {
  if (this.cache.length > 15) {
    var out = this.cache.slice(0, 16);
    this.cache = this.cache.slice(16);
    return out;
  }
  return null;
};
Splitter.prototype.flush = function () {
  var len = 16 - this.cache.length;
  var padBuff = Buffer.allocUnsafe(len);
  var i = -1;
  while (++i < len) {
    padBuff.writeUInt8(len, i);
  }
  return Buffer.concat([this.cache, padBuff]);
};
function createCipheriv(suite, password, iv) {
  var config = MODES[suite.toLowerCase()];
  if (!config) throw new TypeError('invalid suite type');
  if (typeof password === 'string') password = Buffer.from(password);
  if (password.length !== config.key / 8) throw new TypeError('invalid key length ' + password.length);
  if (typeof iv === 'string') iv = Buffer.from(iv);
  if (config.mode !== 'GCM' && iv.length !== config.iv) throw new TypeError('invalid iv length ' + iv.length);
  if (config.type === 'stream') {
    return new StreamCipher(config.module, password, iv);
  } else if (config.type === 'auth') {
    return new AuthCipher(config.module, password, iv);
  }
  return new Cipher(config.module, password, iv);
}
function createCipher(suite, password) {
  var config = MODES[suite.toLowerCase()];
  if (!config) throw new TypeError('invalid suite type');
  var keys = ebtk(password, false, config.key, config.iv);
  return createCipheriv(suite, keys.key, keys.iv);
}
exports.createCipheriv = createCipheriv;
exports.createCipher = createCipher;