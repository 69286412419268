import {
    Component, EventEmitter, Input, OnInit, Output 
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { OnboardingService } from '../../onboarding.service';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrl: './email-verification.component.scss'
})

export class EmailVerificationComponent implements OnInit {
    @Output() completed = new EventEmitter<any>();
    @Input() invite_code: string = '';

    register_loading: boolean = false;
    verify_otp_loading: boolean = false;

    window = getWindow();

    email: string = '';
    email_otp: string = '';
    show_email_otp: boolean = false;
    termsAndConditions: boolean = false;
    resend_otp_loading: boolean = false;
    constructor(
        public httpService: HttpService,
        private onboardingService: OnboardingService,
        private cfAlertService: CfAlertService,
        private router: Router) {}
    
    ngOnInit(): void {
        
    }

    onChangeEmail(event: any) {
        this.email = event;
    }

    changeVerificationCode(email_otp: string) {
        this.email_otp = email_otp;
    }

    registerWithEmail() {
        if (!this.termsAndConditions) {
            this.cfAlertService.showMessage('Please agree to the terms and conditions', true);
            
            return;
        }

        this.register_loading = true;

        const body = {
            email: this.email,
            invite_code: this.invite_code
        };

        this.onboardingService.registerWithEmail(body).subscribe((res: any) => {
            this.register_loading = false;

            this.cfAlertService.showSuccess(res.message);

            this.show_email_otp = true;
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.register_loading = false;
        });
    }

    verifyRegisterEmailOtp() {
        if (!this.termsAndConditions) {
            this.cfAlertService.showMessage('Please agree to the terms and conditions', true);
            
            return;
        }

        this.verify_otp_loading = true;

        const body = {
            email: this.email,
            otp: +this.email_otp
        };

        this.onboardingService.verifyRegisterEmailOtp(body).subscribe((res: any) => {
            this.verify_otp_loading = false;

            console.log('verifyRegisterEmailOtp res.data', res.data);
            this.completed.emit(res.data);
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.verify_otp_loading = false;
        });
    }

    resendVerificationCode() {
        this.resend_otp_loading = true;
        const body = {
            email: this.email,
            type: 'registration'
        };

        this.onboardingService.resendEmailOtp(body).subscribe((res: any) => {
            this.resend_otp_loading = false;

            console.log('resend email otp', res.data);
            this.cfAlertService.showSuccess(res.message);
        }, (err: HttpErrorResponse) => {
            this.cfAlertService.showError(err);
            this.resend_otp_loading = false;
        });
    }
    agreeTerms() {
        this.termsAndConditions = !this.termsAndConditions;

        console.log('termsAndConditions => ', this.termsAndConditions);
    }
}
