import {
    Component, EventEmitter, OnInit, 
    Output
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-wallet-balances',
    templateUrl: './wallet-balances.component.html',
    styleUrl: './wallet-balances.component.scss'
})
export class WalletBalancesComponent implements OnInit {
    currencies: any = [];
    @Output() changeTab = new EventEmitter<any>();

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getCurrenciesList();
    }

    getCurrenciesList() {
        this.httpService.getCurrencyList('').subscribe((res: any) => {
            const currencies = res.data;

            currencies.forEach((item: any) => {
                item.loading = true;
            });

            this.currencies = currencies;

            this.getBalances();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    async getBalances() {
        for (let i = 0; i < this.currencies.length; i++) {
            this.httpService.getCurrencyBalanceV3(this.currencies[i].currency, this.currencies[i].network_list[0].mint_address).subscribe((res: any) => {
                this.currencies[i].balance = res.data;
                this.currencies[i].loading = false;
            }, (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            });
        }
    }

    redirectToDeposit(currency_info: any) {
        this.router.navigate([ '/funds' ], {
            queryParams: {
                tab: 'deposits'
            }
        });

        this.changeTab.emit({
            tab: 'deposit',
            currency: currency_info.currency
        });
    }

    redirectToWithdrawal(currency_info: any) {
        this.router.navigate([ '/funds' ], {
            queryParams: {
                tab: 'withdraw'
            }
        });

        this.changeTab.emit({
            tab: 'withdraw',
            currency: currency_info.currency
        });
    }
}