import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import { AmbassadorService } from '../ambassador.service';
import { HttpErrorResponse } from '@angular/common/http';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-ambassador',
    templateUrl: './ambassador.component.html',
    styleUrl: './ambassador.component.scss'
})
export class AmbassadorComponent implements OnInit {
    currentTab = 'onboarding';

    tabs: any[] = [];
    selected_status: string = '';
    statusOptions: Array<IDropdown> = [];
    loading: boolean = false;
    members: Array<any> = [];
    totalChildrenDataCount: number = 0;
    invitation_link: any;
    referred_user_count: any;

    page: number = 0;
    limit: number = 10;
    level: any = 'all';
    window: Window;

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private router: Router,
        private ambassadorService: AmbassadorService
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.getTabs();

        this.currentTab = this.tabs[0].key;

        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.key === tab))
                    this.currentTab = tab;
            }
        });

        this.getReferralLevels();
        this.getMembers();
        this.getUserTasks();
        this.invitation_link = `${this.window.location.origin}/invite/${this.httpService.user.referral_code}`;
    }
    getTabs() {
        this.tabs = [{
            name: 'Onboarding',
            key: 'onboarding',
            ambassador_required: false
        },
        //     {
        //     name: 'Private credit',
        //     key: 'private-credit',
        //     ambassador_required: false
        // },
        {
            name: 'Members',
            key: 'members',
            ambassador_required: false

        }];
        // {
        // name: 'Leaderboard',
        // key: 'leaderboard',
        // ambassador_required: false
        // }
    }
    // getTabsForNonAmbbassodorUsers() {
    //     this.tabs = [ 
    //         {
    //             name: 'Members',
    //             key: 'members',
    //             ambassador_required: false

    //         }
    //     ];
    // }

    changeTab(tab: string) {
        this.currentTab = tab;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getMembers() {
        this.loading = true;
        const data = {
            level: this.level,
            page: this.page,
            limit: this.limit
        };

        this.httpService.getMembers(data).subscribe(
            (res) => {
                this.members = res.data.children_data;
                this.totalChildrenDataCount = res.data.totalChildrenDataCount;
                this.loading = false;
            }, (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getMembers();
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.level = event.value;
        this.page = 0;
        this.getMembers();
    }

    getReferralLevels() {
        this.ambassadorService.getReferralLevelsV2().subscribe((res) => {
            const levels = res.data;

            const levelOptions = [
                {
                    value: 'all',
                    label: 'All',
                    optionEnabled: true
                },
                ...levels.map((level: any) => ({
                    value: level.level.toString(),
                    label: `Level ${level.level}-${level.name}`,
                    optionEnabled: true
                }))
            ];

            this.statusOptions = levelOptions;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getUserTasks() {
        this.httpService.getUserTasks().subscribe((res: any) => {
            this.referred_user_count = res.data.referred_user_count;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
