<div class="app-header-popup-menu" [ngClass]="httpService.theme">
    <div class="diamonds-holder">
        {{httpService.user.email}}
    </div>

    <!-- <div class="divider"></div> -->
    
    <!-- <div class="diamonds-holder">
        <img [width]="20" src="https://assets.credible.finance/IMG312.png" alt="">
        <span>
            {{ httpService.user.diamonds | number }} Moons
        </span>
    </div> -->

    <div class="divider"></div>

    <div class="menu-item">
        <img src="https://assets.credible.finance/Logout.png" class="menu-item-image" alt="Loogout" />

        <div class="menu-item-name" (click)="logout()">Logout</div>
    </div>
</div>