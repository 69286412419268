import {
    Component, OnInit 
} from '@angular/core';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { HttpService } from '../../services/http.service';
import { WalletService } from '../../services/wallet.service';

@Component({
    selector: 'app-wallet-new-deposit',
    templateUrl: './wallet-new-deposit.component.html',
    styleUrl: './wallet-new-deposit.component.scss'
})
export class WalletNewDepositComponent implements OnInit {
    currencyList : any;
    currencyOptions :any;
    selectedCurrency: string = '';
    selectedCurrencyLabel: string = '';
    selectedCurrencyInfo:any;
    networkOptions: Array<IDropdown> = [];
    selectedNetwork: string = '';
    selectedNetworkInfo : any;
    wallet_address: string = '';

    constructor(
        public httpService: HttpService,
        private walletService: WalletService
    ) {}

    ngOnInit(): void {
        //get currency list
        this.getCurrencyList();
    }

    getCurrencyList() {
        this.httpService.getCurrencyList('deposit').subscribe((data: any) => {
            console.log('data', data);
            this.currencyList = data.data;
           
            this.currencyOptions = this.currencyList.map((currency: any) => (
                {
                    currency: currency.currency,
                    label: currency.name,
                    value: currency.name,
                    logo: this.httpService.getCurrencyUrl(currency.currency),
                    optionEnabled: true
                }
            ));
            this.selectedCurrency = this.currencyOptions[0].value;
            this.selectedCurrencyLabel = this.currencyOptions[0].label;
            this.selectedCurrencyInfo = this.currencyOptions[0];
            console.log('  this.currencyOptions', this.currencyOptions);
            console.log(' 90 this.selectedCurrency', this.selectedCurrency);
            console.log('92 this.selectedCurrencyLabel', this.selectedCurrencyLabel);
           
            this.networkOptions = this.currencyList[0].network_list.map((network: any) => ({
                label: network.blockchain,
                value: network.blockchain,
                logo: network.logo,
                optionEnabled: true
            }));
        
            this.selectedNetwork = this.networkOptions[0].value;
            this.selectedNetworkInfo = this.currencyList[0].network_list[0];

            console.log('selectedNetworkInfo', this.selectedNetworkInfo);
            this.getWalletAddress(this.selectedNetwork, this.selectedCurrencyInfo.currency);
        });
    }
    changeCurrency(option: any): void {
        console.log('105 option', option);
        this.selectedCurrencyInfo = option;
        this.selectedCurrency = option.value;
        this.selectedCurrencyLabel = option.value;
        
        console.log('this.selectedCurrency', this.selectedCurrency);
        
        const selectedCurrencyData = this.currencyList.find((currency : any) => currency.name === this.selectedCurrencyLabel);

        console.log('selectedCurrencyData', selectedCurrencyData);
        this.networkOptions = selectedCurrencyData.network_list.map((network: any) => ({
            label: network.blockchain,
            value: network.blockchain,
            logo: network.logo,
            optionEnabled: true
        }));
        
        this.selectedNetwork = this.networkOptions[0].value;
        this.selectedNetworkInfo = selectedCurrencyData.network_list[0];
        console.log('selectedNetworkInfo', this.selectedNetworkInfo);
        this.changeNetwork(this.networkOptions[0]); 
    }

    changeNetwork(option: IDropdown): void {
        console.log('option', option);
        this.wallet_address = '';
        this.selectedNetwork = option.value;
        console.log('this.selectedNetwork', this.selectedNetwork);
        this.selectedNetworkInfo = this.currencyList.find((currency: any) => currency.name === this.selectedCurrencyLabel).network_list.find((network: any) => network.blockchain === this.selectedNetwork);
        console.log('this.selectedNetworkInfo', this.selectedNetworkInfo);
        this.getWalletAddress(this.selectedNetwork, this.selectedCurrencyInfo.currency);
    }

    getWalletAddress(blockchain:any, currency:any) {
        this.httpService.getWalletAddress(blockchain, currency).subscribe((data:any) => {
            console.log('getWalletAddress', data);
            this.wallet_address = data.data.wallet_address;
        });
    }
}
