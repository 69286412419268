import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, HostListener, Input, OnInit
} from '@angular/core';

import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IInvestment from '../../interfaces/IInvestment';

import { getWindow } from 'ssr-window';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lend-pool-transactions',
    templateUrl: './lend-pool-transactions.component.html',
    styleUrl: './lend-pool-transactions.component.scss'
})

export class LendPoolTransactionsComponent implements OnInit {
    loading: boolean = false;
    items: Array<IInvestment> = [];
    total_count: number = 0;
    page: number = 0;
    limit: number = 10;

    @Input() pool_id: string | null = null;

    window: Window;
    
    width: any;

    constructor(public httpService: HttpService, 
        private cfAlertService: CfAlertService, private router: Router
    ) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        if (this.httpService.user.user_id > 0)
            this.getInvestments();

        this.width = this.window.innerWidth;
    }
    
    getInvestments() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            pool_id: this.pool_id,
            status: 'completed'
        };

        this.httpService.getInvestments(body).subscribe((res: any) => {
            this.items = res.data;
            this.total_count = res.total_count;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    pageChanged(event: any) {
        this.page = event;
        this.getInvestments();
    }
}