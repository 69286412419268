/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PayFiService } from '../../services/payfi.service';
import IPayFiDrawdown from '../../interfaces/IPayFiDrawdown';

@Component({
    selector: 'app-my-borrowings-payfi',
    templateUrl: './my-borrowings-payfi.component.html',
    styleUrl: './my-borrowings-payfi.component.scss'
})
export class MyBorrowingsPayFiComponent implements OnInit {
    view() {
        throw new Error('Method not implemented.');
    }
    repay() {
        throw new Error('Method not implemented.');
    }
    items: any = [{}, {}, {}, {}, {}, {}, {}];
    currentDate: string | number | Date = new Date().getTime();
    show_no_drawdowns: boolean = false;
    loading: boolean = false;
    drawdowns: Array<IPayFiDrawdown> = [];
    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    constructor(
        public httpService: HttpService,
        private payFiService: PayFiService,
        private cfAlertService: CfAlertService,
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log('new-payfi.component.ts ngOnInit()');

        this.getDrawdowns();
    }

    getDrawdowns() {
        this.show_no_drawdowns = false;
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            usage: 'create_repayment'
        };

        this.payFiService.getDrawdowns(body).subscribe(
            (res) => {
                this.drawdowns = res.data;
                this.total_count = res.total_count;
                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        );
    }

    redirect(item: any) {
        this.router.navigate(['/cash-loan/borrow'], {
            queryParams: {
                currenttab: 'repayment',
                drawdown: JSON.stringify(item)
            }
        });
    }

    pageChanged(event: any) {
        this.page = event;
        this.getDrawdowns();
    }
}
