<div class="app-lend-widget" [ngClass]="httpService.theme">
    <div class="lend-widget-sub-section">
        <div class="lend-widget-sub-section-tabs">
            <div class="button-container">
                <button type="button" class="button" [ngClass]="currentTab === 'nodePurchase' ? 'active' :''"
                    (click)="changedTab('nodePurchase')">
                    Node Purchase
                </button>
                <button type="button" class="button" [ngClass]="currentTab === 'nodeRewards' ? 'active' :''"
                    (click)="changedTab('nodeRewards')">
                    Node Rewards
                </button>
            </div>
        </div>

        <div class="tabs">
            <app-node-purchase *ngIf="currentTab === 'nodePurchase'"></app-node-purchase>
            <app-node-rewards *ngIf="currentTab === 'nodeRewards'"></app-node-rewards>
        </div>
    </div>
</div>