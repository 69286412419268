<div class="app-change-chain-dialog">
    <div class="page-heading" mat-dialog-title>Change network</div>

    <mat-dialog-content>
        <div class="blockhain-container">
            <span class="title">Wallet address:</span>
            <span class="blockchain">
                {{ httpService.sliceAddress(walletService.wallet_address, 8) }}
            </span>
        </div>
        
        <div class="blockhain-container">
            <span class="title">Current chain:</span>
            <span class="blockchain">
                <img class="blockchain-icon" [src]="current_chain_info.chainLogo" [alt]="current_chain_info.chainName" />
                {{ current_chain_info.chainName | titlecase }}
            </span>
        </div>

        <div class="blockhain-container">
            <span class="title">Expected chain:</span>
            <span class="blockchain">
                <img class="blockchain-icon" [src]="expected_chain_info.chainLogo" [alt]="expected_chain_info.chainName" />
                {{ expected_chain_info.chainName | titlecase}}
            </span>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="controls">
            <cf-button type="outline" label="Cancel" (clicked)="closeDialog()"></cf-button>
            <cf-button type="solid" label="Switch network" (clicked)="switchNetwork()"></cf-button>
        </div>
    </mat-dialog-actions>
</div>