<div class="app-lend-pool-details-payfi" [ngClass]="httpService.theme">
    <div class="top-container">
        <div class="pool-heading">
            {{ commonData.name }}
        </div>

        <div class="pool-description">
            {{ commonData.description }}
        </div>

        <div class="row ">
            <app-payfi-widget [lending_pool_id]="commonData.lending_pool_id" [type]="type"></app-payfi-widget>
        </div>
    </div>

    <div class="row mt-4">
        <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
            (changed)="changeTab($event)"></cf-tabs>

        <div class="tabs">
            <app-my-payfi-drawdowns *ngIf="currentTab === 'my-drawdowns'" [showStats]="false"></app-my-payfi-drawdowns>
            
            <app-my-payfi-collaterals *ngIf="currentTab === 'my-collaterals'"
                [showStats]="false"></app-my-payfi-collaterals>

            <app-my-payfi-repayments *ngIf="currentTab === 'my-repayments'"
                [showStats]="false"></app-my-payfi-repayments>

            <app-my-payfi-investments *ngIf="currentTab === 'my-investments'" [showStats]="false"></app-my-payfi-investments>

            <app-my-payfi-withdrawals *ngIf="currentTab === 'my-withdrawals'"></app-my-payfi-withdrawals>
        </div>
    </div>
</div>