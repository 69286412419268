const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

function formatDate(timestamp: number): string {
    if (!timestamp || isNaN(timestamp)) return '-';

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) return '-';

    return `${date.getDate()} ${[ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ][date.getMonth()]}`;
}

export default formatDate;