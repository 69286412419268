import { Connection } from '@solana/web3.js';
import {SOLANA_ENVIRONMENT} from '../app/app.config';
import SOLANA_CHAINS from '../constants/SolanaChains';

function getSolanaConnection() {
    if (!SOLANA_CHAINS[SOLANA_ENVIRONMENT]) {
        console.error('Invalid cluster', SOLANA_ENVIRONMENT);

        throw new Error('Invalid cluster: ' + SOLANA_ENVIRONMENT);
    }

    const connection = new Connection(SOLANA_CHAINS[SOLANA_ENVIRONMENT].rpc, 'confirmed');

    return connection;
}

export default getSolanaConnection;