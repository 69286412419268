import {
    ChangeDetectorRef,
    Component, Inject, OnInit
} from '@angular/core';
import {
    MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { PayFiService } from '../../services/payfi.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import ICheckbox from '@crediblefinance/credible-ui/lib/cf-checkbox-large/ICheckbox';

@Component({
    selector: 'app-add-payout-beneficiary',
    templateUrl: './add-payout-beneficiary.component.html',
    styleUrl: './add-payout-beneficiary.component.scss'
})
export class AddPayoutBeneficiaryComponent implements OnInit {
    loading: boolean = true;
    add_payout_beneficiary_loading: boolean = false;

    selected_payout_currency: string = '';
    payout_currency_options: Array<IDropdown> = [];

    payout_currencies: Array<any> = [];

    selected_payout_method: string = '';
    payout_method_options: Array<IDropdown> = [];

    payout_method_input_fields: Array<any> = [];

    add_payout_beneficiary_request_body: any = {
        input_fields: {},
        currency: '',
        payout_method: ''
    };

    beneficiary_added: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AddPayoutBeneficiaryComponent>, private cdr: ChangeDetectorRef,
        public httpService: HttpService, private payFiService: PayFiService, private cfAlertService: CfAlertService,
        @Inject(MAT_DIALOG_DATA) public data: { current_profile_picture: string }
    ) { }

    ngOnInit(): void {
        this.getPayoutCurrencies();
    }

    getPayoutCurrencies() {
        this.payFiService.getPayoutCurrencies().subscribe((res: any) => {
            this.payout_currencies = res.data;

            for (let i = 0; i < this.payout_currencies.length; i++) {
                this.payout_currency_options.push({
                    label: this.payout_currencies[i].label,
                    value: this.payout_currencies[i].key,
                    logo: this.httpService.getCurrencyUrl(this.payout_currencies[i].key),
                    optionEnabled: true
                });
            }

            this.selected_payout_currency = this.payout_currency_options[0].value;

            this.updatePayoutMethods();
        }, (err: HttpErrorResponse) => {
            console.error('getPayoutBeneficiaries error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    payoutCurrencyChanged(option: IDropdown) {
        this.selected_payout_currency = option.value;

        this.updatePayoutMethods();
    }

    updatePayoutMethods() {
        const selected_payout_currency_info = this.payout_currencies.find(item => {
            return item.key === this.selected_payout_currency;
        });

        this.payout_method_options = [];

        for (let i = 0; i < selected_payout_currency_info.payout_methods.length; i++) {
            this.payout_method_options.push({
                label: selected_payout_currency_info.payout_methods[i].label,
                value: selected_payout_currency_info.payout_methods[i].key,
                optionEnabled: true
            });
        }

        this.selected_payout_method = this.payout_method_options[0].value;

        this.updatePayoutMethodInputFields();

        this.loading = false;
    }

    updatePayoutMethodInputFields() {
        this.payout_method_input_fields = this.payout_currencies.find(item => {
            return item.key === this.selected_payout_currency;
        }).payout_methods.find((item: any) => {
            return this.selected_payout_method === item.key;
        }).input_fields.filter((item: any) => {
            return !item.readonly;
        });

        this.add_payout_beneficiary_request_body.input_fields = {};

        this.payout_method_input_fields.forEach((item: any) => {
            this.add_payout_beneficiary_request_body.input_fields[item.key] = item.value;
        });
    }

    payoutMethodChanged(option: IDropdown) {
        this.selected_payout_method = option.value;

        this.updatePayoutMethodInputFields();
    }

    textInputChanged(value: any, key: string) {
        // console.log('hiiiiiiiiiiii', key, value);

        // this.add_payout_beneficiary_request_body.input_fields[key] = value;

        console.log('Value Changed:', key, value);
        this.add_payout_beneficiary_request_body.input_fields[key] = value;
        this.cdr.detectChanges(); // Trigger change detection
    }

    checkboxChanged(event: ICheckbox, key: string) {
        this.add_payout_beneficiary_request_body.input_fields[key] = event.value;
    }

    addPayoutBeneficiary() {
        this.add_payout_beneficiary_loading = true;

        this.add_payout_beneficiary_request_body.currency = this.selected_payout_currency;
        this.add_payout_beneficiary_request_body.payout_method = this.selected_payout_method;

        this.payFiService.addPayoutBeneficiary(this.add_payout_beneficiary_request_body).subscribe((res: any) => {
            this.cfAlertService.showSuccess('Beneficiary added successfully');

            this.beneficiary_added = true;
            this.dialogRef.close(this.beneficiary_added);
        }, (err: HttpErrorResponse) => {
            console.error('addPayoutBeneficiary error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.add_payout_beneficiary_loading = false;
        });
    }

    closeDialog() {
        this.dialogRef.close(this.beneficiary_added);
    }
}
