import {
    Component, OnInit
} from '@angular/core';
import { Router} from '@angular/router';

@Component({
    selector: 'app-accept-invite-code',
    templateUrl: './accept-invite-code.component.html',
    styleUrl: './accept-invite-code.component.scss'
})
export class AcceptInviteCodeComponent implements OnInit {
    invite_code: string = '';

    constructor(
        private router: Router
    ) {
        console.log('AcceptInviteCodeComponent constructor');
    }

    ngOnInit(): void {
        console.log('AcceptInviteCodeComponent ngOnInit');

        this.invite_code = this.router.url.split('/')[2];

        console.log('invite_code', this.invite_code);

        this.router.navigate([ '/register' ], {
            queryParams: {
                invite_code: this.invite_code
            }
        });
    }
}
