/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { getWindow } from 'ssr-window';
import { DiscordService } from '../../services/discord.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { KycService } from '../../services/kyc.service';
import { TwitterService } from '../../services/twitter.service';
import { OnboardingService } from '../onboarding.service';
import { WalletService } from '../../services/wallet.service';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import ILineConfig from '../charts/line-chart/ILineConfig';

@Component({
    selector: 'app-node-sale',
    templateUrl: './node-sale.component.html',
    styleUrls: ['./node-sale.component.scss']
})
export class NodeSaleComponent implements OnInit {
    width: any;
    total_apr_tooltip_text: string = '';
    chart_loading: boolean = true;
    stats: any;
    graphArray: any = [];
    chartTitle = '';
    labelArrayPool: Array<any> = [ 'My Nodes' ];
    tabIndexPool: number = 0;
    labelArrayVault: Array<any> = [ 'Vault Allocation' ];
    tabIndexVault: number = 0;
    pool_id:any;
    drawdown_id:any;
    chartLineConfig: Array<ILineConfig> = [];
    isTooltipVisible = false;

    openedFaqIndex: string = '-1';
    faqs: Array<{
        question: string;
        answer: string;
        index: string;
    }> = [];

    constructor(public httpService: HttpService,
        private discordService: DiscordService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private kycService: KycService,
        private twitterService: TwitterService,
        private onboardingService: OnboardingService,
        public walletService: WalletService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>
    ) {
        this.window = getWindow();
        this.width = this.window.outerWidth;
    }

    tabs: Array<TabOption> = [{
        label: 'My Node',
        value: 'my-node'
    }];

    currentTab = 'my-node';

    questions: Array<any> = [];
    max: number = 5;
    current: number = 2;
    window: Window;

    features: Array<any> = [{
        icon: 'https://assets.credible.finance/Favicon_Black.png',
        title: '💰 Fee-Based Lifetime Rewards in $CRED',
        desc: 'Crypto ipsum bitcoin ethereum dogecoin litecoin. Velas nexo bancor m'
    }, {
        icon: 'https://assets.credible.finance/Favicon_Black.png',
        title: '🤝 Get upto 30% referral bonus',
        desc: 'Crypto ipsum bitcoin ethereum dogecoin litecoin. Velas nexo bancor m'
    }, {
        icon: 'https://assets.credible.finance/Favicon_Black.png',
        title: '🖼️ Get node License as non-transferable NFT',
        desc: 'Crypto ipsum bitcoin ethereum dogecoin litecoin. Velas nexo bancor m'
    }, {
        icon: 'https://assets.credible.finance/Favicon_Black.png',
        title: '⚙️ Get upto 10% node operation commission',
        desc: 'Crypto ipsum bitcoin ethereum dogecoin litecoin. Velas nexo bancor m'
    }];

    public discord: any = {
        loading: false,
        loginButton: true,
        serverJoined: false,
        checkGroupButton: false,
        checkGroupJoinedIntervalId: null,
        accountConnected: false,
        groupCheckAttempt: 0
    };

    public twitter: any = {
        loading: false,
        accountConnected: false
    };

    kyc_loading: boolean = false;
    twitterJoined: boolean = false;
    discordJoined: boolean = false;
    telegramJoined: boolean = false;
    discord_join_loading: boolean = false;
    twitter_join_loading: boolean = false;
    share_twitter_join_loading: boolean = false;
    telegram_join_loading: boolean = false;

    ngOnInit(): void {
        this.getFaqs();
    }

    getFaqs() {
        this.faqs = [
            {
                index: '0',
                question: 'What is the Credible platform?',
                answer: 'Credible is a decentralized blockchain-based ecosystem designed to modernize credit systems by introducing tokenized debt markets. With its oracle network, Credible Nodes, the platform provides data validation, staking rewards, and governance, creating a transparent and secure infrastructure for its users.'
            },
            {
                index: '1',
                question: 'What is $vCRED, and how does it work?',
                answer: '$vCRED is a vested form of Credible’s $CRED token. It plays a central role in Credible’s ecosystem, allowing users to participate in staking, governance, and reward distribution. By staking $vCRED, holders earn rewards and help secure the network. Additionally, larger purchases of $vCRED come with reward multipliers to encourage deeper involvement and network stability.'
            },
            {
                index: '2',
                question: 'How can I earn rewards with vCRED?',
                answer: 'By staking $vCRED with Credible’s nodes, users earn daily staking rewards based on the amount they’ve staked and the node’s performance. Rewards are distributed proportionally, with larger stakes often earning higher returns. Additionally, users are encouraged to sell accrued rewards rather than unstaking, as unstaking can reduce overall network reward rates.'
            }
        ];
    }

    openFaq(index: string) {
        if (this.openedFaqIndex === index) this.openedFaqIndex = '-1';
        else this.openedFaqIndex = index;
    }

    openQues(index: any) {
        this.questions[index].open = true;
    }

    closeQues(index: any) {
        this.questions[index].open = false;
    }

    redirectUrl(url: any) {
        this.window.open(url, '_blank');
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getSpaceshipFaqQuestions() {
        this.httpService.getSpaceshipFaqQuestions().subscribe((res: any) => {
            this.questions = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    get progress(): number {
        return (30 / 1000) * 100;
    }

    showCharts(event: string) {}

    onTabChangePool(index: any) {
        console.log('onTabChangePool >>', index);

        this.tabIndexPool = index;
    }

    onTabChangeVault(index: any) {
        console.log('onTabChangeVault >>', index);

        this.tabIndexVault = index;
    }

    showTooltip() {
        this.isTooltipVisible = true;
    }

    hideTooltip() {
        this.isTooltipVisible = false;
    }
}
