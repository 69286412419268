import {
    RouterModule, Routes
} from '@angular/router';
import { NgModule } from '@angular/core';
import { LoyaltyComponent } from './loyalty/loyalty.component';
import { SocialCallbackComponent } from './social-callback/social-callback.component';
import { BorrowPoolDetailsComponent } from './borrow-pool-details/borrow-pool-details.component';
import { LendComponent } from './lend/lend.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomepageComponent } from './homepage/homepage.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { BorrowerDashboardComponent } from './borrower-dashboard/borrower-dashboard.component';
import { RegisterStepsComponent } from './register-steps/register-steps.component';
import { AuthGuard } from './gaurds/auth.guard';
import { AcceptInviteCodeComponent } from './accept-invite-code/accept-invite-code.component';
import { LendPoolDetailsComponent } from './lend-pool-details/lend-pool-details.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AmbassadorComponent } from './ambassador/ambassador.component';
import { CircleFundsComponent } from './circle/circle-funds/circle-funds.component';
import { ProfileComponent } from './profile/profile.component';
import { SpaceshipComponent } from './spaceship/spaceship.component';
import { NewPayFiComponent } from './new-payfi/new-payfi.component';
import { LendPoolDetailsPayFiComponent } from './lend-pool-details/lend-pool-details-payfi/lend-pool-details-payfi.component';
import { LoginComponent } from './login/login.component';

export const routes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        // component: HomepageNewComponent,
        data: {
            title: '',
            description: 'Early invest in private credit and equities',
            showSidebar: false
        }
    },
    // {
    //     path: 'dashboard',
    //     canActivate: [ AuthGuard ],
    //     component: DashboardComponent,
    //     data: {
    //         title: 'Dashboard',
    //         description:
    //             'Access your personalized dashboard on Credible. Monitor your investments, loans, earnings, and account activities all in one place. Stay informed and manage your financial portfolio efficiently.'
    //     }
    // },
    {
        path: 'liquidity',
        canActivate: [ AuthGuard ],
        component: LendComponent,
        data: {
            title: 'Liquidity',
            description:
                'Check and manage your liquidity on Credible. View the Total Value Locked (TVL) and Total Outstanding Drawdown in the platform. Explore various pools and staking opportunities to maximize your returns and contribute to the Credible ecosystem.'
        }
    },
    {
        path: 'cash-loan',
        canActivate: [ AuthGuard ],
        component: NewPayFiComponent,
        data: {
            title: 'Cash Loan',
            description:
                'Check and manage your liquidity on Credible. View the Total Value Locked (TVL) and Total Outstanding Drawdown in the platform. Explore various pools and staking opportunities to maximize your returns and contribute to the Credible ecosystem.'
        }
    },
    {
        path: 'cash-loan/:type',
        canActivate: [ AuthGuard ],
        component: LendPoolDetailsPayFiComponent,
        data: {
            title: 'Cash Loan details',
            description:
                'Check and manage your liquidity on Credible. View the Total Value Locked (TVL) and Total Outstanding Drawdown in the platform. Explore various pools and staking opportunities to maximize your returns and contribute to the Credible ecosystem.'
        }
    },
    {
        path: 'account',
        canActivate: [ AuthGuard ],
        component: ProfileComponent,
        data: {
            title: 'Account',
            description: 'Account'
        }
    },
    {
        path: 'transactions',
        canActivate: [ AuthGuard ],
        component: TransactionsComponent,
        data: {
            title: 'Transactions',
            description:
                'Monitor all your financial activities on Credible. View and manage your investments, including All Investments and My Investments. Keep track of your earnings, withdrawals, loans, and repayments.'
        }
    },
    {
        path: 'liquidity/:category_id/:pool_id',
        canActivate: [ AuthGuard ],
        component: LendPoolDetailsComponent,
        data: {
            title: 'Pool details',
            description: 'Pool details'
        }
    },
    {
        path: 'tokenize/:category_id/:pool_id',
        canActivate: [ AuthGuard ],
        component: BorrowPoolDetailsComponent,
        data: {
            title: 'Tokenize',
            description:
                'Check the Total Value Locked (TVL) and Loans Originated for your assets. Manage withdrawals and repayments from your selected pool seamlessly. '
        }
    },
    {
        path: 'tokenize',
        canActivate: [ AuthGuard ],
        component: BorrowerDashboardComponent,
        data: {
            title: 'Pool details',
            description: 'Pool details'
        }
    },
    {
        path: 'points',
        canActivate: [ AuthGuard ],
        component: LoyaltyComponent,
        data: {
            title: 'Airdrop',
            description: 'Airdrop',
            sidebar: false
        }
    },
    // {
    //     path: 'airdrop',
    //     canActivate: [ AuthGuard ],
    //     component: AirdropComponent,
    //     data: {
    //         title: 'Airdrop',
    //         description: 'Airdrop',
    //         sidebar: false
    //     }
    // },
    {
        path: 'social/:platform/callback',
        canActivate: [ AuthGuard ],
        component: SocialCallbackComponent
    },
    {
        path: 'register',
        component: RegisterStepsComponent,
        data: {
            data: {
                showConnectWallet: false
            }
        }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            data: {
                title: 'Login',
                description: 'Login',
                showConnectWallet: false
            }
        }
    },
    {
        path: 'referrals',
        component: AmbassadorComponent,
        canActivate: [ AuthGuard ],
        data: {
            title: 'Referrals',
            description: 'Referrals'
        }
    },
    {
        path: 'funds',
        component: CircleFundsComponent,
        canActivate: [ AuthGuard ],
        data: {
            title: 'Funds',
            description: 'Funds'
        }
    },
    {
        path: 'invite/:code',
        component: AcceptInviteCodeComponent
    },
    {
        path: 'terms-condition',
        component: TermsConditionComponent,
        data: {
            title: 'Terms and conditions',
            description: 'Terms and Conditions',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
            title: 'Privacy Policy',
            description: 'Privacy Policy',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'airdrop',
        component: SpaceshipComponent,
        canActivate: [ AuthGuard ],
        data: {
            title: 'Airdrop',
            description: 'Airdrop',
            showSidebar: true,
            showConnectWallet: true
        }
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: {
            title: 'Not found',
            description: 'Not found'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking'
        })
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
