<div class="connect_wallet container-fluid">
    <div class="container gx-0">
        <!-- <span class="register-heading">You will be asked to sign a transaction to
                authenticate you in our platform.
            </span> -->

        <div class="ledger">
            <cf-checkbox (changed)="ledgerChanged($event)" name="I have a ledger device"></cf-checkbox>
        </div>

        <div class="wallets">
            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'phantom'}"
                (click)="changeProvider('phantom')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/Phantom-Icon-Transparent-Purple.png" />
                    <span>Phantom</span>
                </div>
                <span *ngIf="!phantomDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
                <span *ngIf="phantomDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>
            </div>

            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'solflare'}"
                (click)="changeProvider('solflare')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/solflare.png" />
                    <span>Solflare</span>
                </div>
                <img src= />
                <span *ngIf="solflareDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>

                <span *ngIf="!solflareDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
            </div>

            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'metamask'}"
                (click)="changeProvider('metamask')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/logos/Metamask.png" />
                    <span>Metamask</span>
                </div>
                <span *ngIf="!metamaskDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
                <span *ngIf="metamaskDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>
            </div>

            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'binance'}"
                (click)="changeProvider('binance')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/Binanceicon.png" />
                    <span>Binance Web3 Wallet</span>
                </div>
                <span *ngIf="!binanceWalletDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
                <span *ngIf="binanceWalletDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                </span>
            </div>
        </div>

        

        <div class="row mb-3" *ngIf="!showControls.connectButton">
            <div class="col-md-12 current-wallet-address">
                <cf-input class="web" [value]="wallet_address" label="Wallet address" type="text" [copy]="true"
                    placeholder="Wallet address" (valueChanged)="walletChanged($event)" [formGroup]="form"
                    [readonly]="true" [control]="$any(form).get('wallet_address')">
                </cf-input>

                <cf-input class="mobile" [value]="this.httpService.sliceAddress(wallet_address, 8)"
                    label="Wallet address" type="text" [copy]="true" placeholder="Wallet address"
                    (valueChanged)="walletChanged($event)" [formGroup]="form" [readonly]="true"
                    [control]="$any(form).get('wallet_address')">
                </cf-input>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'phantom'">
            <div class="action" *ngIf="!phantomDetected && width > 600">
                <cf-button label="Download Phantom" (clicked)="phantomService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="!phantomDetected && width <= 600">
                <cf-button label="Connect Phantom" (clicked)="phantomService.connectWalletMobile()" type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="phantomDetected && showControls.connectButton">
                <cf-button label="Connect Phantom" (clicked)="phantomService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="phantomDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Phantom" (clicked)="phantomService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'metamask'">
            <div class="action" *ngIf="!metamaskDetected && width > 600">
                <cf-button label="Download Metamask" (clicked)="metamaskService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="!metamaskDetected && width <= 600">
                <cf-button label="Connect Metamask" (clicked)="metamaskService.connectWalletMobile()" type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="metamaskDetected && showControls.connectButton">
                <cf-button label="Connect Metamask" (clicked)="metamaskService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="metamaskDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Metamask" (clicked)="metamaskService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'binance'">
            <div class="action" *ngIf="!binanceWalletDetected && width > 600">
                <cf-button label="Download Binance Wallet" (clicked)="binanceWalletService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="!binanceWalletDetected && width <= 600">
                <cf-button label="Connect Binance Wallet" (clicked)="binanceWalletService.connectWalletMobile()" type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="binanceWalletDetected && showControls.connectButton">
                <cf-button label="Connect Binance Wallet" (clicked)="binanceWalletService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="binanceWalletDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Binance Wallet" (clicked)="binanceWalletService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'solflare'">
            <div class="action" *ngIf="!solflareDetected">
                <cf-button label="Download Solflare" (clicked)="solflareService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="solflareDetected && showControls.connectButton">
                <cf-button label="Connect Solflare" (clicked)="solflareService.connectWallet()"
                    [loading]="showControls.signingLoader">
                </cf-button>
            </div>

            <div class="action" *ngIf="solflareDetected && showControls.disconnectButton">
                <cf-button label="Disconnect Solflare" (clicked)="solflareService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>
    </div>
</div>