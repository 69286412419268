<div class="app-invite-code container-fluid" [ngClass]="httpService.theme">
    <div class="code-input">
        <cf-input label="Invitation Code" type="text" [showLabel]="true" [value]="invite_code"
            (valueChanged)="onChangeCode($event)" placeholder="Enter Invitation Code"
            [theme]="httpService.theme"></cf-input>
    </div>
    <div class="action">
        <cf-button label="Skip" (clicked)="skipReferral()" [loading]="loading" type="outline"></cf-button>
        <cf-button label="Verify code" (clicked)="updateReferral()" [loading]="loading" type="solid"></cf-button>
    </div>
</div>