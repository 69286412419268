import {
    Component, Input, OnInit 
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { getWindow } from 'ssr-window';
import ICheckbox from '@crediblefinance/credible-ui/lib/cf-checkbox-large/ICheckbox';

@Component({
    selector: 'app-new-withdrawal',
    templateUrl: './new-withdrawal.component.html',
    styleUrls: [ './new-withdrawal.component.scss' ]
})
export class NewWithdrawalComponent implements OnInit {
    withdrawal_type_options: Array<ICheckbox> = [
        {
            label: 'Investment',
            value: 'investment',
            optionEnabled: true
        },
        {
            label: 'Earnings',
            value: 'earnings',
            optionEnabled: true
        }
    ];
    selected_withdrawal_type: string = this.withdrawal_type_options[0].value;

    @Input() pool_id: string = '';

    kyc_required: boolean = false;
    window = getWindow();

    constructor(public httpService: HttpService, private router: Router) {}

    ngOnInit(): void {
        console.log('new-withdrawal.component.ts ngOnInit()');
    }

    changeWithdrawalType(option: ICheckbox) {
        console.log('changeCollateralType', option);

        this.selected_withdrawal_type = option.value;
    }
}
