import ISolanaChain from '../interfaces/ISolanaChain';

const MAINNETBETA = {
    chainId: 1,
    chainName: 'Mainnet beta',
    chainKey: 'mainnet-beta',
    network: 'mainnet',
    chainLogo: 'https://assets.credible.finance/currencies/sol.png',
    blockExplorerUrl: 'https://explorer.solana.com/tx',
    rpc: 'https://rpc.ironforge.network/mainnet?apiKey=01JJDSFCABSMPP85WDCWGXZXPH',
    nativeCurrency: {
        name: 'Solana',
        symbol: 'SOL',
        decimals: 9
    }
};

const DEVNET = {
    chainId: 3,
    chainName: 'Devnet',
    chainKey: 'devnet',
    network: 'devnet',
    chainLogo: 'https://assets.credible.finance/currencies/sol.png',
    blockExplorerUrl: 'https://explorer.solana.com/tx',
    rpc: 'https://api.devnet.solana.com',
    nativeCurrency: {
        name: 'Solana',
        symbol: 'SOL',
        decimals: 9
    }
};

const SOLANA_CHAINS: {
    [key: string]: ISolanaChain;
} = {};

// You can query by blockchain or chainId
SOLANA_CHAINS['MAINNETBETA'] = MAINNETBETA;
SOLANA_CHAINS[MAINNETBETA.chainId] = MAINNETBETA;

SOLANA_CHAINS['DEVNET'] = DEVNET;
SOLANA_CHAINS[DEVNET.chainId] = DEVNET;

export default SOLANA_CHAINS;