import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Input, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IInvestment from '../../../interfaces/IInvestment';
import IUser from '../../../interfaces/IUser';
import User from '../../../models/User';
import { HttpService } from '../../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { ConnectWalletComponent } from '../../dialogs/connect-wallet/connect-wallet.component';

@Component({
    selector: 'app-my-nodes',
    templateUrl: './my-nodes.component.html',
    styleUrl: './my-nodes.component.scss'
})
export class MyNodesComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    loading_stats: boolean = false;
    currentTime: number = (new Date()).getTime();
  @Input() showStats: boolean = true;
  investments: Array<IInvestment> = [];
  investmentStats: any = {
      all: {
          amount_usd: 0,
          count: 0
      },
      days_1: {
          amount_usd: 0,
          count: 0
      },
      days_7: {
          amount_usd: 0,
          count: 0
      },
      days_30: {
          amount_usd: 0,
          count: 0
      }
  };

  total_count: number = 0;
  page: number = 0;
  limit: number = 10;
  opened_panel: string = '';

  statusOptions: Array<IDropdown> = [{
      label: 'All',
      value: '',
      optionEnabled: true
  },
  {
      label: 'Pending',
      value: 'pending',
      optionEnabled: true
  },
  {
      label: 'Processing',
      value: 'processing',
      optionEnabled: true
  }, {
      label: 'Completed',
      value: 'completed',
      optionEnabled: true
  }, {
      label: 'Failed',
      value: 'failed',
      optionEnabled: true
  }];
  selected_status: string = '';

  constructor( public httpService: HttpService, private cfAlertService: CfAlertService, private router: Router, 
      public dialogRef: MatDialogRef<ConnectWalletComponent>,
      public dialog: MatDialog
  ) { }

  ngOnInit(): void {
      console.log('my-investments.component.ts ngOnInit()');

      this.subscribeObservables();

      this.httpService.setMetaData('My Investments', 'Investments made by the user');
  }

  afterExpand(lending_id: string) {
      this.opened_panel = lending_id;
  }

  getInvestmentStats(filter: string) {
      this.loading_stats = true;

      this.httpService.getInvestmentStats(filter, true).subscribe(res => {
          this.investmentStats[filter] = res.data;

          this.loading_stats = false;
      }, (err: HttpErrorResponse) => {
          console.error(err);

          this.cfAlertService.showError(err);

          this.loading_stats = false;
      });
  }

  getInvestments() {
      this.loading = true;

      const body = {
          page: this.page,
          limit: this.limit,
          token_required: true,
          status: this.selected_status
      };

      this.httpService.getInvestments(body).subscribe((res: any) => {
          this.investments = res.data;
          this.total_count = res.total_count;

          if (this.investments.length > 0)
              this.opened_panel = this.investments[0].lending_id;

          this.loading = false;
      }, (err: HttpErrorResponse) => {
          console.error(err);

          this.loading = false;

          this.cfAlertService.showError(err);
      });
  }

  subscribeObservables() {
      if (this.httpService.user.user_id > 0) {
          this.httpService.getUserId().subscribe((user_id: number) => {
              this.user.user_id = user_id;

              this.getInvestments();

              if (this.showStats) {
                  this.getInvestmentStats('all');
                  this.getInvestmentStats('days_1');
                  this.getInvestmentStats('days_7');
                  this.getInvestmentStats('days_30');
              }
          });
      }
  }

  pageChanged(event: any) {
      this.page = event;
      this.getInvestments();
  }

  redirectionPool(category_id: string, pool_id: string) {
      this.router.navigate([ `/liquidity/${category_id}/${pool_id}` ]);
  }

  changeStatus(event: any) {
      this.selected_status = event.value;
      this.page = 0;
      this.getInvestments();
  }

  getStatusClasses(id: string, status: string) {
      const classes: any = {};

      if (this.opened_panel === id)
          classes['current-opened'] = true;

      if (status === 'pending')
          classes['status-pending'] = true;

      else if (status === 'processing')
          classes['status-processing'] = true;

      else if (status === 'completed')
          classes['status-success'] = true;

      else if (status === 'failed')
          classes['status-failed'] = true;

      return classes;
  }
}