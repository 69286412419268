import {
    Component, Input, OnInit 
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';

@Component({
    selector: 'app-payfi-widget',
    templateUrl: './payfi-widget.component.html',
    styleUrl: './payfi-widget.component.scss'
})
export class PayFiWidgetComponent implements OnInit {
    currentTab: string = '';
    @Input() lending_pool_id: string = '';
    @Input() type: string = '';

    lendTabs: Array<{
        label: string;
        key: string;
    }> = [
            {
                label: 'Deposit',
                key: 'lending'
            },
            {
                label: 'Withdrawals',
                key: 'withdrawal'
            }
        // {
        //     label: 'Earnings',
        //     key: 'earnings',
        // },
        ];

    borrowTabs: Array<{
        label: string;
        key: string;
    }> = [
            {
                label: 'Drawdown',
                key: 'drawdown'
            },
            {
                label: 'Repayment',
                key: 'repayment'
            },
            {
                label: 'Collateral',
                key: 'collateral'
            }
        ];
    tabs: Array<{
        label: string;
        key: string;
    }> = [];

    constructor(
        public httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.type === 'borrow') {
            this.tabs = this.borrowTabs;
            this.currentTab = this.borrowTabs[0].key;
        }
        else {
            this.tabs = this.lendTabs;
            this.currentTab = this.lendTabs[0].key;
        }

        console.log('lending_pool_id', this.lending_pool_id);

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe((params) => {
            if (params['currenttab']) {
                const tab = params['currenttab'];

                if (this.tabs.find((t) => t.key === tab)) this.currentTab = tab;
            }
        });
    }

    changedTab(tab: any) {
        this.currentTab = tab;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                currenttab: tab
            },
            queryParamsHandling: 'merge' // remove to replace all query params by provided
        });
    }
}
