import {
    Component, HostListener, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { getWindow } from 'ssr-window';
import { LocalStorageService } from '../../services/localstorage.service';
import { isPlatformBrowser } from '@angular/common';
import IPrivateCreditPool from '../../interfaces/IPrivateCreditPool';

@Component({
    selector: 'app-lend',
    templateUrl: './lend.component.html',
    styleUrl: './lend.component.scss'
})

export class LendComponent implements OnInit {
    loading: boolean = true;
    pools: Array<any> = [];
    window: Window;
    fixed_income_pools: Array<IPrivateCreditPool> = [];
    platformId: object = {};
    tvl_usd = 0;
    outstanding_drawdown_usd = 0;
    isBrowser: boolean = isPlatformBrowser(this.platformId);
    width: any;

    currentTab = 'all';
    tabs = [{
        name: 'All',
        key: 'all'
    }];

    category_id: string = '';
    layout: string = this.getDefaults('layout', true) ? 'list' : 'grid';

    constructor(
        public httpService: HttpService, 
        private cfAlertService: CfAlertService,
        private router: Router, 
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend.component.ts ngOnInit()');
        this.width = this.window.outerWidth;

        if (this.width < 768)
            this.layout = 'grid';

        this.getCategories();
        this.getPools();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;

        console.log('width on resize', this.width);

        if (this.width < 768)
            this.layout = 'grid';
    }

    getCategories() {
        this.httpService.getCategories().subscribe(res => {
            res.data.forEach((item: any) => {
                this.tabs.push({
                    name: item.name,
                    key: item.category_id
                });
            });
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getPools() {
        const data = {
            category_id: this.category_id
        };

        this.httpService.getPools(data).subscribe(res => {
            const data = res.data;

            this.pools = data;
            this.fixed_income_pools = [];

            this.pools.forEach(x => {
                if (x.category_id === 'private-credit') {
                    x.type = 'Private Credit';
                    this.fixed_income_pools.push(x);
                }

                if (x.category_id === 'partner-protocol'){
                    x.type = 'Partner Protocol';
                    this.fixed_income_pools.push(x);
                }
                
                if (x.category_id === 'vault'){
                    x.type = 'Vault';
                    this.fixed_income_pools.push(x);
                }

                this.tvl_usd += x.stats.tvl_usd;
                this.outstanding_drawdown_usd += x.stats.outstanding_drawdown_usd;
            });

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    selectPool(pool: any) {
        console.log('pool', pool);
        
        this.router.navigate([ `/liquidity/${pool.category_id}/${pool.pool_id}` ]);
    }

    redirectToUrl(url: string, event: Event) {
        this.window.open(url, '_blank');

        event.preventDefault();
        event.stopImmediatePropagation();
    }

    changeTab(tab: string) {
        this.currentTab = tab;

        console.log('TAB -> ', this.currentTab);

        this.category_id = this.currentTab;

        if (this.currentTab === 'all')
            this.category_id = '';

        this.getPools();

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getDefaults(field_name: string, fallback: boolean) {
        const value = this.localStorageService.getItem(field_name);

        if (value === null) {
            this.localStorageService.setItem(field_name, fallback.toString());

            return fallback;
        }

        return value === 'true';
    }

    changeLayout(layout: string) {
        this.layout = layout;

        this.localStorageService.setItem('layout', layout === 'list' ? 'true' : 'false');
    }
}