import {
    Component, NgZone, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../../services/localstorage.service';
import { OnboardingService } from '../onboarding.service';
import { nullChecker } from '../../helpers/nullChecker';

@Component({
    selector: 'app-register-steps',
    templateUrl: './register-steps.component.html',
    styleUrl: './register-steps.component.scss'
})

export class RegisterStepsComponent implements OnInit {
    steps = [{
        step: 'email-verification',
        title: 'Email Verification',
        completed: false
    },
    {
        step: 'invite-code',
        title: 'Invitation',
        completed: false
    }, {
        step: 'connect-wallet',
        title: 'Wallet',
        completed: false
    }];

    email: string = '';

    connectedWalletAddres: string = '';
    provider: string = '';

    currentStep = -1;
    currentCollapseStatus: boolean = false;
    step_skipping_allowed: boolean = false;
    returnUrl: string = '';
    invite_code: string = '';
    invite_code_readonly: boolean = false;

    prefill_invite_code: {
        email: string,
        invite_code: string,
        invite_code_readonly: boolean
    } = {
            email: '',
            invite_code: '',
            invite_code_readonly: false
        };

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private onboardingService: OnboardingService,
        private router: Router,
        private location: LocationStrategy,
        private localStorageService: LocalStorageService,
        private _ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        if (this.httpService.user.user_id > 0) {
            this.router.navigate([ '/airdrop' ]);

            return;
        }

        this.subscribeQueryParams();

        this.checkNextStep();
    }

    subscribeQueryParams() {
        this.route.queryParams.subscribe(params => {
            if (params['tab'])
                this.returnUrl = params['returnUrl'];

            if (params['invite_code'])
                this.validateInviteCode(params['invite_code']);

            else
                this.httpService.setMetaData('Connect wallet', 'Connect your wallet');
        });
    }

    validateInviteCode(invite_code: string) {
        this.onboardingService.validateInviteCode(invite_code).subscribe((res: any) => {
            this.invite_code = invite_code;

            const title = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;
            const description = `${res.data.display_name} is inviting you to join Credible with code ${invite_code}`;

            this.httpService.setMetaData(title, description);
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    checkNextStep() {
        this._ngZone.run(() => {
            let currentStep = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (!this.steps[i].completed) {
                    currentStep = i;
                    break;
                }
            }

            console.log('checkNextStep => ', currentStep);

            if (currentStep === -1) {
                console.log('All steps completed');

                const redirect_url = !this.returnUrl || this.returnUrl === '' ? '/airdrop' : this.returnUrl;
                const base_url = window.location.origin;
                const full_url = `${base_url}${redirect_url}`;

                history.pushState(null, '', full_url);

                this.router.navigateByUrl(redirect_url, {
                    replaceUrl: true
                });

                this.location.onPopState(() => {
                    history.pushState(null, '', full_url);
                });
            }

            else {
                console.log('currentStep => ', this.steps[currentStep]?.step);

                this.currentStep = currentStep;
            }
        });
    }

    changeStep(index: number) {
        if (this.step_skipping_allowed)
            this.currentStep = index;
    }

    previousStep() {
        if (this.currentStep !== 0)
            this.currentStep -= 1;
    }

    notifyWalletConnected($event: any) {
        console.log('register-steps : notifyWalletConnected => ', new Date().getTime());

        const { provider, wallet_address } = $event;

        this.connectedWalletAddres = wallet_address;
        this.provider = provider;

        this.steps[2].completed = true;
        this.checkNextStep();
    }

    notifyInviteCode($event: any) {
        this.steps[1].completed = true;
        this.checkNextStep();
    }

    notifyEmailVerification($event: any) {
        console.log('register-steps : notifyEmailVerification => ', $event);

        this.email = $event.email;

        this.steps[0].completed = true;

        if (!nullChecker($event.referred_by))
            this.steps[1].completed = true;

        // this would happen when the invite code has been set with the user's account
        // in the email-verification step, so we can skip this step
        if (!nullChecker(this.invite_code))
            this.steps[1].completed = true;

        this.checkNextStep();
    }

    connectWalletNext() {
        if (this.provider = 'email') {
            this.steps[2].completed = true;

            this.checkNextStep();
        }

        else
            this.currentStep += 1;
    }
}
