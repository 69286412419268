<div class="app-my-pool-activity" [ngClass]="httpService.theme">
    <div class="header-container">
        <span class="header-title">Pool Activity</span>
    </div>

    <div class="table-container">

        <table [cellPadding]="3">
            <thead>
                <tr class="table-header">
                    <th>Wallet Address</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Tx Link</th>
                    <th>Tx Type</th>
                </tr>
            </thead>
            <tbody *ngIf="items.length > 0">
                <tr *ngFor="let item of userActivity">
                    <td>
                        <span class="ellipses">{{ item.wallet_address }}
                        </span>
                    </td>
                    <td>
                        <span>
                            {{ item.amount | number:httpService.getPrecision(item.currency)
                            }} {{ item.currency | uppercase }}
                        </span>
                    </td>
                    <td>{{ item.created | date : "mediumDate" }}</td>
                    <td>
                        <div class="redirection">
                            <span class="ellipses">{{ item.blockchain_txid }}
                            </span>
                            <i class="fa-solid fa-up-right-from-square ml icon"
                                (click)="httpService.redirectionTx(item.blockchain, item.chain, item.network, item.blockchain_txid)"></i>
                        </div>
                    </td>
                    <td>
                        <span>{{ item.type | titlecase }}
                        </span>
                    </td>

                </tr>
            </tbody>
        </table>
        <cf-paginator *ngIf="total_count > 0" [page]="page + 1" [count]="total_count" [limit]="limit"
            (changed)="pageChanged($event)"></cf-paginator>
    </div>
</div>