<div class="app-new-payfi-collateral dark">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>

    <div *ngIf="!loading">
        <div class="field">
            <cf-checkbox-large [options]="collateral_type_options" label="Collateral type"
                (changed)="changeCollateralType($event)"></cf-checkbox-large>
        </div>

        <div class="field">
            <cf-dropdown [theme]="httpService.theme" [options]="collateral_options" label="Collateral currency"
                (change)="changeCollateralCurrency($event)" [selected]="collateral_currency" [showLogo]="true">
            </cf-dropdown>
        </div>

        <div class="field">
            <cf-input-currency [theme]="httpService.theme" [value]="collateral_amount" label="Collateral amount"
                type="number" [currency]="collateral_currency"
                [currencyLogo]="httpService.getCurrencyUrl(collateral_currency)"
                (valueChanged)="loanAmountChanged($event)" [precision]="6">
            </cf-input-currency>
            <span class="equivalent-amount mt-1">Min: {{ selected_pool.min_borrow_amount |
                number:collateral_currency_precision}} {{ collateral_currency | uppercase }} </span>
        </div>

        <div class="new-payfi-collateral-summary">
            <div class="progress-bar-holder">
                <div class="progress-bar" [style.width.%]="timer / initial_timer * 100"></div>
            </div>

            <div class="summary-content">
                <div class="summary-item">
                    <span class="summary-heading">Existing Collateral</span>
                    <span class="summary-value">$ {{ userCollateralInfo?.collateral_usd | number:'0.0-2'
                        }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Existing Drawdown</span>
                    <span class="summary-value">$ {{ userCollateralInfo?.dropdown_usd | number:'0.0-2'
                        }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Total collateral required</span>
                    <span class="summary-value" style="color: #cf0;">
                        $ {{ collateral_amount_usd - userCollateralInfo?.collateral_usd +
                        userCollateralInfo?.dropdown_usd | number:'0.0-2' }}
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="
                                            httpService.user.wallet_address !== '' &&
                                            httpService.user?.kyc_status !== 1">
            <cf-button [theme]="httpService.theme" [label]="'Start KYC'" [loading]="loading"
                (clicked)="generateKycUrl()"></cf-button>
        </div>

        <div class="actions" *ngIf="httpService.user.wallet_address !== '' &&
               httpService.user?.kyc_status === 1">>

            <div *ngIf="!new_collateral_loading">
                <cf-button [label]="confirm_btn_label" type="solid" [disabled]="httpService.user.wallet_address === ''"
                    (clicked)="createCollateral()"></cf-button>
                <span class="equivalent-amount mt-2">
                    Available:
                    {{ balance | number : collateral_currency_precision }}
                    {{ collateral_currency | uppercase }}
                </span>
            </div>
            <div *ngIf="new_collateral_loading">
                <cf-loader></cf-loader>
            </div>
        </div>
    </div>
</div>