import {
    Component, OnInit 
} from '@angular/core';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { HttpService } from '../../services/http.service';
import { CircleService } from '../circle.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { nullChecker } from '../../helpers/nullChecker';
import { getWindow } from 'ssr-window';
import { LocalStorageService } from '../../services/localstorage.service';

@Component({
    selector: 'app-wallet-new-withdrawal',
    templateUrl: './wallet-new-withdrawal.component.html',
    styleUrl: './wallet-new-withdrawal.component.scss'
})
export class WalletNewWithdrawalComponent implements OnInit {
    currencyList : any;
    currencyOptions :any;
    selectedCurrency: string = '';
    selectedCurrencyLabel: string = '';
    selectedCurrencyInfo:any = {};
    networkOptions: Array<IDropdown> = [];
    selectedNetwork: string = '';
    selectedNetworkInfo : any = {};
    balance: number = 0;
    window: Window;

    amount: string = '';
    destination_address: string = '';
    withdrawal_otp: string = '';
    showVerificationCode: boolean = false;
    loading: boolean = false;
    network_fees: number = 0;
    min_withdrawal: number = 1;
    recievable_amount: number = 0;

    constructor(
        public httpService: HttpService,
        private circleService: CircleService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.getCurrencyList();
    }

    getCurrencyList() {
        this.httpService.getCurrencyList('withdrawal').subscribe((data: any) => {
            console.log('data', data);
            this.currencyList = data.data;

            this.currencyOptions = this.currencyList.map((currency: any) => (
                {
                    currency: currency.currency,
                    mint_address: currency.mint_address,
                    label: currency.name,
                    value: currency.name,
                    logo: this.httpService.getCurrencyUrl(currency.currency),
                    optionEnabled: true
                }
            ));
            // this.selectedCurrency = this.currencyOptions[0].value;
            this.selectedCurrency = this.currencyOptions[0].value;
            this.selectedCurrencyLabel = this.currencyOptions[0].label;
            this.selectedCurrencyInfo = this.currencyOptions[0];
            console.log('  this.currencyOptions', this.currencyOptions);
            console.log(' 90 this.selectedCurrency', this.selectedCurrency);
            console.log('92 this.selectedCurrencyLabel', this.selectedCurrencyLabel);
           
            this.networkOptions = this.currencyList[0].network_list.map((network: any) => ({
                label: network.blockchain,
                value: network.blockchain,
                logo: network.logo,
                optionEnabled: true
            }));
        
            this.selectedNetwork = this.networkOptions[0].value;
            this.selectedNetworkInfo = this.currencyList[0].network_list[0];
            this.min_withdrawal = this.selectedCurrencyInfo.min_withdrawal;
            // this.getWithdrawalFee();
            console.log('selectedNetworkInfo', this.selectedNetworkInfo);
            this.network_fees = this.selectedNetworkInfo.withdrawal_fees;

            this.getCurrencyBalance();
        });
    }
    getWithdrawalFee() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address)) {
            this.network_fees = 0;

            return;
        }

        const body = {
            amount: this.amount,
            currency: this.selectedCurrencyInfo.currency,
            mint_address: this.selectedNetworkInfo.mint_address,
            destination_address: this.destination_address
        };

        console.log('body', body);

        this.circleService.getWithdrawalFee(body).subscribe((res: any) => {
            this.network_fees = res.data.low.networkFee;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    // changeCurrency(option: IDropdown): void {
    //     this.selectedCurrency = option.value;

    //     this.getWithdrawalFee();
    // }

    // changeNetwork(option: IDropdown): void {
    //     this.selectedNetwork = option.value;

    //     this.getWithdrawalFee();
    // }

    changeCurrency(option: any): void {
        console.log('105 option', option);
        this.selectedCurrencyInfo = option;
        this.selectedCurrency = option.value;
        this.selectedCurrencyLabel = option.value;
        
        console.log('this.selectedCurrency', this.selectedCurrency);
        
        const selectedCurrencyData = this.currencyList.find((currency : any) => currency.name === this.selectedCurrencyLabel);

        console.log('selectedCurrencyData', selectedCurrencyData);
        this.networkOptions = selectedCurrencyData.network_list.map((network: any) => ({
            label: network.blockchain,
            value: network.blockchain,
            logo: network.logo,
            optionEnabled: true
        }));
        
        this.selectedNetwork = this.networkOptions[0].value;
        this.selectedNetworkInfo = selectedCurrencyData.network_list[0];
        console.log('selectedNetworkInfo', this.selectedNetworkInfo);
        this.changeNetwork(this.networkOptions[0]);
    }

    changeNetwork(option: IDropdown): void {
        console.log('option', option);
     
        this.selectedNetwork = option.value;
        console.log('this.selectedNetwork', this.selectedNetwork);
        this.selectedNetworkInfo = this.currencyList.find((currency: any) => currency.name === this.selectedCurrencyLabel).network_list.find((network: any) => network.blockchain === this.selectedNetwork);
        console.log('this.selectedNetworkInfo', this.selectedNetworkInfo);
        this.getCurrencyBalance();
        this.getReceiableAmount();
        this.network_fees = this.selectedNetworkInfo.withdrawal_fees;
        // this.getWithdrawalFee();
    }

    changeAmount(amount: string) {
        this.amount = amount;
        this.getReceiableAmount();

        // this.getWithdrawalFee();
    }

    changeVerificationCode(verification_code: string) {
        this.withdrawal_otp = verification_code;
    }

    changeWalletAddress(wallet_address: string) {
        this.destination_address = wallet_address;

        // this.getWithdrawalFee();
    }

    getReceiableAmount() {
        const amount = parseFloat(this.amount);

        if (isNaN(amount) || amount < 0) 
            this.recievable_amount = 0;

        this.recievable_amount = parseFloat((amount - this.network_fees).toFixed(this.selectedNetworkInfo.crypto_precision));

        if (isNaN(this.recievable_amount) || this.recievable_amount < 0) 
            this.recievable_amount = 0;

        // console.log(this.recievable_amount);

        // return this.recievable_amount;
    }

    getVerificationCode() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address))
            return;

        if (parseFloat(this.amount) < this.min_withdrawal) {
            this.cfAlertService.showMessage(`Minimum withdrawal amount is ${this.min_withdrawal} ${this.selectedCurrency.toUpperCase()}`, true);

            return;
        }

        if (parseFloat(this.balance.toString()) < parseFloat(this.amount.toString())) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        this.loading = true;

        const body = {
            amount: parseFloat(this.amount),
            destination_address: this.destination_address,
            mint_address: this.selectedNetworkInfo.mint_address,
            fee_level: 'LOW'
        };

        this.httpService.initiateWithdrawalRequest(body).subscribe((res: any) => {
            this.cfAlertService.showSuccess(res.message);

            this.showVerificationCode = true;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }

    resendWithdrawalOtp() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address))
            return;

        const data = {
            amount: this.amount,
            currency: this.selectedCurrencyInfo.currency
        };

        this.httpService.resendWithdrawalOtp(data.amount, data.currency).subscribe((res: any) => {
            console.log(res);

            this.cfAlertService.showSuccess(res.message);
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    submit() {
        if (nullChecker(this.amount) || parseFloat(this.amount) <= 0 || nullChecker(this.selectedNetwork) || nullChecker(this.destination_address) || nullChecker(this.withdrawal_otp))
            return;

        if (parseFloat(this.balance.toString()) < parseFloat(this.amount.toString())) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        this.loading = true;

        const body = {
            amount: parseFloat(this.amount),
            destination_address: this.destination_address,
            mint_address: this.selectedNetworkInfo.mint_address,
            fee_level: 'LOW',
            OTP: parseInt(this.withdrawal_otp)
        };

        this.httpService.initiateWithdrawalRequest(body).subscribe((res: any) => {
            console.log('Challenge completed');

            this.cfAlertService.showSuccess('Withdrawal request submitted succesfully');

            this.loading = false;
            this.showVerificationCode = false;
            this.destination_address = '';
            this.amount = '',
            this.withdrawal_otp = '';
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
            this.loading = false;
        });
    }

    getCurrencyBalance() {
        this.httpService.getCurrencyBalanceV3(this.selectedCurrencyInfo.currency, this.selectedNetworkInfo.mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
