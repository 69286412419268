import {
    Component, Input, 
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { getWindow } from 'ssr-window';
import User from '../../models/User';
import { HttpService } from '../../services/http.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import { MetamaskService } from '../metamask.service';

@Component({
    selector: 'app-header-popup-menu',
    templateUrl: './header-popup-menu.component.html',
    styleUrl: './header-popup-menu.component.scss'
})
export class HeaderPopupMenuComponent implements OnInit {
    @Input() provider: string = '';
    window: Window;
    risk_score: any = {};

    constructor(
        private router: Router,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private metamaskService: MetamaskService,
        public walletService: WalletService,
        public httpService: HttpService,
        private localStorageService: LocalStorageService,
        private cfAlertService: CfAlertService
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        // if (!this.httpService.user.diamonds)
        //     this.httpService.user.diamonds = 0;
    }

    async logout() {
        console.log('logout', this.constructor.name);
        
        if (this.walletService.current_provider === 'phantom') 
            this.phantomService.disconnectWallet();

        else if (this.walletService.current_provider === 'solflare') 
            this.solflareService.disconnectWallet();

        else if (this.walletService.current_provider === 'metamask') 
            this.metamaskService.disconnectWallet();

        this.walletService.wallet_address = '';
        this.walletService.current_provider = '';

        this.localStorageService.clear();

        this.httpService.user = new User();

        await this.router.navigate([ '/' ], {
            replaceUrl: true
        });

        console.log('RELOAD ISSUE URL : HeaderPopupMenuComponent => ', this.window.location.href);

        this.window.location.reload();
    }

    getRiskScore() {
        this.httpService.getRiskScore().subscribe(res => {
            this.risk_score = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }
}
