import {
    Component, EventEmitter, Inject, OnInit, 
    Output,
    PLATFORM_ID
} from '@angular/core';
import { CircleService } from '../circle.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { ChallengeStatus } from '@circle-fin/w3s-pw-web-sdk/dist/src/types';
import { LocalStorageService } from '../../services/localstorage.service';
import { circle_app_id } from '../app.config';
import { isPlatformBrowser } from '@angular/common';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-login-circle',
    templateUrl: './login-circle.component.html',
    styleUrl: './login-circle.component.scss'
})
export class LoginCircleComponent implements OnInit {
    email: string = '';
    password: string = '';
    code: string = '';
    emailReadOnly: boolean = false;
    passwordReadOnly: boolean = false;
    loading: boolean = false;
    deviceToken: string = '';
    deviceEncryptionKey: string = '';
    otpToken: string = '';
    app_id: string = circle_app_id;

    encryptionKey: string = '';
    refreshToken: string = '';
    userToken: string = '';
    platformId: object = {};
    isBrowser: boolean = false;
    @Output() next = new EventEmitter<any>();
    window = getWindow();

    constructor(
        private circleService: CircleService, 
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService,
        @Inject(PLATFORM_ID) platformId: object) {
        this.platformId = platformId;
    }

    ngOnInit(): void {
        console.log('LoginCircleComponent', this.platformId);

        this.isBrowser = isPlatformBrowser(this.platformId);

        console.log('isBrowser', this.isBrowser);

        if (this.isBrowser)
            console.log('LoginCircleComponent browser');

        else
            console.log('LoginCircleComponent not browser');
    }

    emailChanged(email: string) {
        this.email = email;
    }

    passwordChanged(password: string) {
        this.password = password;
    }

    codeChanged(code: string) {
        this.code = code;
    }

    login() {
        this.loading = true;

        this.emailReadOnly = true;
        this.passwordReadOnly = true;

        this.circleService.login(this.email).subscribe((res: any) => {
            this.loading = false;

            this.deviceToken = res.data.deviceToken;
            this.deviceEncryptionKey = res.data.deviceEncryptionKey;
            this.otpToken = res.data.otpToken;

            console.log('res', res);

            this.initVerifyOtp();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.loading = false;
        });
    }

    initVerifyOtp() {
        if (!this.circleService.sdk) {
            console.log('no circle sdk');

            return;
        }

        this.loading = true;
        
        const configs = {
            appSettings: {
                appId: this.app_id
            },
            loginConfigs: {
                otpToken: this.otpToken,
                deviceToken: this.deviceToken,
                deviceEncryptionKey: this.deviceEncryptionKey
            }
        };

        this.circleService.sdk.updateConfigs(configs, (err, result) => {
            console.log('onlogin onComplete');
            console.log('err', err);
            console.log('result', result);

            if (result) {
                this.userToken = result.userToken;
                this.encryptionKey = result.encryptionKey;
                this.refreshToken = result.refreshToken;

                this.verifyOtp();
            }

            else 
                this.loading = false;
        });

        this.circleService.sdk.verifyOtp();
    }

    verifyOtp() {
        if (!this.circleService.sdk) {
            console.log('no circle sdk');

            return;
        }

        const body = {
            encryptionKey: this.encryptionKey,
            refreshToken: this.refreshToken,
            userToken: this.userToken,
            email: this.email
        };

        this.circleService.verifyOtp(body).subscribe((res: any) => {
            this.loading = false;

            console.log('res', res);

            if (res.data.challengeId)
                this.challengeNotCompleted(res.data);

            else
                this.challengeAlreadyCompleted(res.data.token);
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.loading = false;
        });
    }

    challengeAlreadyCompleted(token: string) {
        console.log('Challenge already completed');

        this.localStorageService.setItem('token', token);
        this.localStorageService.setItem('circle_user_token', this.userToken);
        this.localStorageService.setItem('circle_encryption_key', this.encryptionKey);

        this.cfAlertService.showSuccess('Login successful');

        console.log('RELOAD ISSUE URL : loginComponent => ', this.window.location.href);
        this.window.location.reload();
    }

    challengeNotCompleted(responseData: any) {
        console.log('Challenge not completed');

        const challengeId = responseData.challengeId;
        const token = responseData.token;

        console.log('challengeId', challengeId);

        this.circleService.sdk?.setAuthentication({
            userToken: this.userToken,
            encryptionKey: this.encryptionKey 
        });

        this.circleService.sdk?.setAppSettings({
            appId: this.app_id
        });

        this.circleService.sdk?.execute(challengeId, (err, result) => {
            console.log('execute onComplete');
            console.log('err', err);
            console.log('result', result);

            if (result?.status !== ChallengeStatus.FAILED) {
                console.log('Challenge completed');

                this.localStorageService.setItem('token', token);
                this.localStorageService.setItem('circle_user_token', this.userToken);
                this.localStorageService.setItem('circle_encryption_key', this.encryptionKey);

                this.cfAlertService.showSuccess('Login successful');

                this.next.emit();
            }

            else {
                this.cfAlertService.showMessage('Challenge not completed. Please retry.', true);

                console.log('RELOAD ISSUE URL : LoginCircleComponent => ', this.window.location.href);

                this.window.location.reload();
            }
        });
    }
}
