import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import {
    Inject, Injectable, PLATFORM_ID 
} from '@angular/core';
import { serverUrl} from './app.config';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class CircleService {
    isBrowser: boolean = false;

    constructor(
        private http: HttpClient, 
        @Inject(PLATFORM_ID) platformId: object,
        private localStorageService: LocalStorageService
    ) {
        console.log('CircleService', platformId);

        this.isBrowser = isPlatformBrowser(platformId);

        console.log('isBrowser', this.isBrowser);
    }

    getDeposits(body: any) {
        const queryParams = new URLSearchParams(body).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}circle/getDeposits?${queryParams}`, httpOptions);
    }

    getWithdrawals(body: any) {
        const queryParams = new URLSearchParams(body).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}circle/getWithdrawals?${queryParams}`, httpOptions);
    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getWithdrawalFee(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}circle/getWithdrawalFee`, body, httpOptions);
    }

    newWithdrawalRequest(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}circle/newWithdrawalRequest`, body, httpOptions);
    }

    getCircleCurrencies(blockchain: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}circle/getCircleCurrencies?blockchain=${blockchain}`, httpOptions);
    }
}
