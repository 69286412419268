<div class="app-lend-pools container-fluid" [ngClass]="httpService.theme">
    <div class="row">
        <div class="col-md-12">
            <div class="tabs">
                <span class="tab" (click)="changeTab(tab.key)" [ngClass]="{ 'tab-active': currentTab === tab.key }"
                    *ngFor="let tab of tabs">{{ tab.name }}</span>
            </div>
            <div class="tabs-divider"></div>
        </div>
    </div>

    <div class="controls">
        <div class="control" [ngClass]="{'control-active': layout === 'list'}" (click)="changeLayout('list')"
            matTooltip="List View">
            <i class="fa-solid fa-bars"></i>
        </div>

        <div class="control" [ngClass]="{'control-active': layout === 'grid'}" (click)="changeLayout('grid')"
            matTooltip="Grid View">
            <i class="fa-solid fa-grip"></i>
        </div>
    </div>

    <div class="row" *ngIf="loading">
        <div class="loader-content">
            <cf-loader [theme]="httpService.theme" class="loader"></cf-loader>
        </div>
    </div>

    <div class="row" *ngIf="!loading && layout === 'grid'">
        <div class="col-md-12">
            <div class="pools-grid-container">
                <div *ngFor="let pool of pools">
                    <app-lend-default [pool]="pool" *ngIf="pool.category_id === 'private-credit'"></app-lend-default>
                    <app-lend-default [pool]="pool" *ngIf="pool.category_id === 'partner-protocol'"></app-lend-default>
                    <app-lend-default [pool]="pool" *ngIf="pool.category_id === 'vault'"></app-lend-default>
                </div>
            </div>
        </div>
    </div>

    <div class="sections" *ngIf="!loading && layout === 'list'">
        <cf-card [theme]="httpService.theme" type="empty" *ngIf="fixed_income_pools.length > 0">
            <div class="pools-list-container">
                <div class="table-sub-heading">NBFIs, Bonds, T-Bills</div>
                <div class="table-heading">Private Credit</div>

                <table>
                    <thead>
                        <th>Company</th>
                        <th>Type</th>
                        <th>TVL</th>
                        <th>APY</th>
                        <th>Rating</th>
                        <th>Verifier</th>
                        <th>Tenor</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pool of fixed_income_pools" (click)="selectPool(pool)"
                            [matTooltip]="pool.coming_soon ? 'Coming Soon' : ''"
                            [ngClass]="{ 'pool-disabled': pool.coming_soon }">
                            <td class="pool-name-container">
                                <img [src]="pool.image_url" width="22" [alt]="pool.name" />
                                {{ pool.name }}
                            </td>
                            <td>{{ pool.type }}</td>
                            <td>
                                {{ pool.stats.tvl_usd | number:'0.0-0' }} USD
                            </td>
                            <td>{{
                                pool.lending_yield_rates[pool.lending_yield_rates.length - 1].apy
                                }}%
                            </td>
                            <td>
                                <span *ngIf="pool.borrower_overview.rating_agency_equivalent">
                                    {{ pool.borrower_overview.rating_agency_equivalent }}
                                </span>
                                <span *ngIf="!pool.borrower_overview.rating_agency_equivalent">
                                    Unrated
                                </span>
                            </td>
                            <td>
                                <span *ngIf="pool.borrower_overview.rating_agency_equivalent">
                                    {{ pool.borrower_overview.rating_agency }}
                                    <i class="fa-solid fa-up-right-from-square"
                                        *ngIf="pool.borrower_overview.rating_agency"
                                        (click)="redirectToUrl(pool.borrower_overview.rating_agency_url, $event)"></i>
                                </span>
                                <span *ngIf="!pool.borrower_overview.rating_agency_equivalent">
                                    Unrated
                                </span>
                            </td>
                            <td>
                                <span *ngIf="pool.tenor === 0">Flexible</span>
                                <span *ngIf="pool.tenor > 0">{{ pool.tenor }} days</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </cf-card>
    </div>
</div>