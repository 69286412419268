import {
    Component, OnInit
} from '@angular/core';
import { AddPayoutBeneficiaryComponent } from '../add-payout-beneficiary/add-payout-beneficiary.component';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';
import { PayFiService } from '../../services/payfi.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';

@Component({
    selector: 'app-payout-beneficiaries',
    templateUrl: './payout-beneficiaries.component.html',
    styleUrl: './payout-beneficiaries.component.scss'
})
export class PayoutBeneficiariesComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddPayoutBeneficiaryComponent>,
        public dialog: MatDialog,
        public httpService: HttpService,
        private payFiService: PayFiService,
        private cfAlertService: CfAlertService
    ) { }

    payout_beneficiaries: Array<any> = [];
    payout_currencies: Array<any> = [];

    ngOnInit(): void {
        this.getPayoutBeneficiaries();
    }

    getPayoutBeneficiaries() {
        this.payFiService.getPayoutBeneficiaries('', '', 'read').subscribe((res: any) => {
            res.data.forEach((payout_beneficiary: any) => {
                const fields = [];

                for (const key in payout_beneficiary) {
                    let value = payout_beneficiary[key];

                    if (key === 'currency')
                        value = value.toUpperCase();

                    if (key === 'payout_method')
                        value = value.replaceAll('_', ' ');

                    fields.push({
                        label: key.replaceAll('_', ' '),
                        value: value
                    });
                }

                this.payout_beneficiaries.push({
                    fields: fields
                });
            });
        }, (err: HttpErrorResponse) => {
            console.error('getPayoutBeneficiaries error');
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    openDialog() {
        this.dialogRef = this.dialog.open(AddPayoutBeneficiaryComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result)
                this.getPayoutBeneficiaries(); //call api only if beneficiary added
        });
    }
}