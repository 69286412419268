<div class="app-new-payfi-earning dark" [ngClass]="httpService.theme">
    <div *ngIf="
            !loading &&
            httpService.user.user_id > 0 &&
            !show_no_investments
        ">

        <div class="row mt-2">
            <cf-dropdown [theme]="httpService.theme" [options]="investmentDropdownOptions"
                (change)="changeInvestment($event)" label="Select Investment" [showLogo]="false" [selected]="lending_id"
                [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <cf-input-currency-dropdown [theme]="httpService.theme" [readonly]="true"
                [options]="currencyDropdownOptions" [value]="amount" [class]="'input'" label="Amount"
                [selected]="currency" type="number" (valueChanged)="amountChanged($event)" [precision]="4">
            </cf-input-currency-dropdown>

            <span class="equivalent-amount mt-1">
                {{ amount | number : httpService.getPrecision(currency) }}
                {{ currency | uppercase }}
                ≈
                {{ amount_usd | number : httpService.getPrecision("usd") }}
                USD
            </span>
        </div>

        <div class="row mt-2" *ngIf="vault_dropdown_options.length > 0">
            <cf-dropdown [theme]="httpService.theme" [options]="vault_dropdown_options" [class]="'input'"
                label="Select Vault" [selected]="selected_vault" (change)="changeVault($event)">
            </cf-dropdown>
        </div>

        <div class="actions">
            <div *ngIf="
                    this.kyc_required &&
                    httpService.user.user_id > 0 &&
                    httpService.user?.kyc_status !== 1
                ">
                <cf-button [theme]="httpService.theme" [label]="'Start KYC'" (clicked)="generateKycUrl()"
                    [loading]="loading"></cf-button>
            </div>
            <div *ngIf="
                    httpService.user.user_id > 0 &&
                    (!this.kyc_required || httpService.user?.kyc_status === 1) &&
                    !(walletService.current_provider === '' || walletService.current_provider === 'email')
                ">
                <div class="action">
                    <div *ngIf="!btn_loading">
                        <cf-button label="Claim Earnings" type="solid" [disabled]="httpService.user.user_id === 0"
                            (clicked)="claimInterest()"></cf-button>
                    </div>
                    <div *ngIf="btn_loading">
                        <cf-loader></cf-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div
        *ngIf="!loading && !show_no_investments && (walletService.current_provider === '' || walletService.current_provider === 'email')">
        <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
    </div>
    <div *ngIf="show_no_investments" class="no_data">
        <div>No Earnings</div>
    </div>
</div>