import {
    Inject, Injectable, PLATFORM_ID 
} from '@angular/core';
import { getWindow } from 'ssr-window';
import { WalletService } from '../services/wallet.service';
import ETHEREUM_CHAINS from '../constants/EthereumChains';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { PLUME_ENVIRONMENT } from './app.config';
import IEthereumChain from '../interfaces/IEthereumChain';
import { isPlatformBrowser } from '@angular/common';
import Web3 from 'web3';

@Injectable({
    providedIn: 'root'
})
export class BinanceWalletService {
    window: Window = getWindow();
    provider: any | undefined;
    wallet_address: string = '';
    chainId: string = '';
    isBrowser: boolean = false;
    web3: any;
  
    constructor(
        private walletService: WalletService, 
        @Inject(PLATFORM_ID) platformId: object,
        private cfAlertService: CfAlertService) {
        this.window = getWindow();

        const isPresent = this.isPresent();

        console.log('BinanceWalletService isPresent', isPresent);

        console.log('CircleService', platformId);
        
        this.isBrowser = isPlatformBrowser(platformId);
        
        console.log('isBrowser', this.isBrowser);
    }

    isPresent() {
        if (!('ethereum' in this.window))
            return false;

        if (!this.window.ethereum)
            return false;
        
        if ('isBinance' in this.window.ethereum)
            return true;

        return false;
    }

    async setBinanceProvider() {
        console.log('BinanceWalletService setBinanceProvider');

        const promise = new Promise<void>((resolve, reject) => {
            import('@binance/w3w-ethereum-provider')
                .then(defaultImport => {
                    console.log('BinanceWalletService defaultImport', defaultImport);
                    
                    const chainInfo = ETHEREUM_CHAINS[PLUME_ENVIRONMENT];

                    this.provider = defaultImport.getProvider({
                        chainId: chainInfo.chainId,
                        rpc: chainInfo.rpc,
                        lng: 'en'
                    });

                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });

        return promise;
    }

    signMessageLedger(publicKey: string) {
        console.log('BinanceWalletService signMessageLedger');
    }

    async signMessageNonLedger() {
        console.log('BinanceWalletService signMessageNonLedger');

        if (!this.provider) {
            console.log('BinanceWalletService No provider found');
            
            return ;
        }

        const exampleMessage = 'Sign below to authenticate with Credible finance';

        try {
            const from = this.wallet_address;
            // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
            // This uses a Node.js-style buffer shim in the browser.
            const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;

            const signedMessage = await this.provider.request({
                method: 'personal_sign',
                params: [ msg, from ]
            });

            console.log('signedMessage', signedMessage);

            if (!signedMessage) {
                this.walletService.setEvent('binance', 'signMessage', {
                    status: 'failure'
                });
            }

            else {
                this.walletService.setEvent('binance', 'signMessage', {
                    status: 'success',
                    signature: signedMessage
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    async signTransaction(abi: string, contract_address: string, wallet_address: string, gas: number) {
        console.log('BinanceWalletService signTransaction', abi, contract_address, wallet_address, gas);

        const extra_gas = 100 * 1000;

        const promise = new Promise<string>((resolve, reject) => {
            if (!this.provider) {
                console.log('BinanceWalletService No provider found');
                
                return reject(new Error('No provider found'));
            }

            this.provider.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        data: abi,
                        from: wallet_address,
                        to: contract_address,
                        value: '0',
                        gasLimit: (gas + extra_gas).toString(16)
                    }
                ]
            }).then((txHash: string) => {
                console.log('txHash', txHash);

                const txnSignature = txHash as string;

                resolve(txnSignature);
            }).catch((error: Error) => {
                console.error(error);
                reject(error);
            });
        });
        
        return promise;
    }

    switchNetwork(chainInfo: IEthereumChain) {
        console.log('BinanceWalletService switchNetwork');

        this.provider?.request({
            method: 'wallet_switchEthereumChain',
            params: [{
                chainId: Web3.utils.toHex(chainInfo.chainId)
            }]
        }).then((res: any) => {
            this.cfAlertService.showMessage(`Switched to ${chainInfo.chainName}`, false);
        }).catch((e: Error) => {
            this.cfAlertService.showMessage(`Failed to switch to ${chainInfo.chainName}`, true);
            console.error('switchNetwork error', e);
        });
    }

    async signMessage(address: string) {
        console.log('BinanceWalletService signMessage', address);

        if (this.walletService.ledger)
            await this.signMessageLedger(address);

        else
            await this.signMessageNonLedger();
    }

    downloadWallet() {
        this.window.open('https://metamask.io/download/', '_blank');
    }

    async connectWallet() {
        console.log('BinanceWalletService connectWallet');

        await this.setBinanceProvider();

        if (!this.provider) {
            console.log('BinanceWalletService No provider found');
            
            return;
        }

        const accounts = await this.provider?.request({
            method: 'eth_requestAccounts'
        });

        console.log('connectWallet', accounts);

        if (!accounts || accounts.length === 0) {
            console.log('BinanceWalletService No wallet states found');

            return ;
        }

        this.chainId = await this.getChainId();

        console.log('chainIdHex', this.chainId);

        if (!accounts) {
            console.log('No accounts found');

            return ;
        }

        const accountsArr = accounts as string[];

        if (this.chainId) {
            const chainIdDecimal = parseInt(this.chainId, 16);

            console.log('chainIdDecimal', chainIdDecimal);
            
            const address = accountsArr[0];

            this.newAddressConnected(address);
        }

        else {
            const chainInfo = ETHEREUM_CHAINS[PLUME_ENVIRONMENT];

            this.switchNetwork(chainInfo);
        }
    }

    newAddressConnected(wallet_address: string) {
        console.log('BinanceWalletService newAddressConnected', wallet_address);

        this.wallet_address = wallet_address;

        console.log('BinanceWalletService binance connect', this.wallet_address);

        this.walletService.current_provider = 'binance';
        this.walletService.blockchain = 'ethereum';

        this.walletService.setEvent('binance', 'connect', {
            wallet_address: this.wallet_address
        });

        if (!localStorage.getItem('token'))
            this.signMessage(this.wallet_address);
    }

    disconnectWallet() {
        if (!this.provider) {
            console.log('BinanceWalletService No provider found');
            
            return ;
        }

        this.provider.disconnect && this.provider.disconnect();
    }

    async getChainId() {
        const chainId = await this.provider?.request({
            method: 'eth_chainId' 
        });

        this.chainId = chainId as string;

        return chainId as string;
    }

    connectWalletMobile() {

    }
}
