import {
    Component, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router} from '@angular/router';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-accept-invite-code',
    templateUrl: './accept-invite-code.component.html',
    styleUrl: './accept-invite-code.component.scss'
})
export class AcceptInviteCodeComponent implements OnInit {
    invite_code: string = '';

    constructor(
        private router: Router,
        public httpService: HttpService,
        private cfAlertService: CfAlertService
    ) {
    }

    ngOnInit(): void {
        this.invite_code = this.router.url.split('/')[2];

        this.router.navigate([ '/register' ], {
            queryParams: {
                invite_code: this.invite_code
            }
        });
    }
}
