import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Input, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IUser from '../../../interfaces/IUser';
import User from '../../../models/User';
import { HttpService } from '../../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { PayFiService } from '../../../services/payfi.service';
import IPayFiDrawdown from '../../../interfaces/IPayFiDrawdown';

@Component({
    selector: 'app-my-payfi-drawdowns',
    templateUrl: './my-payfi-drawdowns.component.html',
    styleUrls: [ './my-payfi-drawdowns.component.scss' ]
})

export class MyPayFiDrawdownsComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    loading_stats: boolean = false;
    currentTime: number = (new Date()).getTime();
    items: Array<IPayFiDrawdown> = [];
    @Input() showStats: boolean = true;
    stats: any = {
        all: {
            amount_usd: 0,
            count: 0
        },
        days_1: {
            amount_usd: 0,
            count: 0
        },
        days_7: {
            amount_usd: 0,
            count: 0
        },
        days_30: {
            amount_usd: 0,
            count: 0
        }
    };

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    opened_panel: string = '';

    statusOptions: Array<IDropdown> = [{
        label: 'All',
        value: '',
        optionEnabled: true
    },
    {
        label: 'Pending',
        value: 'pending',
        optionEnabled: true
    },
    {
        label: 'Processing',
        value: 'processing',
        optionEnabled: true
    }, {
        label: 'Completed',
        value: 'completed',
        optionEnabled: true
    }, {
        label: 'Failed',
        value: 'failed',
        optionEnabled: true
    }];
    selected_status: string = '';

    constructor(
        public httpService: HttpService, 
        private cfAlertService: CfAlertService, 
        private router: Router,
    private payFiService: PayFiService) { }

    ngOnInit(): void {
        console.log('my-payfi-drawdowns.component.ts ngOnInit()');

        this.subscribeObservables();

        this.httpService.setMetaData('My payfi drawdowns', 'My payfi drawdowns');
    }

    afterExpand(lending_id: string) {
        this.opened_panel = lending_id;
    }

    getStats(filter: string) {
        this.loading_stats = true;

        this.payFiService.getDrawdownStats(filter, true).subscribe(res => {
            this.stats[filter] = res.data;

            this.loading_stats = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.loading_stats = false;
        });
    }

    getItems() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: this.selected_status
        };

        this.payFiService.getDrawdowns(body).subscribe((res: any) => {
            this.items = res.data;
            this.total_count = res.total_count;

            this.items.forEach((item: any) => {
                item.progressSteps = this.getProgressSteps(item.progress);

                for (let i = 0; i < item.progress.length; i++) {
                    if (item.progress[i].status === 'completed') 
                        item.status = item.progress[i].label;
                }
            });

            console.log('this.items', this.items);

            if (this.items.length > 0)
                this.opened_panel = this.items[0].drawdown_id;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getProgressSteps(progress: Array<any>) {
        const steps = [];

        for (let i = 0; i < progress.length; i++) {
            const step = {
                completed: progress[i].status === 'completed',
                label: progress[i].label,
                editable: false,
                content: '',
                key: progress[i].key
            };

            if (step.completed) {
                const date = new Date(progress[i].completed_at);

                step.content = `Completed at ${date.toDateString()} ${date.toLocaleTimeString('en-US')}`;
            }

            steps.push(step);
        }

        return steps;
    }

    subscribeObservables() {
        if (this.httpService.user.wallet_address !== '') {
            this.httpService.getUserId().subscribe((user_id: number) => {
                this.user.user_id = user_id;

                this.getItems();

                if (this.showStats) {
                    this.getStats('all');
                    this.getStats('days_1');
                    this.getStats('days_7');
                    this.getStats('days_30');
                }
            });
        }
    }

    pageChanged(event: any) {
        this.page = event;
        this.getItems();
    }

    redirectionPool(category_id: string, pool_id: string) {
        this.router.navigate([ `/tokenize/${category_id}/${pool_id}` ]);
    }

    connectWallet() {
        this.router.navigate([ '/login' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    getStatusClasses(id: string, status: string) {
        const classes: any = {};

        if (this.opened_panel === id)
            classes['current-opened'] = true;

        if (status === 'pending')
            classes['status-pending'] = true;

        else if (status === 'processing')
            classes['status-processing'] = true;

        else if (status === 'completed')
            classes['status-success'] = true;

        else if (status === 'failed')
            classes['status-failed'] = true;

        return classes;
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.page = 0;
        this.getItems();
    }
}