import {
    Component, Input, Output, EventEmitter, OnInit, 
    OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-new-button-menu',
    templateUrl: './button-menu.html',
    styleUrls: [ './button-menu.scss' ]
})

export default class ButtonMenuComponent implements OnInit, OnChanges {
    /***
     * @param padding: padding for button menu component
    */
    @Input()
        padding?: string;

    /***
     * @param buttonWidth: Width for button menu component
    */
    @Input()
        buttonWidth?: string;

    /***
     * @param backgroundWidth: backgroundWidth for button menu component
    */
    @Input()
        backgroundWidth?: string;

    /***
     * @param showButtonType: showButtonType for button menu component
    */
    @Input()
        showButtonType?: string;

    /***
     * @param label: label for button menu component
    */
    @Input()
        label?: string;

    @Input()
        selectedDate?: any = 'all';

    @Input()
        selected?: string = 'buy';

    selectedButton: string = 'buy';

    selectedMultiToggleButton: number = 0;
    selectedLabelButton: number = 0;
    selectedLabelBorderButton: number = 0;

    @Input()
        dateTypeArray = [
            {
                type: 'one_day',
                resolution: '1D'
            },
            {
                type: 'week',
                resolution: '1W'
            },
            {
                type: 'month',
                resolution: '1M'
            },
            {
                type: 'three_month',
                resolution: '3M'
            },
            {
                type: 'one_year',
                resolution: '1Y'
            },
            {
                type: 'all',
                resolution: 'ALL'
            }
        ];

    selectedToggleButton: any = this.dateTypeArray[0];
    selected_pool_id: string | null = null;

    /**
       * @emits when user clicks on button 
       */
    @Output()
        buttonClick = new EventEmitter<string>();

    /***
     * @param labelArray: `labelArray` for button menu component
    */
    @Input()
        labelArray: Array<string> = [];

    @Input()
        defaultTabIndex: number = 0;

    @Input()
        pool_id: string | null = null;

    @Input()
        multiToggleArray: Array<string> = [];

    /***
     * @param labelButtonArray: `labelButtonArray` for button menu component
    */
    @Input()
        labelButtonArray: Array<string> = [];

    constructor(private router: Router, public httpService: HttpService) {
    }

    ngOnInit() {
        //this.selectLabelBorderButton(this.defaultTabIndex);
        this.selectedLabelBorderButton = this.defaultTabIndex;
        this.selected_pool_id = this.pool_id;

        if (typeof this.selected !== 'undefined') 
            this.selectedButton = this.selected;

        // console.log('selectedButton', this.selectedButton , this.selected)

        this.selectedToggleButton = this.dateTypeArray.find((data: any) => {
            let d;

            // eslint-disable-next-line no-cond-assign
            if (data.type === this.selectedDate) 
                d = data;
            
            return d;
        });
    }

    ngOnChanges(changes: any) {
        console.log('changes button menu >> ', changes );

        if (changes?.selected?.currentValue) 
            this.selectedButton = changes?.selected?.currentValue;

        if (changes?.defaultTabIndex && this.showButtonType === 'lableBorderButton') 
            this.selectedLabelBorderButton = changes?.defaultTabIndex?.currentValue;
    }

    /**
    * @description changes the tab which is selected by the user  for buy and sell
    */
    selectedTab(tab: string) {
        this.selectedButton = tab;
        this.buttonClick.emit(tab);
    }

    /**
    * @description changes the button which is selected by the user for 1D, 1W, 1M etc
    */
    selectToggleButton(date: any) {
        this.selectedToggleButton = date;
        this.buttonClick.emit(date);
    }

    /**
    * @description Switch between wallet, trade and earn when user clicks on button
    */
    selectMultiToggleButton(index: any) {
        this.selectedMultiToggleButton = index;
        this.buttonClick.emit(index);
    }

    /**
    * @description Switch between level1, level2, level3 button type when user clicks on button
    */
    selectLabelButton(index: number) {
        this.selectedLabelButton = index;
        this.buttonClick.emit('');
    }

    /**
     * @description changes the tab when user clicks on particular tab button
     */
    selectLabelBorderButton(index: any) {
        this.selectedLabelBorderButton = index;
        this.buttonClick.emit(index);
    }
}
